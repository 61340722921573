/* eslint-disable no-nested-ternary */

import React from 'react';

import { Formik } from 'formik';

import moment from 'moment';

import { FormStyled } from '../../../css/styles/form';

const handleValidate = ({ begin, end }) => {
  const errors = {};

  if (end && !begin) errors.begin = 'OBRIGATÓRIO';

  if (begin && !end) errors.end = 'OBRIGATÓRIO';

  if (begin && end && moment(begin).isAfter(end))
    errors.end = 'DATA FINAL NÃO PODE SER MENOR QUE INICIAL';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  const { onSearchFrequency } = props;

  const { mes, ano, begin, end } = values;

  onSearchFrequency(mes, ano, begin, end);

  setSubmitting(false);

  // resetForm();
};

const frequenciaFilterForm = props => {
  const mes =
    // eslint-disable-next-line radix
    parseInt(
      moment()
        .subtract(1, 'months')

        .format('MM')
    ) - 1;

  const ano = moment()
    .subtract(1, 'months')

    .format('YYYY');

  const mesOptions = [
    'Janeiro-Fevereiro',

    'Fevereiro-Março',

    'Março-Abril',

    'Abril-Maio',

    'Maio-Junho',

    'Junho-Julho',

    'Julho-Agosto',

    'Agosto-Setembro',

    'Setembro-Outubro',

    'Outubro-Novembro',

    'Novembro-Dezembro',

    'Dezembro-Janeiro',
  ];

  return (
    <Formik
      onSubmit={handleSubmit(props)}
      validate={handleValidate}
      enableReinitialize
      initialValues={{
        mes,

        ano,

        begin: '',

        end: '',
      }}
    >
      {({
        values,

        handleChange,

        handleBlur,

        handleReset,

        errors,

        touched,

        isSubmitting,

        dirty,
      }) => (
        <FormStyled>
          <h1>FOLHA DE PONTO</h1>

          <div className="line">
            <div className="formData">
              <strong>MÊS BASE</strong>

              <select
                id="mes"
                name="mes"
                value={values.mes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.mes && touched.mes)}
              >
                {mesOptions.map((option, index) => (
                  <option key={option} value={index}>
                    {option}
                  </option>
                ))}
              </select>

              {errors.mes && touched.mes && <span>{errors.mes}</span>}
            </div>

            <div className="formData">
              <strong>ANO</strong>

              <input
                id="ano"
                name="ano"
                type="number"
                value={values.ano}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.ano && touched.ano)}
              />

              {errors.ano && touched.ano && <span>{errors.ano}</span>}
            </div>
          </div>

          <div className="divider">
            <hr />
            <strong>OU</strong>
            <hr />
          </div>

          <div className="line">
            <div className="formData">
              <strong>DATA INICIAL</strong>

              <input
                id="begin"
                name="begin"
                type="date"
                value={values.begin}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.begin && touched.begin)}
              />

              {errors.begin && touched.begin && <span>{errors.begin}</span>}
            </div>

            <div className="formData">
              <strong>DATA FINAL</strong>

              <input
                id="end"
                name="end"
                type="date"
                value={values.end}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.end && touched.end)}
              />

              {errors.end && touched.end && <span>{errors.end}</span>}
            </div>
          </div>

          <button type="submit" disabled={isSubmitting}>
            PESQUISAR
          </button>

          <button
            type="button"
            disabled={isSubmitting || !dirty}
            onClick={handleReset}
          >
            LIMPAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

export default frequenciaFilterForm;
