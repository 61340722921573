import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';
import Loading from '../components/Loading';

const departamentosListItem = props =>
  props.departamentos.map(departamento => (
    <li key={departamento.uuid}>
      <Link to={`/departamentos/${departamento.uuid}`} key={departamento.uuid}>
        <strong>{departamento.nm_departamento}</strong>
        <strong />
        <strong>{new Date(departamento.createdAt).toLocaleString()}</strong>
      </Link>
    </li>
  ));

function DepartamentoList(props) {
  const { isLoading, departamentos } = props;  
  return (
    <>
      {!isLoading ? (
        <>
          {departamentos.length > 0 ? (
            <ListStyled>
              <header>
                <div className="total">
                  <h1>TOTAL: </h1>
                  <p>{departamentos.length}</p>
                </div>

                <div className="titles">
                  <strong>NOME</strong>
                  <strong />
                  <strong>DATA DE CRIAÇÃO</strong>
                </div>
              </header>
              {departamentosListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>LISTA VAZIA</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

DepartamentoList.propTypes = {
  isLoading: PropTypes.bool,

  departamentos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_departamento: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
};

DepartamentoList.defaultProps = {
  departamentos: [],
  isLoading: false,
};

export default DepartamentoList;
