import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';

import API from '../../../services/api';

// Components

import AlocacaoTurnoList from './list';
import AlocacaoTurnoForm from './form';

// Styles

import { SubContainer, SubContent } from '../../../css/styles/wrapper';

class AlocacaoTurnoContainer extends Component {
  state = {
    isLoading: true,
    alocacoesTurnos: null,
    turnos: null,
  };

  async componentDidMount() {
    this.mount();
  }

  mount() {
    this.getAlocacoesTurnos();

    this.getTurnos();
  }

  async getAlocacoesTurnos() {
    this.setState({
      isLoading: true,
    });

    const { funcionario, onGetTurno } = this.props;

    API.get(`alocacaoTurnos/funcionario/${funcionario.uuid}`).then(res => {
      const result = res.data;

      this.setState({
        alocacoesTurnos: result,

        isLoading: false,
      });

      onGetTurno(res.data);
    });
  }

  handleAlocacaoTurnoCreation = async values => {
    const { funcionario } = this.props;

    try {
      await API.post(
        'alocacaoTurnos',

        {
          // eslint-disable-next-line new-cap
          dt_inicio: new moment.utc(values.dt_inicio),

          turnoUuid: values.turnoUuid,

          funcionarioUuid: funcionario.uuid,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getAlocacoesTurnos();
    } catch (err) {
      alert(err);
    }
  };

  handleAlocacaoTurnoTrocaRapida = async values => {
    const { funcionario } = this.props;
    const { alocacoesTurnos } = this.state;

    const turnoAtual = alocacoesTurnos[alocacoesTurnos.length - 1].turno;

    try {
      await API.post(
        'alocacaoTurnos',

        {
          // eslint-disable-next-line new-cap
          dt_inicio: new moment.utc(values.dt_inicio),

          turnoUuid: values.turnoUuid,

          funcionarioUuid: funcionario.uuid,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      await API.post(
        'alocacaoTurnos',

        {
          // eslint-disable-next-line new-cap
          dt_inicio: new moment.utc(values.dt_retorno),

          turnoUuid: turnoAtual.uuid,

          funcionarioUuid: funcionario.uuid,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getAlocacoesTurnos();
    } catch (err) {
      alert(err);
    }
  };

  async getTurnos() {
    this.setState({
      isLoading: true,
    });

    API.get(`turnos`).then(res => {
      const result = res.data;

      this.setState({
        turnos: result,

        isLoading: false,
      });
    });
  }

  handleAlocacaoTurnoDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Turno?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`alocacaoTurnos/${uuid}`).then(() => {
        this.getAlocacoesTurnos();
      });
    }
  };

  render() {
    const { isLoading, alocacoesTurnos, turnos } = this.state;

    return (
      <SubContainer>
        <header>
          <h1>TURNOS</h1>
        </header>

        <SubContent>
          <AlocacaoTurnoList
            isLoading={isLoading}
            onAlocacaoTurnoDelete={this.handleAlocacaoTurnoDelete}
            alocacoesTurnos={alocacoesTurnos}
          />

          <AlocacaoTurnoForm
            onAlocacaoTurnoCreation={this.handleAlocacaoTurnoCreation}
            onAlocacaoTurnoTrocaRapida={this.handleAlocacaoTurnoTrocaRapida}
            turnos={turnos}
          />
        </SubContent>
      </SubContainer>
    );
  }
}

AlocacaoTurnoContainer.propTypes = {
  funcionario: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  onGetTurno: PropTypes.func.isRequired,
};

export default AlocacaoTurnoContainer;
