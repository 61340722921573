import styled from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  width: 100%;
  padding: 32px 64px;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  background: #193441;

  h1 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }

  hr {
    margin: 32px 0;
  }

  div.divider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 32px 0 8px 0;

    hr {
      width: 100%;
      margin: 0;
    }

    strong {
      font-size: 14px;
      margin: 0 16px;
      color: #fff;
    }
  }

  div.line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div.formData {
      width: 50%;

      & + div {
        margin-left: 32px;
      }
    }
  }

  div.formData {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 32px;
    padding: 0;

    strong {
      font-size: 14px;
      color: #fff;
      margin-bottom: 16px;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      background: #fff;
      border: #ddd;
      border-radius: 4px;
      font-size: 16px;
      padding: 0 15px;
      color: #333;

      display: flex;

      &:disabled {
        background: #999;
        color: #ddd;
        cursor: default;
      }
    }

    textarea {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      background: #fff;
      border: #ddd;
      border-radius: 4px;
      font-size: 16px;
      color: #333;
      padding: 15px 0 0 15px;
      font-size: 16px;
      color: #333;
      outline: none;
      font-family: Arial, Helvetica, sans-serif;

      &:disabled {
        background: none;
        color: #999;
        cursor: default;
      }
    }

    select {
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      background: #fff;
      border: #ddd;
      border-radius: 4px;
      font-size: 16px;
      color: #333;
      padding: 0 15px;

      &:disabled {
        background: none;
        color: #999;
        cursor: default;
      }
    }

    span {
      margin-top: 8px;
      color: #bf1523;
      font-size: 14px;
      font-weight: bold;
    }
  }

  div.division {
    margin: 32px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    hr {
      height: 1px;
      border: 0;
      width: 100%;
      background: #999;
      margin: 0;
    }

    p {
      font-size: 16px;
      color: #999;
      margin: 0 4px;
    }
  }

  button {
    height: 40px;
    border-radius: 4px;
    padding: 0 15px;
    box-sizing: border-box;
    background: #59d99d;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-top: 32px;

    & + button {
      margin-left: 16px;
    }

    &:hover {
      background: rgba($color: #59d99d, $alpha: 0.2);
    }

    &.delete {
      background: #bf1523;
      background: rgba($color: '#bf1523', $alpha: 0.2);
    }

    &:disabled {
      background: #ddd;
      color: #999;
      cursor: default;
    }
  }
`;
