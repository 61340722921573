import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  background: #f2f2f2;

  display: flex;

  align-items: flex-start;

  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;

  max-width: 1120px;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  padding: 0 32px 64px 32px;

  box-sizing: border-box;

  header {
    width: 100%;

    padding: 0 32px;

    box-sizing: border-box;

    margin-bottom: 32px;

    display: flex;

    align-items: center;

    justify-content: space-between;

    div.header_button {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        & + button {
          margin-left: 16px;
        }
      }
    }

    h1 {
      color: #333;

      font-size: 20px;

      font-weight: bold;
    }

    button {
      display: flex;

      align-items: center;

      justify-content: center;

      height: 40px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 16px;

      font-weight: bold;

      color: #fff;

      &:hover {
        background: rgba($color: #59d99d, $alpha: 0.2);
      }

      &:disabled {
        background: #ddd;
        color: #999;
        cursor: default;
      }
    }

    a {
      display: flex;

      align-items: center;

      justify-content: center;

      height: 40px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 16px;

      font-weight: bold;

      color: #fff;

      &:hover {
        background: rgba($color: #59d99d, $alpha: 0.2);
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 864px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button.print {
    display: flex;

    align-items: center;

    justify-content: center;

    height: 40px;

    border-radius: 4px;

    padding: 0 15px;

    box-sizing: border-box;

    background: #59d99d;

    font-size: 16px;

    font-weight: bold;

    color: #fff;

    margin-top: 32px;

    &:disabled {
      background: #ddd;
      color: #999;
      cursor: default;
    }
  }
`;

export const SubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 32px;
  padding-top: 32px;
  box-sizing: border-box;

  border-top: 2px solid #193441;

  header {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: #333;

      font-size: 20px;

      font-weight: bold;
    }

    a {
      display: flex;

      align-items: center;

      justify-content: center;

      height: 40px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 16px;

      font-weight: bold;

      color: #fff;

      &:hover {
        background: rgba($color: #59d99d, $alpha: 0.2);
      }
    }
  }
`;

export const SubContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  h1 {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin: 0 64px;
  }

  a {
    height: 35px;
    width: 35px;
    margin: 0;
    padding: 0;
  }
`;
