import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../services/api';
import Loading from '../components/Loading';
import Parameters from '../../services/parameters';
import createAuditoria from '../../services/auditoria';

// Components

import JustificativaForm from './form';
import JustificativaList from './list';

// Styles

import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class JustificativaContainer extends Component {
  state = {
    modalAddJust: false,
    isLoading: true,
    justificativas: [],
    justificativa: null,
    isLoadingJustificativa: false,
  };

  async componentDidMount() {
    createAuditoria('JUSTIFICATIVAS', localStorage.getItem('USER_UUID'));
    const { match } = this.props;
    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getJustificativa(uuid);
    }

    this.getJustificativas();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  async getJustificativas() {
    this.setState({
      isLoading: true,
    });

    await API.get(`justificativas/${Parameters.EMPRESA_DEFAULT}`).then(res => {
      const justificativas = res.data;

      this.setState({
        justificativas,

        isLoading: false,
      });
    });
  }

  async getJustificativa(uuid) {
    this.setState({
      isLoadingJustificativa: true,

      modalAddJust: true,
    });

    await API.get(`justificativas/show/${uuid}`).then(res => {
      const justificativa = res.data[0];

      this.setState({
        justificativa,

        isLoadingJustificativa: false,
      });
    });
  }

  handleJustificativaDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Justificativa?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      await API.delete(`justificativas/${uuid}`).then(() => {
        this.getJustificativas();

        this.setState({
          justificativa: null,

          modalAddJust: false,
        });
      });
    }
  };

  handleJustificativaCreation = async values => {
    try {
      await API.post(
        'justificativas',

        {
          nm_justificativa: values.nm_justificativa,
          fl_abono: values.fl_abono || false,
          fl_trabalhada: values.fl_trabalhada || false,
          fl_mostrar: values.fl_mostrar || true,
          fl_compensar: values.fl_compensar || false,
          fl_automatico: values.fl_automatico || false,
          fl_indevida: values.fl_indevida || false,
          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getJustificativas();

      this.setState({ modalAddJust: false });
    } catch (err) {
      alert(err);
    }
  };

  handleJustificativaUpdate = async values => {
    const { justificativa } = this.state;

    try {
      await API.put(
        `justificativas/${justificativa.uuid}`,

        {
          nm_justificativa: values.nm_justificativa,
          fl_abono: values.fl_abono || false,
          fl_trabalhada: values.fl_trabalhada || false,
          fl_mostrar: values.fl_mostrar || true,
          fl_compensar: values.fl_compensar || false,
          fl_automatico: values.fl_automatico || false,
          fl_indevida: values.fl_indevida || false,
          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getJustificativas();

      this.setState({ modalAddJust: false, justificativa: null });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      modalAddJust,
      isLoadingJustificativa,
      justificativas,
      isLoading,
      justificativa,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>JUSTIFICATIVAS</h1>

            <Link
              to="/justificativas"
              onClick={() =>
                this.setState(prevState => ({
                  modalAddJust: !prevState.modalAddJust,
                  justificativa: null,
                }))
              }
            >
              {!modalAddJust ? 'ADICIONAR' : 'LISTA'}
            </Link>
          </header>

          <Content>
            {modalAddJust ? (
              <>
                {!isLoadingJustificativa ? (
                  <JustificativaForm
                    onJustificativaCreation={this.handleJustificativaCreation}
                    onJustificativaUpdate={this.handleJustificativaUpdate}
                    isLoadingJustificativa={isLoadingJustificativa}
                    onJustificativaDelete={this.handleJustificativaDelete}
                    justificativa={justificativa}
                  />
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <JustificativaList
                isLoading={isLoading}
                onJustificativaGet={this.getJustificativa}
                justificativas={justificativas}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

JustificativaContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default JustificativaContainer;
