import React from 'react';

import { Formik } from 'formik';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import { FormStyled } from '../../css/styles/form';

import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ nm_motivo }) => {
  const errors = {};

  if (!nm_motivo) errors.nm_motivo = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.motivo) {
    await props.onMotivoCreation({ ...values });
  } else {
    await props.onMotivoUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const MotivoForm = props => {
  const { motivo, onMotivoDelete } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nm_motivo: motivo ? motivo.nm_motivo : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleChange,

        handleReset,

        handleBlur,

        errors,

        touched,

        dirty,

        isSubmitting,
      }) => (
        <FormStyled>
          <h1>MOTIVO</h1>

          <div className="formData">
            <strong>NOME DO MOTIVO</strong>

            <input
              id="nm_motivo"
              name="nm_motivo"
              value={values.nm_motivo}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.nm_motivo && touched.nm_motivo && (
              <span>{errors.nm_motivo}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>

          {motivo && (
            <button
              type="button"
              onClick={() => {
                onMotivoDelete(motivo.uuid);
              }}
              className="delete"
              style={{ margin: '10px' }}
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

MotivoForm.propTypes = {
  motivo: PropTypes.shape({
    uuid: PropTypes.string,

    nm_motivo: PropTypes.string,
  }),

  onMotivoDelete: PropTypes.func.isRequired,
};

MotivoForm.defaultProps = {
  motivo: null,
};

export default MotivoForm;
