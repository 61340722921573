import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';
import Loading from '../components/Loading';

const turnosListItem = props =>
  props.turnos.map(turno => (
    <li key={turno.uuid}>
      <Link to={`/turnos/${turno.uuid}`} key={turno.uuid}>
        <strong>{turno.nm_turno}</strong>
        <strong />
        <strong>{new Date(turno.dt_inicio_vigencia).toLocaleString()}</strong>
      </Link>
    </li>
  ));

function TurnoList(props) {
  const { isLoading, turnos } = props;

  return (
    <>
      {!isLoading ? (
        <>
          {turnos.length > 0 ? (
            <ListStyled>
              <header>
                <div className="total">
                  <h1>TOTAL: </h1>
                  <p>{turnos.length}</p>
                </div>

                <div className="titles">
                  <strong>NOME</strong>
                  <strong />
                  <strong>DATA DE VIGÊNCIA</strong>
                </div>
              </header>
              {turnosListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>LISTA VAZIA</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

TurnoList.propTypes = {
  isLoading: PropTypes.bool,

  turnos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_turno: PropTypes.string,
      dt_inicio_vigencia: PropTypes.string,
    })
  ),
};

TurnoList.defaultProps = {
  turnos: [],
  isLoading: false,
};

export default TurnoList;
