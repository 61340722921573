import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as DateTimeUIUtil from '../../Utilities/Datetime';
import { FormStyled } from '../../css/styles/form';

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList({ ...values });
  setSubmitting(false);
};

const AuditoriaFilterForm = props => {
  const { categorias, areas, dt_inicial, dt_final } = props;

  console.log('dt_inicial', dt_inicial);
  console.log('dt_final', dt_final);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        dt_inicial: dt_inicial ? DateTimeUIUtil.formatDateTimeInput(dt_inicial) : '',
        dt_final: dt_final ? DateTimeUIUtil.formatDateTimeInput(dt_final) : '',
        nm_area: '',
        nm_categoria: '',
        tx_detalhe: '',
        username: ''
      }}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleBlur,
        handleReset,
        handleChange,
        isSubmitting,
        dirty
      }) => (
          <FormStyled>
            <h1>FILTROS</h1>
            <div className="formData">
              <strong>DATA INICIAL</strong>
              <input
                id="dt_inicial"
                name="dt_inicial"
                type="date"
                value={values.dt_inicial}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="formData">
              <strong>DATA FINAL</strong>
              <input
                id="dt_final"
                name="dt_final"
                type="date"
                value={values.dt_final}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="formData">
              <strong>AREA</strong>
              <select
                value={values.nm_area}
                onChange={handleChange}
                onBlur={handleBlur}
                name="nm_area"
                id="nm_area"
              >
                {
                  <>
                    <option value="">SELECIONE A AREA</option>

                    {areas && areas.map(area =>
                      <option
                        key={area}
                        value={area}
                      >
                        {area}
                      </option>
                    )
                    }
                  </>
                }
              </select>
            </div>
            <div className="formData">
              <strong>CATEGORIA</strong>
              <select
                value={values.nm_area}
                onChange={handleChange}
                onBlur={handleBlur}
                name="nm_categoria"
                id="nm_categoria"
              >
                {
                  <>
                    <option value="">SELECIONE A CATEGORIA</option>

                    {categorias && categorias.map(categoria =>
                      <option
                        key={categoria}
                        value={categoria}
                      >
                        {categoria}
                      </option>
                    )
                    }
                  </>
                }
              </select>
            </div>
            <div className="formData">
            <strong>DETALHE</strong>
            <input
              placeholder="Digite uma palavra ou frase"
              id="tx_detalhe"
              name="tx_detalhe"
              value={values.tx_detalhe}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="formData">
            <strong>LOGIN</strong>
            <input
              placeholder=""
              id="username"
              name="username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>


            <button type="submit" disabled={isSubmitting}>
              BUSCAR
          </button>
            <button
              type="button"
              disabled={isSubmitting || !dirty}
              onClick={handleReset}
            >
              DESFAZER
          </button>
          </FormStyled>
        )}
    </Formik>
  );
};

AuditoriaFilterForm.propTypes = {
  auditorias: PropTypes.arrayOf(),
  nm_categoria: PropTypes.string,
  nm_area: PropTypes.string,
  dt_inicial: PropTypes.string,
  dt_final: PropTypes.string,
  tx_detalhe: PropTypes.string,
  username: PropTypes.string
};

AuditoriaFilterForm.defaultProps = {  
  nm_area: null,
  nm_categoria: null
};

export default AuditoriaFilterForm;
