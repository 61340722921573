import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../services/api';
import Loading from '../components/Loading';
import Parameters from '../../services/parameters';
import createAuditoria from '../../services/auditoria';

// Components

import CargoForm from './form';
import CargoList from './list';

// Styles

import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class CargoContainer extends Component {
  state = {
    modalAddJust: false,
    isLoading: true,
    cargos: null,
    cargo: null,
    isLoadingCargo: false,
  };

  async componentDidMount() {
    createAuditoria('CARGO',localStorage.getItem('USER_UUID'));
    const { match } = this.props;
    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getCargo(uuid);
    }

    this.getCargos();
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps;

    this.mount(match.params.uuid);
  }

  async getCargos() {
    this.setState({
      isLoading: true,
    });

    API.get(`cargos`).then(res => {
      const cargos = res.data;

      this.setState({
        cargos,

        isLoading: false,
      });
    });
  }

  getCargo = async uuid => {
    this.setState({
      isLoadingCargo: true,

      modalAddJust: true,
    });

    API.get(`cargos/${uuid}`).then(res => {
      const cargo = res.data[0];

      this.setState({
        cargo,

        isLoadingCargo: false,
      });
    });
  };

  handleCargoDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Cargo?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`cargos/${uuid}`).then(() => {
        this.getCargos();

        this.setState({
          cargo: null,

          modalAddJust: false,
        });
      });
    }
  };

  handleCargoCreation = async values => {
    try {
      await API.post(
        'cargos',

        {
          nm_cargo: values.nm_cargo,

          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getCargos();

      this.setState({ modalAddJust: false });
    } catch (err) {
      alert(err);
    }
  };

  handleCargoUpdate = async values => {
    const { cargo } = this.state;

    try {
      await API.put(
        `cargos/${cargo.uuid}`,

        {
          nm_cargo: values.nm_cargo,

          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getCargos();

      this.setState({ modalAddJust: false, cargo: null });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      modalAddJust,
      isLoadingCargo,
      cargo,
      isLoading,
      cargos,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>CARGOS</h1>

            <Link
              to="/cargos"
              onClick={() =>
                this.setState(prevState => ({
                  modalAddJust: !prevState.modalAddJust,

                  cargo: null,
                }))
              }
            >
              {!modalAddJust ? 'ADICIONAR' : 'LISTA'}
            </Link>
          </header>

          <Content>
            {modalAddJust ? (
              <>
                {!isLoadingCargo ? (
                  <CargoForm
                    onCargoCreation={this.handleCargoCreation}
                    onCargoUpdate={this.handleCargoUpdate}
                    isLoadingCargo={isLoadingCargo}
                    onCargoDelete={this.handleCargoDelete}
                    cargo={cargo}
                  />
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <CargoList
                isLoading={isLoading}
                onCargoDelete={this.handleCargoDelete}
                onCargoGet={this.getCargo}
                cargos={cargos}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

CargoContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default CargoContainer;
