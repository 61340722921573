import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';

import API from '../../services/api';

import Loading from '../components/Loading';

import Parameters from '../../services/parameters';

import createAuditoria from '../../services/auditoria';

// Components

import MotivoForm from './form';

import MotivoList from './list';

// Styles

import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class MotivoContainer extends Component {
  state = {
    modalAddJust: false,

    isLoading: true,

    motivos: [],

    motivo: null,

    isLoadingMotivo: false,
  };

  async componentDidMount() {
    createAuditoria('MOTIVOS', localStorage.getItem('USER_UUID'));

    const { match } = this.props;

    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getMotivo(uuid);
    }

    this.getMotivos();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  async getMotivos() {
    this.setState({
      isLoading: true,
    });

    await API.get(`motivos/${Parameters.EMPRESA_DEFAULT}`).then(res => {
      const motivos = res.data;

      this.setState({
        motivos,

        isLoading: false,
      });
    });
  }

  async getMotivo(uuid) {
    this.setState({
      isLoadingMotivo: true,

      modalAddJust: true,
    });

    await API.get(`motivos/show/${uuid}`).then(res => {
      const motivo = res.data[0];

      this.setState({
        motivo,

        isLoadingMotivo: false,
      });
    });
  }

  handleMotivoDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Motivo?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      await API.delete(`motivos/${uuid}`).then(() => {
        this.getMotivos();

        this.setState({
          motivo: null,

          modalAddJust: false,
        });
      });
    }
  };

  handleMotivoCreation = async values => {
    try {
      await API.post(
        'motivos',

        {
          nm_motivo: values.nm_motivo,

          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getMotivos();

      this.setState({ modalAddJust: false });
    } catch (err) {
      alert(err);
    }
  };

  handleMotivoUpdate = async values => {
    const { motivo } = this.state;

    try {
      await API.put(
        `motivos/${motivo.uuid}`,

        {
          nm_motivo: values.nm_motivo,

          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getMotivos();

      this.setState({ modalAddJust: false, motivo: null });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      modalAddJust,

      isLoadingMotivo,

      motivos,

      isLoading,

      motivo,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>MOTIVOS</h1>

            <Link
              to="/motivos"
              onClick={() =>
                this.setState(prevState => ({
                  modalAddJust: !prevState.modalAddJust,

                  motivo: null,
                }))
              }
            >
              {!modalAddJust ? 'ADICIONAR' : 'LISTA'}
            </Link>
          </header>

          <Content>
            {modalAddJust ? (
              <>
                {!isLoadingMotivo ? (
                  <MotivoForm
                    onMotivoCreation={this.handleMotivoCreation}
                    onMotivoUpdate={this.handleMotivoUpdate}
                    isLoadingMotivo={isLoadingMotivo}
                    onMotivoDelete={this.handleMotivoDelete}
                    motivo={motivo}
                  />
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <MotivoList
                isLoading={isLoading}
                onMotivoGet={this.getMotivo}
                motivos={motivos}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

MotivoContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default MotivoContainer;
