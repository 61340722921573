import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IoMdInformationCircle } from 'react-icons/io';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ListStyled, SubListStyled } from '../../css/styles/list';
import Loading from '../components/Loading';

class RelatorioList extends Component {
  state = {
    visible: false,
  };

  RelatorioListItem = () => {
    const { funcionarios } = this.props;

    const { visible } = this.state;

    return funcionarios.map(funcionario => (
      <>
        {!funcionario.folha.diasInvalidos[0] ? (
          <>
            {funcionario.folha.totalDescontadoGeral ? (
              <>
                <li key={funcionario.uuid} className="descontado">
                  <Link
                    to={`/funcionarios/${funcionario.uuid}`}
                    key={funcionario.uuid}
                  >
                    <strong className="name">
                      {funcionario.nm_funcionario}
                    </strong>
                    <strong>
                      {funcionario.folha.totalTrabalhadoGeral
                        ? funcionario.folha.totalTrabalhadoGeral
                        : 0}
                    </strong>
                    <strong>
                      {funcionario.folha.totalDescontadoGeral
                        ? funcionario.folha.totalDescontadoGeral
                        : 0}
                    </strong>
                    <strong>
                      {funcionario.folha.totalExtra50Geral
                        ? funcionario.folha.totalExtra50Geral
                        : 0}
                    </strong>
                    <strong>
                      {funcionario.folha.totalExtra100Geral
                        ? funcionario.folha.totalExtra100Geral
                        : 0}
                    </strong>
                    <strong>
                      {funcionario.folha.totalNoturnoGeral
                        ? funcionario.folha.totalNoturnoGeral
                        : 0}
                    </strong>
                  </Link>
                  <button
                    type="button"
                    disabled={
                      !funcionario.folha.relatorioData &&
                      !funcionario.folha.relatorioData[0]
                    }
                    onClick={() => {
                      if (visible === funcionario.uuid) {
                        this.setState({ visible: false });
                      } else {
                        this.setState({ visible: funcionario.uuid });
                      }
                    }}
                  >
                    {funcionario.folha.relatorioData &&
                    funcionario.folha.relatorioData[0] ? (
                      <IoMdInformationCircle size={20} color="#FFC300" />
                    ) : (
                      <IoMdInformationCircle size={20} color="#999" />
                    )}
                  </button>
                </li>
                <SubListStyled visible={visible === funcionario.uuid}>
                  {funcionario.folha.relatorioData.map(dia => (
                    <>
                      <strong key={dia.dataBase}>
                        {`${moment.utc(dia.dataBase).format('DD/MM')} -
                      ${dia.tipo}`}
                        {dia.pontos && ` - ${dia.pontos}`}
                      </strong>
                    </>
                  ))}
                </SubListStyled>
                {funcionario.folha.pontosNaoAlocados &&
                  funcionario.folha.pontosNaoAlocados[0] && (
                    <SubListStyled visible={visible === funcionario.uuid}>
                      <strong>PONTOS NÃO ALOCADOS:</strong>
                      {funcionario.folha.pontosNaoAlocados.map(ponto => (
                        <strong
                          key={moment
                            .utc(ponto.dt_calendario)
                            .format('DD/MM/YYYY hh:mm')}
                        >
                          {moment
                            .utc(ponto.dt_calendario)
                            .format('DD/MM/YYYY hh:mm')}
                        </strong>
                      ))}
                    </SubListStyled>
                  )}
              </>
            ) : (
              <>
                <li key={funcionario.uuid}>
                  <Link
                    to={`/funcionarios/${funcionario.uuid}`}
                    key={funcionario.uuid}
                  >
                    <strong className="name">
                      {funcionario.nm_funcionario}
                    </strong>
                    <strong>
                      {funcionario.folha.totalTrabalhadoGeral
                        ? funcionario.folha.totalTrabalhadoGeral
                        : 0}
                    </strong>
                    <strong>
                      {funcionario.folha.totalDescontadoGeral
                        ? funcionario.folha.totalDescontadoGeral
                        : 0}
                    </strong>
                    <strong>
                      {funcionario.folha.totalExtra50Geral
                        ? funcionario.folha.totalExtra50Geral
                        : 0}
                    </strong>
                    <strong>
                      {funcionario.folha.totalExtra100Geral
                        ? funcionario.folha.totalExtra100Geral
                        : 0}
                    </strong>
                    <strong>
                      {funcionario.folha.totalNoturnoGeral
                        ? funcionario.folha.totalNoturnoGeral
                        : 0}
                    </strong>
                  </Link>
                  <button
                    type="button"
                    disabled={
                      !funcionario.folha.relatorioData &&
                      !funcionario.folha.relatorioData[0]
                    }
                    onClick={() => {
                      if (visible === funcionario.uuid) {
                        this.setState({ visible: false });
                      } else {
                        this.setState({ visible: funcionario.uuid });
                      }
                    }}
                  >
                    {funcionario.folha.relatorioData &&
                    funcionario.folha.relatorioData[0] ? (
                      <IoMdInformationCircle size={20} color="#FFC300" />
                    ) : (
                      <IoMdInformationCircle size={20} color="#999" />
                    )}
                  </button>
                </li>
                <SubListStyled visible={visible === funcionario.uuid}>
                  {funcionario.folha.relatorioData.map(dia => (
                    <>
                      <strong key={dia.dataBase}>
                        {`${moment.utc(dia.dataBase).format('DD/MM')} -
                      ${dia.tipo}`}
                        {dia.pontos && ` - ${dia.pontos}`}
                      </strong>
                    </>
                  ))}
                </SubListStyled>
                {funcionario.folha.pontosNaoAlocados &&
                  funcionario.folha.pontosNaoAlocados[0] && (
                    <SubListStyled visible={visible === funcionario.uuid}>
                      <strong>PONTOS NÃO ALOCADOS:</strong>
                      {funcionario.folha.pontosNaoAlocados.map(ponto => (
                        <strong
                          key={moment
                            .utc(ponto.dt_calendario)
                            .format('DD/MM/YYYY hh:mm')}
                        >
                          {moment
                            .utc(ponto.dt_calendario)
                            .format('DD/MM/YYYY hh:mm')}
                        </strong>
                      ))}
                    </SubListStyled>
                  )}
              </>
            )}
          </>
        ) : (
          <>
            <li key={funcionario.uuid} className="irregular">
              <Link
                to={`/funcionarios/${funcionario.uuid}`}
                key={funcionario.uuid}
              >
                <strong className="name">{funcionario.nm_funcionario} </strong>
                <strong />
                <strong />
                <strong>PONTO IRREGULAR</strong>
                <strong />
                <strong />
              </Link>
              <button
                type="button"
                disabled={
                  !funcionario.folha.relatorioData &&
                  !funcionario.folha.relatorioData[0]
                }
                onClick={() => {
                  if (visible === funcionario.uuid) {
                    this.setState({ visible: false });
                  } else {
                    this.setState({ visible: funcionario.uuid });
                  }
                }}
              >
                {funcionario.folha.relatorioData ? (
                  <IoMdInformationCircle size={20} color="#FFC300" />
                ) : (
                  <IoMdInformationCircle size={20} color="#FF9D8B" />
                )}
              </button>
            </li>
            <SubListStyled visible={visible === funcionario.uuid}>
              {funcionario.folha.relatorioData.map(dia => (
                <>
                  <strong key={dia.dataBase}>
                    {`${moment.utc(dia.dataBase).format('DD/MM')} -
                      ${dia.tipo}`}
                    {dia.pontos && ` - ${dia.pontos}`}
                  </strong>
                </>
              ))}
            </SubListStyled>
            {funcionario.folha.pontosNaoAlocados &&
              funcionario.folha.pontosNaoAlocados[0] && (
                <SubListStyled visible={visible === funcionario.uuid}>
                  <strong>PONTOS NÃO ALOCADOS:</strong>
                  {funcionario.folha.pontosNaoAlocados.map(ponto => (
                    <strong
                      key={moment
                        .utc(ponto.dt_calendario)
                        .format('DD/MM/YYYY hh:mm')}
                    >
                      {moment
                        .utc(ponto.dt_calendario)
                        .format('DD/MM/YYYY hh:mm')}
                    </strong>
                  ))}
                </SubListStyled>
              )}
          </>
        )}
      </>
    ));
  };

  render() {
    const { isLoading, funcionarios } = this.props;

    return (
      <>
        {!isLoading ? (
          <>
            {funcionarios && (
              <>
                {funcionarios.length > 0 ? (
                  <ListStyled>
                    <header>
                      <div className="total">
                        <h1>TOTAL: </h1>
                        <p>{funcionarios.length}</p>
                      </div>

                      <div className="titles">
                        <strong className="name">NOME</strong>
                        <strong>TRABALHADO</strong>
                        <strong>DESCONTADO</strong>
                        <strong>EXTRA50</strong>
                        <strong>EXTRA100</strong>
                        <strong>NOTURNO</strong>
                        <strong />
                      </div>
                    </header>

                    {this.RelatorioListItem()}
                  </ListStyled>
                ) : (
                  <ListStyled>
                    <strong>LISTA VAZIA</strong>
                  </ListStyled>
                )}
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}
RelatorioList.propTypes = {
  isLoading: PropTypes.bool,
  funcionarios: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_funcionario: PropTypes.string,
      cd_funcionario: PropTypes.string,
      folha: PropTypes.shape({
        diasInvalidos: PropTypes.arrayOf(
          PropTypes.shape({
            tipo: PropTypes.string,
            dataBase: PropTypes.string,
          })
        ),
        totalDescontadoGeral: PropTypes.string,
        totalTrabalhadoGeral: PropTypes.string,
        totalExtra100Geral: PropTypes.string,
        totalExtra50Geral: PropTypes.string,
        totalNoturnoGeral: PropTypes.string,
      }),
    })
  ),
};

RelatorioList.defaultProps = {
  funcionarios: [],
  isLoading: false,
};

export default RelatorioList;
