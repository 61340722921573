/* eslint-disable array-callback-return */
import React, { useRef } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';

import ReactSelect from 'react-select';

import SelectStyled from '../components/ReactSelect/styles';

import { FormStyled } from '../../css/styles/form';

const handleValidate = ({ begin, end }) => {
  const errors = {};

  if (end && !begin) errors.begin = 'OBRIGATÓRIO';

  if (begin && !end) errors.end = 'OBRIGATÓRIO';

  if (begin && end && moment(begin).isAfter(end))
    errors.end = 'DATA FINAL NÃO PODE SER MENOR QUE INICIAL';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList(values);

  setSubmitting(false);
};

const FechamentoFilterForm = props => {
  const { superiores, departamentos } = props;

  const mes =
    // eslint-disable-next-line radix
    parseInt(
      moment()
        .subtract(1, 'months')

        .format('MM')
    ) - 1;

  const ano = moment()
    .subtract(1, 'months')

    .format('YYYY');

  const mesOptions = [
    'Janeiro-Fevereiro',

    'Fevereiro-Março',

    'Março-Abril',

    'Abril-Maio',

    'Maio-Junho',

    'Junho-Julho',

    'Julho-Agosto',

    'Agosto-Setembro',

    'Setembro-Outubro',

    'Outubro-Novembro',

    'Novembro-Dezembro',

    'Dezembro-Janeiro',
  ];

  const formRef = useRef();
  const dptRef = useRef();
  const supRef = useRef();

  const clearFields = () => {
    if (formRef) {
      dptRef.current.select.clearValue();
      supRef.current.select.clearValue();
      formRef.current.resetForm();
    }
  };

  return (
    <Formik
      ref={formRef}
      onSubmit={handleSubmit(props)}
      validate={handleValidate}
      enableReinitialize
      initialValues={{
        mes,

        ano,

        begin: '',

        end: '',

        superiorUuid: '',

        departamentos: '',

        tipo: '',
      }}
    >
      {({
        values,

        handleChange,

        handleBlur,

        errors,

        touched,

        setFieldValue,

        isSubmitting,

        dirty,
      }) => (
        <FormStyled>
          <h1>FOLHA DE PONTO</h1>

          <div className="line">
            <div className="formData">
              <strong>MÊS BASE</strong>

              <select
                id="mes"
                name="mes"
                value={values.mes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.mes && touched.mes)}
              >
                {mesOptions.map((option, index) => (
                  <option key={option} value={index}>
                    {option}
                  </option>
                ))}
              </select>

              {errors.mes && touched.mes && <span>{errors.mes}</span>}
            </div>

            <div className="formData">
              <strong>ANO</strong>

              <input
                id="ano"
                name="ano"
                type="number"
                value={values.ano}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.ano && touched.ano)}
              />

              {errors.ano && touched.ano && <span>{errors.ano}</span>}
            </div>
          </div>

          <div className="formData">
            <strong>SUPERVISOR</strong>

            <ReactSelect
              id="superiorUuid"
              name="superiorUuid"
              ref={supRef}
              isMulti
              isSearchable
              isClearable={false}
              placeholder="Selecione um supervisor"
              styles={SelectStyled}
              className="basic-multi-select"
              classNamePrefix="select"
              value={values.superiorUuid.uuid}
              onChange={selectedDpt => {
                const opt = [];
                if (selectedDpt !== null) {
                  selectedDpt.map(item => {
                    opt.push(item.uuid);
                  });
                  setFieldValue('superiorUuid', opt);
                  if (values.superiorUuid && values.superiorUuid[0] === null) {
                    setFieldValue('superiorUuid', '');
                  }
                }
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={superiores}
              getOptionValue={item => item.uuid}
              getOptionLabel={item => item.nm_funcionario}
              noOptionsMessage={() => 'Não há itens para selecionar!'}
            />
          </div>

          <div className="formData">
            <strong>DEPARTAMENTO</strong>

            <ReactSelect
              id="departamentos"
              name="departamentos"
              ref={dptRef}
              isMulti
              isSearchable
              isClearable={false}
              placeholder="Selecionar departamento"
              styles={SelectStyled}
              className="basic-multi-select"
              classNamePrefix="select"
              value={values.departamentos.uuid}
              onChange={selectedDpt => {
                const opt = [];
                if (selectedDpt !== null) {
                  selectedDpt.map(item => {
                    opt.push(item.uuid);
                  });
                  setFieldValue('departamentos', opt);
                  if (
                    values.departamentos &&
                    values.departamentos[0] === null
                  ) {
                    setFieldValue('departamentos', '');
                  }
                }
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={departamentos}
              getOptionValue={item => item.uuid}
              getOptionLabel={item => item.nm_departamento}
              noOptionsMessage={() => 'Não há itens para selecionar!'}
            />
          </div>

          <div className="formData">
            <strong>TIPO</strong>
            <select
              id="tipo"
              name="tipo"
              value={values.tipo}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" />
              <option value="REGULAR">REGULAR</option>
              <option value="PENDENTE">PENDENTE</option>
              <option value="DESCONTADO">DESCONTADO</option>
              <option value="HORAS EXTRA">HORAS EXTRA</option>
              <option value="DESCONTADO + HORAS EXTRA">
                DESCONTADO + HORAS EXTRA
              </option>
            </select>
          </div>

          <button type="submit" disabled={isSubmitting}>
            PESQUISAR
          </button>

          <button
            type="button"
            disabled={isSubmitting || !dirty}
            onClick={clearFields}
          >
            LIMPAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

export default FechamentoFilterForm;

FechamentoFilterForm.propTypes = {
  superiores: PropTypes.arrayOf(
    PropTypes.shape({
      fl_responsavel_ponto: PropTypes.bool,
      uuid: PropTypes.string,
      nm_funcionario: PropTypes.string,
    })
  ),
  departamentos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_departamento: PropTypes.string,
    })
  ),
};

FechamentoFilterForm.defaultProps = {
  superiores: [],
  departamentos: [],
};
