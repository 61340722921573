import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FormStyled } from '../../css/styles/form';
import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ nm_departamento }) => {
  const errors = {};

  if (!nm_departamento) errors.nm_departamento = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.departamento) {
    await props.onDepartamentoCreation({ ...values });
  } else {
    await props.onDepartamentoUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const DepartamentoForm = props => {
  const { departamento, onDepartamentoDelete } = props;  
  return (
    <Formik
      enableReinitialize
      initialValues={{
        nm_departamento: departamento ? departamento.nm_departamento : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleReset,
        handleBlur,
        errors,
        touched,
        dirty,
        isSubmitting,
      }) => (
        <FormStyled>
          <h1>DEPARTAMENTO</h1>
          <div className="formData">
            <strong>NOME DO DEPARTAMENTO</strong>
            <input
              id="nm_departamento"
              name="nm_departamento"
              value={values.nm_departamento}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.nm_departamento && touched.nm_departamento && (
              <span>{errors.nm_departamento}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>
          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>
          {departamento && (
            <button
              type="button"
              onClick={() => {
                onDepartamentoDelete(departamento.uuid);
              }}
              className="delete"
              style={{ margin: '10px' }}
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

DepartamentoForm.propTypes = {
  departamento: PropTypes.shape({
    uuid: PropTypes.string,
    nm_departamento: PropTypes.string,
  }),
  onDepartamentoDelete: PropTypes.func.isRequired,
};

DepartamentoForm.defaultProps = {
  departamento: null,
};

export default DepartamentoForm;
