import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../../services/api';

// Components

import AlocacaoCargoList from './list';
import AlocacaoCargoForm from './form';

// Styles

import { SubContainer, SubContent } from '../../../css/styles/wrapper';

class AlocacaoCargoContainer extends Component {
  state = {
    isLoading: true,
    alocacoesCargos: null,
    cargoContratos: null,
  };

  async componentDidMount() {    
    this.mount();
  }

  mount() {
    this.getAlocacoesCargos();

    this.getCargoContratos();
  }

  async getAlocacoesCargos() {
    this.setState({
      isLoading: true,
    });

    const { funcionario } = this.props;

    API.get(`alocacaoCargos/funcionario/${funcionario.uuid}`).then(res => {
      const result = res.data;

      this.setState({
        alocacoesCargos: result,

        isLoading: false,
      });
    });
  }

  handleAlocacaoCargoCreation = async values => {
    const { funcionario } = this.props;

    try {
      await API.post(
        'alocacaoCargos',

        {
          dt_inicio: values.dt_inicio,

          cargoContratoUuid: values.cargoContratoUuid,

          funcionarioUuid: funcionario.uuid,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getAlocacoesCargos();
    } catch (err) {
      alert(err);
    }
  };

  async getCargoContratos() {
    this.setState({
      isLoading: true,
    });

    API.get(`cargoContratos`).then(res => {
      const result = res.data;

      this.setState({
        cargoContratos: result,

        isLoading: false,
      });
    });
  }

  handleContratoChange() {
    this.getCargos();
  }

  handleAlocacaoCargoDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Cargo?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`alocacaoCargos/${uuid}`).then(() => {
        this.getAlocacoesCargos();
      });
    }
  };

  render() {
    const { isLoading, alocacoesCargos, cargoContratos } = this.state;

    return (
      <SubContainer>
        <header>
          <h1>CARGOS</h1>
        </header>

        <SubContent>
          <AlocacaoCargoList
            isLoading={isLoading}
            onAlocacaoCargoDelete={this.handleAlocacaoCargoDelete}
            alocacoesCargos={alocacoesCargos}
          />
          <AlocacaoCargoForm
              onAlocacaoCargoCreation={this.handleAlocacaoCargoCreation}
              cargoContratos={cargoContratos}
          />
        </SubContent>
      </SubContainer>
    );
  }
}

AlocacaoCargoContainer.propTypes = {
  funcionario: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default AlocacaoCargoContainer;
