import React, { Fragment, Component } from 'react';

import { IoMdArrowDropdown, IoMdArrowDropleft, IoMdAdd } from 'react-icons/io';

import PropTypes from 'prop-types';

import moment from 'moment';

import Loading from '../../components/Loading';

import {
  TableContainer,
  TableStyled,
  Row,
  TableButton,
} from '../../../css/styles/table';

class FrequenciaTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataBaseVisible: false,
    };
  }

  getJustificativa = calendario => {
    const { onEdit, closed } = this.props;

    const pontosQtd = calendario.calendarios
      ? calendario.calendarios.length
      : 0;

    if (
      pontosQtd % 2 !== 0 &&
      pontosQtd <= 4 &&
      (calendario && !calendario.cronograma.data_justificada)
    ) {
      return (
        <>
          <td>
            {calendario.calendarios[0] ? (
              <TableButton
                apontamento={calendario.calendarios[0].cd_tipo_apontamento}
                type="button"
                onClick={() => {
                  if (calendario.calendarios[0].cd_tipo_apontamento !== 'A') {
                    onEdit(calendario.calendarios[0]);
                  }
                }}
                disabled={
                  closed ||
                  calendario.calendarios[0].cd_tipo_apontamento === 'A'
                }
              >
                {calendario.cronograma.fl_virada ||
                moment(calendario.dataBase).isAfter(
                  calendario.calendarios[0].dt_calendario,
                  'year',
                  'month',
                  'day'
                )
                  ? `+${moment(calendario.calendarios[0].dt_calendario)
                      .utc()

                      .format('HH:mm')}`
                  : moment(calendario.calendarios[0].dt_calendario)
                      .utc()

                      .format('HH:mm')}
              </TableButton>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[1] ? (
              <TableButton
                apontamento={calendario.calendarios[1].cd_tipo_apontamento}
                type="button"
                onClick={() => {
                  if (calendario.calendarios[1].cd_tipo_apontamento !== 'A') {
                    onEdit(calendario.calendarios[1]);
                  }
                }}
                disabled={
                  closed ||
                  calendario.calendarios[1].cd_tipo_apontamento === 'A'
                }
              >
                {moment(calendario.calendarios[1].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </TableButton>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[2] ? (
              <TableButton
                apontamento={calendario.calendarios[2].cd_tipo_apontamento}
                type="button"
                onClick={() => {
                  if (calendario.calendarios[2].cd_tipo_apontamento !== 'A') {
                    onEdit(calendario.calendarios[2]);
                  }
                }}
                disabled={
                  closed ||
                  calendario.calendarios[2].cd_tipo_apontamento === 'A'
                }
              >
                {moment(calendario.calendarios[2].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </TableButton>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[3] ? (
              <TableButton
                apontamento={calendario.calendarios[3].cd_tipo_apontamento}
                type="button"
                onClick={() => {
                  if (calendario.calendarios[3].cd_tipo_apontamento !== 'A') {
                    onEdit(calendario.calendarios[3]);
                  }
                }}
                disabled={
                  closed ||
                  calendario.calendarios[3].cd_tipo_apontamento === 'A'
                }
              >
                {moment(calendario.calendarios[3].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </TableButton>
            ) : (
              '-'
            )}
          </td>
        </>
      );
    }

    if (calendario.cronograma.data_justificada) {
      const { justificativa } = calendario.cronograma.data_justificada;

      if (justificativa.fl_mostrar && !justificativa.fl_compensar) {
        return (
          <>
            <td colSpan={4}>
              <TableButton
                type="button"
                disabled={closed}
                onClick={() => {
                  onEdit(calendario, calendario.cronograma);
                }}
              >
                {
                  calendario.cronograma.data_justificada.justificativa
                    .nm_justificativa
                }
              </TableButton>
            </td>
          </>
        );
      }
    }

    if (calendario.calendarios[0] && !calendario.data_justificada) {

      return (
        <>
          <td>
            {calendario.calendarios[0] ? (
              <TableButton
                apontamento={calendario.calendarios[0].cd_tipo_apontamento}
                type="button"
                onClick={() => {
                  if (calendario.calendarios[0].cd_tipo_apontamento !== 'A') {
                    onEdit(calendario.calendarios[0]);
                  }
                }}
                disabled={
                  closed ||
                  calendario.calendarios[0].cd_tipo_apontamento === 'A'
                }
              >
                {calendario.cronograma.fl_virada ||
                moment(calendario.calendarios[0].dt_calendario).isBefore(
                  calendario.dataBase,
                  'year',
                  'month',
                  'day'
                )
                  ? `+${moment(calendario.calendarios[0].dt_calendario)
                      .utc()

                      .format('HH:mm')}`
                  : moment(calendario.calendarios[0].dt_calendario)
                      .utc()

                      .format('HH:mm')}
              </TableButton>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[1] ? (
              <TableButton
                apontamento={calendario.calendarios[1].cd_tipo_apontamento}
                type="button"
                onClick={() => {
                  if (calendario.calendarios[1].cd_tipo_apontamento !== 'A') {
                    onEdit(calendario.calendarios[1]);
                  }
                }}
                disabled={
                  closed ||
                  calendario.calendarios[1].cd_tipo_apontamento === 'A'
                }
              >
                {moment(calendario.calendarios[1].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </TableButton>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[2] ? (
              <TableButton
                apontamento={calendario.calendarios[2].cd_tipo_apontamento}
                type="button"
                onClick={() => {
                  if (calendario.calendarios[2].cd_tipo_apontamento !== 'A') {
                    onEdit(calendario.calendarios[2]);
                  }
                }}
                disabled={
                  closed ||
                  calendario.calendarios[2].cd_tipo_apontamento === 'A'
                }
              >
                {moment(calendario.calendarios[2].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </TableButton>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[3] ? (
              <TableButton
                apontamento={calendario.calendarios[3].cd_tipo_apontamento}
                type="button"
                onClick={() => {
                  if (calendario.calendarios[3].cd_tipo_apontamento !== 'A') {
                    onEdit(calendario.calendarios[3]);
                  }
                }}
                disabled={
                  closed ||
                  calendario.calendarios[3].cd_tipo_apontamento === 'A'
                }
              >
                {moment(calendario.calendarios[3].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </TableButton>
            ) : (
              '-'
            )}
          </td>
        </>
      );
    }

    return (
      <>
        <td>-</td>

        <td>-</td>

        <td>-</td>

        <td>-</td>
      </>
    );
  };

  cronogramasListItem = () => {
    const { frequencia, onEdit, onAdd, closed } = this.props;

    const { dataBaseVisible } = this.state;

    return frequencia.dados.map(calendario => {
      const pontosQtd = calendario.calendarios
        ? calendario.calendarios.length
        : 0;

      let justificativaAbono = false;

      if (
        calendario &&
        calendario.cronograma.data_justificada &&
        calendario.cronograma.data_justificada.justificativa.fl_abono
      ) {
        justificativaAbono = true;
      }

      return (
        <Fragment key={calendario.dataBase}>
          {pontosQtd % 2 !== 0 && pontosQtd <= 4 && !justificativaAbono ? (
            <Row>
              <td className="irregular">
                <TableButton
                  type="button"
                  disabled={closed}
                  onClick={() =>
                    calendario.cronograma &&
                    calendario.cronograma.data_justificada
                      ? onEdit(calendario, calendario.cronograma)
                      : onEdit(null, calendario.cronograma, calendario.dataBase)
                  }
                >
                  <strong>{moment(calendario.dataBase).format('DD/MM')}</strong>
                </TableButton>
              </td>

              <td className="irregular">
                <strong>
                  {calendario.totais && calendario.totais.falta
                    ? 'FALTA'
                    : calendario.cronograma.cd_cronograma}
                </strong>
              </td>

              {this.getJustificativa(calendario)}

              <td>
                {calendario.cronograma.cd_cronograma === 'TRABALHO'
                  ? calendario.cronograma.qt_hr_trabalho.substring(0, 5)
                  : '-'}
              </td>

              <td>{calendario.totais ? calendario.totais.trabalhado : '-'}</td>

              <td>{calendario.totais ? calendario.totais.descontado : '-'}</td>

              <td>
                <center>
                  {calendario.totais ? calendario.totais.extra50 : '-'}
                </center>
              </td>

              <td>{calendario.totais ? calendario.totais.extra100 : '-'}</td>

              <td
                style={{
                  borderBottom: '1px solid #ddd',

                  borderTop: '1px solid #ddd',
                }}
              >
                <center>
                  {calendario.totais ? calendario.totais.noturno : '-'}
                </center>
              </td>

              <td>
                {calendario.totais ? calendario.totais.saldoDeHoras : '-'}
              </td>

              <td className="last">
                <TableButton
                  type="button"
                  disabled={closed}
                  onClick={() =>
                    onAdd(moment(calendario.dataBase).format('YYYY-MM-DD'))
                  }
                >
                  <IoMdAdd color="#59d99d" size={16} />
                </TableButton>
              </td>
            </Row>
          ) : (
            <Row>
              <td>
                <TableButton
                  type="button"
                  disabled={closed}
                  onClick={() =>
                    calendario.cronograma &&
                    calendario.cronograma.data_justificada
                      ? onEdit(calendario, calendario.cronograma)
                      : onEdit(null, calendario.cronograma, calendario.dataBase)
                  }
                >
                  <strong>{moment(calendario.dataBase).format('DD/MM')}</strong>
                </TableButton>
              </td>

              <td>
                {calendario.totais && calendario.totais.falta
                  ? 'FALTA'
                  : calendario.cronograma.cd_cronograma}
              </td>

              {this.getJustificativa(calendario)}

              <td>
                {calendario.cronograma.cd_cronograma === 'TRABALHO'
                  ? calendario.cronograma.qt_hr_trabalho.substring(0, 5)
                  : '-'}
              </td>

              <td>
                {calendario.totais && calendario.totais.trabalhado
                  ? calendario.totais.trabalhado
                  : '-'}
              </td>

              <td>
                {calendario.totais && calendario.totais.descontado
                  ? calendario.totais.descontado
                  : '-'}
              </td>

              <td>
                {calendario.totais && calendario.totais.extra50
                  ? calendario.totais.extra50
                  : '-'}
              </td>

              <td>
                {calendario.totais && calendario.totais.extra100
                  ? calendario.totais.extra100
                  : '-'}
              </td>

              <td>
                {calendario.totais && calendario.totais.noturno
                  ? calendario.totais.noturno
                  : '-'}
              </td>

              <td>
                {calendario.totais && calendario.totais.noturnoMultiplicado
                  ? calendario.totais.noturnoMultiplicado
                  : '-'}
              </td>

              <td>
                {calendario.totais && calendario.totais.saldoDeHoras
                  ? calendario.totais.saldoDeHoras
                  : '-'}
              </td>

              <td className="last">
                <TableButton
                  type="button"
                  disabled={closed}
                  onClick={() =>
                    onAdd(moment(calendario.dataBase).format('YYYY-MM-DD'))
                  }
                >
                  <IoMdAdd color="#59d99d" size={16} />
                </TableButton>
              </td>
            </Row>
          )}

          {dataBaseVisible && (
            <Row id={calendario.dataBase} key={calendario.dataBase}>
              <td className="database">
                <strong>{moment(calendario.dataBase).format('DD/MM')}</strong>
              </td>

              {calendario.calendarioDatabase.map(db => (
                <td>
                  <TableButton
                    apontamento={db.cd_tipo_apontamento}
                    type="button"
                    disabled={closed}
                    onClick={() => onEdit(db, calendario.cronograma)}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {db ? (
                      <>
                        {moment(db.dt_calendario)
                          .utc()

                          .format('HH:mm')}
                      </>
                    ) : (
                      ''
                    )}
                  </TableButton>
                </td>
              ))}
            </Row>
          )}
        </Fragment>
      );
    });
  };

  render() {
    const { isLoadingFrequency, loading, frequencia } = this.props;

    const { dataBaseVisible } = this.state;

    moment.locale('en');

    return (
      <TableContainer>
        {!isLoadingFrequency && !loading ? (
          <>
            {frequencia && (
              <TableStyled id="students">
                <tbody>
                  <Row>
                    <th>Data Base</th>

                    <th>Tipo</th>

                    <th>Entrada 01</th>

                    <th>Saida 01</th>

                    <th>Entrada 02</th>

                    <th>Saida 02</th>

                    <th>Expediente</th>

                    <th>Trabalhado</th>

                    <th>Descontado</th>

                    <th>Extras 50%</th>

                    <th>Extras 100%</th>

                    <th>Adc. Noturno</th>

                    <th>Adc. Noturno Calculado</th>

                    <th>Banco de Horas</th>

                    <th>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            dataBaseVisible: !dataBaseVisible,
                          });
                        }}
                      >
                        {dataBaseVisible ? (
                          <IoMdArrowDropleft size={20} color="#fff" />
                        ) : (
                          <IoMdArrowDropdown size={20} color="#fff" />
                        )}
                      </button>
                    </th>
                  </Row>

                  {this.cronogramasListItem()}

                  <Row>
                    <th />

                    <th />

                    <th />

                    <th />

                    <th />

                    <th />

                    <th>Totais:</th>

                    <th>{frequencia.totalTrabalhadoGeral}</th>

                    <th>{frequencia.totalDescontadoGeral}</th>

                    <th>{frequencia.totalExtra50Geral}</th>

                    <th>{frequencia.totalExtra100Geral}</th>

                    <th>{frequencia.totalNoturnoGeral}</th>

                    <th>{frequencia.totalNoturnoMultiplicado}</th>

                    <th>{frequencia.totalBancoDeHoraGeral}</th>

                    <th />
                  </Row>
                </tbody>
              </TableStyled>
            )}
          </>
        ) : (
          <Loading />
        )}
      </TableContainer>
    );
  }
}

FrequenciaTable.propTypes = {
  frequencia: PropTypes.shape({
    dados: PropTypes.array,

    totalNoturnoGeral: PropTypes.string,

    totalNoturnoMultiplicado: PropTypes.string,

    totalTrabalhadoGeral: PropTypes.string,

    totalDescontadoGeral: PropTypes.string,

    totalExtra50Geral: PropTypes.string,

    totalExtra100Geral: PropTypes.string,

    totalBancoDeHoraGeral: PropTypes.string,

    diasInvalidos: PropTypes.arrayOf(PropTypes.shape({})),
  }),

  onEdit: PropTypes.func.isRequired,

  isLoadingFrequency: PropTypes.bool.isRequired,

  loading: PropTypes.bool.isRequired,

  onAdd: PropTypes.func.isRequired,

  closed: PropTypes.bool,
};

FrequenciaTable.defaultProps = {
  frequencia: null,
  closed: false,
};

export default FrequenciaTable;
