import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';
import Loading from '../components/Loading';

const cargosListItem = props =>
  props.cargos.map(cargo => (
    <li key={cargo.uuid}>
      <Link to={`/cargos/${cargo.uuid}`} key={cargo.uuid}>
        <strong>{cargo.nm_cargo}</strong>
        <strong />
        <strong>{new Date(cargo.createdAt).toLocaleString()}</strong>
      </Link>
    </li>
  ));

function CargoList(props) {
  const { isLoading, cargos } = props;

  return (
    <>
      {!isLoading ? (
        <>
          {cargos.length > 0 ? (
            <ListStyled>
              <header>
                <div className="total">
                  <h1>TOTAL: </h1>
                  <p>{cargos.length}</p>
                </div>

                <div className="titles">
                  <strong>NOME</strong>
                  <strong />
                  <strong>DATA DE CRIAÇÃO</strong>
                </div>
              </header>
              {cargosListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>LISTA VAZIA</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

CargoList.propTypes = {
  isLoading: PropTypes.bool,

  cargos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_departamento: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
};

CargoList.defaultProps = {
  cargos: [],
  isLoading: false,
};

export default CargoList;
