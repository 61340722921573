import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FormStyled } from '../../css/styles/form';
import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ nm_cargo }) => {
  const errors = {};

  if (!nm_cargo) errors.nm_cargo = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.cargo) {
    await props.onCargoCreation({ ...values });
  } else {
    await props.onCargoUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const CargoForm = props => {
  const { cargo, onCargoDelete } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nm_cargo: cargo ? cargo.nm_cargo : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        dirty,
        isSubmitting,
      }) => (
        <FormStyled>
          <h1>CARGO</h1>
          <div className="formData">
            <strong>NOME DO CARGO</strong>
            <input
              id="nm_cargo"
              name="nm_cargo"
              value={values.nm_cargo}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.nm_cargo && touched.nm_cargo && (
              <span>{errors.nm_cargo}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>

          {cargo && (
            <button
              type="button"
              onClick={() => {
                onCargoDelete(cargo.uuid);
              }}
              className="delete"
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

CargoForm.propTypes = {
  cargo: PropTypes.shape({
    uuid: PropTypes.string,
    nm_cargo: PropTypes.string,
  }),
  onCargoDelete: PropTypes.func.isRequired,
};

CargoForm.defaultProps = {
  cargo: null,
};

export default CargoForm;
