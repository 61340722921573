import React, { Fragment, Component } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import HeaderReport from '../../components/HeaderReport/index';

import { Content, Table } from '../../../css/styles/newPage';

class ReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getJustificativa = calendario => {
    const pontosQtd = calendario.calendarios
      ? calendario.calendarios.length
      : 0;

    if (
      pontosQtd % 2 !== 0 &&
      pontosQtd <= 4 &&
      (calendario && !calendario.cronograma.data_justificada)
    ) {
      return (
        <>
          <td>
            {calendario.calendarios[0] ? (
              <>
                {calendario.cronograma.fl_virada
                  ? `+${moment(calendario.calendarios[0].dt_calendario)
                      .utc()

                      .format('HH:mm')}`
                  : moment(calendario.calendarios[0].dt_calendario)
                      .utc()

                      .format('HH:mm')}
              </>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[1] ? (
              <>
                {moment(calendario.calendarios[1].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[2] ? (
              <>
                {moment(calendario.calendarios[2].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[3] ? (
              <>
                {moment(calendario.calendarios[3].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </>
            ) : (
              '-'
            )}
          </td>
        </>
      );
    }

    if (calendario.cronograma.data_justificada) {
      const { justificativa } = calendario.cronograma.data_justificada;

      if (justificativa.fl_mostrar && !justificativa.fl_compensar) {
        return (
          <>
            <td colSpan={4}>
              {
                calendario.cronograma.data_justificada.justificativa
                  .nm_justificativa
              }
            </td>
          </>
        );
      }
    }

    if (calendario.calendarios[0] && !calendario.data_justificada) {
      return (
        <>
          <td>
            {calendario.calendarios[0] ? (
              <>
                {calendario.cronograma.fl_virada
                  ? `+${moment(calendario.calendarios[0].dt_calendario)
                      .utc()

                      .format('HH:mm')}`
                  : moment(calendario.calendarios[0].dt_calendario)
                      .utc()

                      .format('HH:mm')}
              </>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[1] ? (
              <>
                {moment(calendario.calendarios[1].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[2] ? (
              <>
                {moment(calendario.calendarios[2].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </>
            ) : (
              '-'
            )}
          </td>

          <td>
            {calendario.calendarios[3] ? (
              <>
                {moment(calendario.calendarios[3].dt_calendario)
                  .utc()

                  .format('HH:mm')}
              </>
            ) : (
              '-'
            )}
          </td>
        </>
      );
    }

    return (
      <>
        <td>-</td>

        <td>-</td>

        <td>-</td>

        <td>-</td>
      </>
    );
  };

  cronogramasListItem = () => {
    const { frequencia } = this.props;

    return frequencia.dados.map(calendario => {
      return (
        <Fragment key={calendario.dataBase}>
          <tr>
            <td>{moment(calendario.dataBase).format('DD/MM')}</td>

            <td>
              {calendario.totais && calendario.totais.falta
                ? 'FALTA'
                : calendario.cronograma.cd_cronograma}
            </td>

            {this.getJustificativa(calendario)}

            <td>
              {calendario.cronograma.cd_cronograma === 'TRABALHO'
                ? calendario.cronograma.qt_hr_trabalho.substring(0, 5)
                : '-'}
            </td>

            <td>{calendario.totais ? calendario.totais.trabalhado : '-'}</td>

            <td>{calendario.totais ? calendario.totais.descontado : '-'}</td>

            <td>{calendario.totais ? calendario.totais.extra50 : '-'}</td>

            <td>{calendario.totais ? calendario.totais.extra100 : '-'}</td>

            <td>{calendario.totais ? calendario.totais.noturno : '-'}</td>

            <td>
              {calendario.totais && calendario.totais.noturnoMultiplicado
                ? calendario.totais.noturnoMultiplicado
                : '-'}
            </td>

            <td>{calendario.totais ? calendario.totais.saldoDeHoras : '-'}</td>
          </tr>
        </Fragment>
      );
    });
  };

  render() {
    const { funcionario, frequencia, mesInicial, anoInicial } = this.props;

    moment.locale('en');

    const mesOptions = [
      'Janeiro-Fevereiro',

      'Fevereiro-Março',

      'Março-Abril',

      'Abril-Maio',

      'Maio-Junho',

      'Junho-Julho',

      'Julho-Agosto',

      'Agosto-Setembro',

      'Setembro-Outubro',

      'Outubro-Novembro',

      'Novembro-Dezembro',

      'Dezembro-Janeiro',
    ];

    return (
      <Content id="fullReportDiv">
        <HeaderReport />

        <h1>Folha de Ponto</h1>

        <ul>
          <li>
            <strong>MÊS BASE</strong>
            <p>{mesOptions[mesInicial]}</p>
          </li>
          <li>
            <strong>ANO</strong>
            <p>{anoInicial}</p>
          </li>
        </ul>

        {frequencia && (
          <>
            <h1>Funcionário: {funcionario.nm_funcionario}</h1>

            <h2>Cargo: 
              {
                funcionario.alocacao_cargos[0] ? 
                  funcionario.alocacao_cargos[0].cargo_contrato.cargo ? 
                    funcionario.alocacao_cargos[0].cargo_contrato.cargo.nm_cargo : 
                  '' :
                ''
              }
            </h2>

            <h1>Pis/Pasep: {funcionario.nu_pis_pasep}</h1>

            <Table>
              <tbody>
                <tr>
                  <th>Data Base</th>

                  <th>Tipo</th>

                  <th>Entrada 01</th>

                  <th>Saida 01</th>

                  <th>Entrada 02</th>

                  <th>Saida 02</th>

                  <th>Expediente</th>

                  <th>Trabalhado</th>

                  <th>Descontado</th>

                  <th>Extras 50%</th>

                  <th>Extras 100%</th>

                  <th>Adicional Noturno</th>

                  <th>Adc. Noturno Calculado</th>

                  <th>Banco de Horas</th>
                </tr>

                {this.cronogramasListItem()}

                <tr>
                  <th />

                  <th />

                  <th />

                  <th />

                  <th />

                  <th />

                  <th>Totais:</th>

                  <th>{frequencia.totalTrabalhadoGeral}</th>

                  <th>{frequencia.totalDescontadoGeral}</th>

                  <th>{frequencia.totalExtra50Geral}</th>

                  <th>{frequencia.totalExtra100Geral}</th>

                  <th>{frequencia.totalNoturnoGeral}</th>

                  <th>{frequencia.totalNoturnoMultiplicado}</th>

                  <th>{frequencia.totalBancoDeHoraGeral}</th>
                </tr>
              </tbody>
            </Table>

            <hr />

            <h2>FUNCIONARIO</h2>

            <h2>{funcionario.nm_funcionario}</h2>

            <hr />

            <h2>RESPONSÁVEL</h2>

            <h2>
              {funcionario.superior && funcionario.superior.nm_funcionario}
            </h2>

            <footer>
              <h2>Lauer Engenharia Maquinas e Seviços Ltda</h2>

              <h2>CNPJ: 31.427.859/0002-96</h2>
            </footer>
          </>
        )}
      </Content>
    );
  }
}

ReportTable.propTypes = {
  funcionario: PropTypes.shape({
    nm_funcionario: PropTypes.string,

    nu_pis_pasep: PropTypes.string,

    superior: PropTypes.string,
  }).isRequired,

  frequencia: PropTypes.shape({
    dados: PropTypes.array,

    totalNoturnoGeral: PropTypes.string,

    totalNoturnoMultiplicado: PropTypes.string,

    totalTrabalhadoGeral: PropTypes.string,

    totalDescontadoGeral: PropTypes.string,

    totalExtra50Geral: PropTypes.string,

    totalExtra100Geral: PropTypes.string,

    totalBancoDeHoraGeral: PropTypes.string,

    diasInvalidos: PropTypes.arrayOf(PropTypes.shape({})),
  }),

  mesInicial: PropTypes.string,

  anoInicial: PropTypes.string,
};

ReportTable.defaultProps = {
  frequencia: null,

  mesInicial: null,

  anoInicial: null,
};

export default ReportTable;
