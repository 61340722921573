import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

import Loading from '../../components/Loading';
import { formatShortDate } from '../../../Utilities/Datetime';
import { ListStyled } from '../../../css/styles/list';

const alocacaoCargoListItem = props =>
  props.alocacoesCargos.map(alocacaoCargo => (
    <li key={alocacaoCargo.uuid}>
      <strong>{formatShortDate(alocacaoCargo.dt_inicio)}</strong>
      <strong>{alocacaoCargo.cargo_contrato.cargo.nm_cargo}</strong>
      <strong>{alocacaoCargo.cargo_contrato.contrato.nm_contrato}</strong>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onAlocacaoCargoDelete(alocacaoCargo.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </li>
  ));

function AlocacaoCargoList(props) {
  const { isLoading, alocacoesCargos } = props;

  return (
    <>
      {!isLoading || alocacoesCargos != null ? (
        <>
          {alocacoesCargos && alocacoesCargos.length > 0 ? (
            <ListStyled>
              <header>
                <div className="titles">
                  <strong>INÍCIO</strong>
                  <strong>CARGO</strong>
                  <strong>CONTRATO</strong>
                  <strong />
                </div>
              </header>
              {alocacaoCargoListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>SEM ALOCAÇÕES</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

AlocacaoCargoList.propTypes = {
  isLoading: PropTypes.bool,
  alocacoesCargos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      dt_inicio: PropTypes.string,
      cargo_contrato: PropTypes.shape({
        contrato: PropTypes.shape({
          nm_contrato: PropTypes.string,
        }),
        cargo: PropTypes.shape({
          nm_cargo: PropTypes.string,
        }),
      }),
    })
  ),
};

AlocacaoCargoList.defaultProps = {
  isLoading: false,
  alocacoesCargos: [],
};

export default AlocacaoCargoList;
