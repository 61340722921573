// Dependencies

import React, { useState } from 'react';

import MaskedInput from 'react-input-mask';

import PropTypes from 'prop-types';

export default function MaskInput({ name, mask, ...inputProps }) {
  // States

  const [value, setValue] = useState('');

  return (
    <MaskedInput
      value={value}
      mask={mask}
      maskChar=" "
      type="text"
      onChange={e => setValue(e.target.value)}
      {...inputProps}
    />
  );
}

// Props

MaskInput.propTypes = {
  name: PropTypes.string.isRequired,

  mask: PropTypes.string.isRequired,
};
