import React, { Component } from 'react';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import API from '../../services/api';

// Components

import RelatorioFilterForm from './filterForm';

import RelatorioList from './list';

// Styles

import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class FechamentoContainer extends Component {
  state = {
    isLoading: false,
    fechamentos: null,
    superiores: [],
    departamentos: [],
    mes: moment.utc().format('MM'),
    ano: moment.utc().format('YYYY'),
    tipo: null,
  };

  situation = {
    'A': 'Ativo',
    'D': 'Demitido',
    'E': 'Afastado'
  }

  componentWillMount() {
    this.getSuperiores();
    this.getDepartamentos();
    // this.getFechamentos();
  }

  getFechamentos = async values => {
    await this.setState(prevState => ({
      isLoading: true,
      mes: values ? values.mes : prevState.mes,
      ano: values ? values.ano : prevState.ano,
      tipo: values ? values.tipo : prevState.tipo,
    }));

    const { mes, ano, tipo } = this.state;

    const res = await API.get(
      `fichaDeFrequencias/all/${parseInt(values && values.mes, 10) + 1 ||
        mes}/${(values && values.ano) || ano}/${(values &&
        values.superiorUuid) ||
        null}/${(values && values.departamentos) || null}/${tipo || null}`
    );

    const fechamentos = res.data;

    this.setState({
      fechamentos,
      isLoading: false,
    });
  };

  getSuperiores = async () => {
    await API.get(`funcionarios/A/null/null`).then(res => {
      const result = res.data;

      const superiores =
        result &&
        result.filter(funcionario => funcionario.fl_responsavel_ponto);

      this.setState({
        superiores,
      });
    });
  };

  getDepartamentos = async () => {
    await API.get(`departamentos`).then(res => {
      const result = res.data;

      this.setState({
        departamentos: result,
      });
    });
  };

  exportToCSV = (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };


  generateExcelData = fechamentos => {
    const arrayExcel = [];

    fechamentos.forEach(fechamento => {
      const {
        funcionario,
        noturno,
        extra_50,
        extra_100,
        descontado,
        trabalhado,
        noturno_calculado,
        banco_de_horas,
        diasTrabalhados,
      } = fechamento;

      if (funcionario) {
        const NOME = funcionario.nm_funcionario;
        const SITUACAO = funcionario.situacao === 'A' ?
                          this.situation.A :
                            funcionario.situacao === 'E' ?
                            this.situation.E :
                          this.situation.D
        const TRABALHADO =
          trabalhado && trabalhado !== '00:00' ? trabalhado : '-';
        const DESCONTADO =
          descontado && descontado !== '00:00' ? descontado : '-';
        const EXTRA100 = extra_100 && extra_100 !== '00:00' ? extra_100 : '-';
        const EXTRA50 = extra_50 && extra_50 !== '00:00' ? extra_50 : '-';
        const NOTURNO = noturno && noturno !== '00:00' ? noturno : '-';
        const NOTURNO_CALCULADO =
          noturno_calculado && noturno_calculado !== '00:00'
            ? noturno_calculado
            : '-';
        const BANCO_DE_HORAS =
          banco_de_horas && banco_de_horas !== '00:00' ? banco_de_horas : '-';
        const DIAS_TRABALHADOS = diasTrabalhados || '-';

        const objeto = {
          NOME,
          SITUACAO,
          TRABALHADO,
          NOTURNO_CALCULADO,
          EXTRA50,
          EXTRA100,
          DESCONTADO,
          NOTURNO,
          BANCO_DE_HORAS,
          DIAS_TRABALHADOS,
        };

        arrayExcel.push(objeto);
      }
    });

    return arrayExcel;
  };

  generateExcelPendente = fechamentos => {
    const arrayExcel = [];

    fechamentos.forEach(funcionario => {
      if (funcionario && funcionario.nm_funcionario) {
        const NOME = funcionario.nm_funcionario;

        const objeto = {
          NOME,
        };

        arrayExcel.push(objeto);
      }
    });

    return arrayExcel;
  };

  render() {
    const {
      fechamentos,
      superiores,
      departamentos,
      isLoading,
      mes,
      ano,
      tipo,
    } = this.state;

    let nomeArquivo = '';
    let excelData = '';

    if (fechamentos && fechamentos[0]) {
      if (tipo === 'PENDENTE') {
        nomeArquivo = `LISTA DE PENDENTES ${mes}-${ano}`;
        excelData = this.generateExcelPendente(fechamentos);
      } else {
        nomeArquivo = `RELATÓRIO DE FREQUÊNCIAS FECHADAS DE ${mes}-${ano}`;
        excelData = this.generateExcelData(fechamentos);
      }
    }

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>FECHAMENTOS</h1>

            <button
              type="button"
              className="excel"
              disabled={!(fechamentos && fechamentos[0])}
              onClick={() => this.exportToCSV(excelData, nomeArquivo)}
            >
              EXCEL
            </button>
          </header>

          <Content>
            <RelatorioFilterForm
              onList={this.getFechamentos}
              superiores={superiores}
              departamentos={departamentos}
            />

            <RelatorioList
              isLoading={isLoading}
              fechamentos={fechamentos}
              tipo={tipo}
            />
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

export default FechamentoContainer;
