import React from 'react';

import { Formik } from 'formik';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import RadioGroup from '@material-ui/core/RadioGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from '@material-ui/core/Radio';

import { FormStyled } from '../../css/styles/form';

import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ nm_justificativa }) => {
  const errors = {};

  if (!nm_justificativa) errors.nm_justificativa = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.justificativa) {
    await props.onJustificativaCreation({ ...values });
  } else {
    await props.onJustificativaUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const JustificativaForm = props => {
  const { justificativa, onJustificativaDelete } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nm_justificativa: justificativa ? justificativa.nm_justificativa : '',

        fl_abono: justificativa && justificativa.fl_abono ? 'true' : 'false',

        fl_trabalhada:
          justificativa && justificativa.fl_trabalhada ? 'true' : 'false',

        fl_mostrar:
          justificativa && justificativa.fl_mostrar ? 'true' : 'false',

        fl_compensar:
          justificativa && justificativa.fl_compensar ? 'true' : 'false',

        fl_automatico:
          justificativa && justificativa.fl_automatico ? 'true' : 'false',

        fl_indevida:
          justificativa && justificativa.fl_indevida ? 'true' : 'false',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleChange,

        handleReset,

        handleBlur,

        errors,

        touched,

        dirty,

        isSubmitting,
      }) => (
        <FormStyled>
          <h1>JUSTIFICATIVA</h1>

          <div className="formData">
            <strong>NOME DA JUSTIFICATIVA</strong>

            <input
              id="nm_justificativa"
              name="nm_justificativa"
              value={values.nm_justificativa}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.nm_justificativa && touched.nm_justificativa && (
              <span>{errors.nm_justificativa}</span>
            )}
          </div>

          <div className="line">
            <div className="formData">
              <strong>ABONO</strong>

              <RadioGroup
                aria-label="fl_abono"
                name="fl_abono"
                value={values.fl_abono}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>

            <div className="formData">
              <strong>TRABALHADO</strong>

              <RadioGroup
                aria-label="fl_trabalhada"
                name="fl_trabalhada"
                value={values.fl_trabalhada}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>

            <div className="formData">
              <strong>MOSTRAR</strong>

              <RadioGroup
                aria-label="fl_mostrar"
                name="fl_mostrar"
                value={values.fl_mostrar}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>

            <div className="formData">
              <strong>BANCO DE HORA</strong>

              <RadioGroup
                aria-label="fl_compensar"
                name="fl_compensar"
                value={values.fl_compensar}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </div>

          <div className="line">
            <div className="formData">
              <strong>AUTOMATICO</strong>

              <RadioGroup
                aria-label="fl_automatico"
                name="fl_automatico"
                value={values.fl_automatico}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>

            <div className="formData">
              <strong>HORA EXTRA INDEVIDA</strong>

              <RadioGroup
                aria-label="fl_indevida"
                name="fl_indevida"
                value={values.fl_indevida}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>

          {justificativa && (
            <button
              type="button"
              onClick={() => {
                onJustificativaDelete(justificativa.uuid);
              }}
              className="delete"
              style={{ margin: '10px' }}
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

JustificativaForm.propTypes = {
  justificativa: PropTypes.shape({
    uuid: PropTypes.string,

    nm_justificativa: PropTypes.string,

    fl_abono: PropTypes.bool,

    fl_trabalhada: PropTypes.bool,

    fl_mostrar: PropTypes.bool,

    fl_compensar: PropTypes.bool,

    fl_automatico: PropTypes.bool,

    fl_indevida: PropTypes.bool,
  }),

  onJustificativaDelete: PropTypes.func.isRequired,
};

JustificativaForm.defaultProps = {
  justificativa: null,
};

export default JustificativaForm;
