import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

import Loading from '../../components/Loading';
import { ListStyled } from '../../../css/styles/list';

const cronogramasListItem = props =>
  props.cronogramas.map(cronograma => (
    <li
      key={cronograma.uuid}
      onClick={e => {
        props.onCronogramaEdit(cronograma);
      }}
    >
      <strong>{cronograma.nu_seq_cronograma}</strong>
      <strong>{cronograma.hr_inicio}</strong>
      <strong>{cronograma.qt_hr_trabalho}</strong>
      <strong>{cronograma.hr_inicio_intervalo}</strong>
      <strong>{cronograma.qt_hr_intervalo}</strong>
      <strong>{cronograma.cd_cronograma}</strong>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onCronogramaDelete(cronograma.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </li>
  ));

function CronogramaList(props) {
  const { isLoading, cronogramas } = props;

  return (
    <>
      {!isLoading ? (
        <>
          {cronogramas && cronogramas.length > 0 ? (
            <ListStyled>
              <header>
                <div className="titles">
                  <strong>SEQUENCIAL</strong>
                  <strong>HORA INÍCIO</strong>
                  <strong>TEMPO</strong>
                  <strong>HORA INTERVALO</strong>
                  <strong>TEMPO</strong>
                  <strong>TIPO</strong>
                  <strong />
                </div>
              </header>
              {cronogramasListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>LISTA VAZIA</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

CronogramaList.propTypes = {
  isLoading: PropTypes.bool,
  cronogramas: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nu_seq_cronograma: PropTypes.number,
      hr_inicio: PropTypes.string,
      qt_hr_trabalho: PropTypes.string,
      hr_inicio_intervalo: PropTypes.string,
      qt_hr_intervalo: PropTypes.string,
      cd_cronograma: PropTypes.string,
    })
  ),
};

CronogramaList.defaultProps = {
  isLoading: false,
  cronogramas: [],
};

export default CronogramaList;
