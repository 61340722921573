import styled from 'styled-components';

export const List = styled.div`
  width: 100%;

  box-sizing: border-box;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: flex-start;

  margin: 32px 0 16px 0;

  border-bottom: 1px solid #666;

  small {
    width: 100%;
    padding: 8px 0;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background: #193441;
    color: #fff;
  }

  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0 32px;

    li {
      width: 100%;

      height: 35px;

      display: flex;

      align-items: center;

      justify-content: center;

      box-sizing: border-box;

      & + li {
        margin-left: 16px;
      }

      button {
        background: none;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:disabled {
          cursor: default;
        }

        strong {
          font-size: 14px;

          color: #193441;
        }
      }
    }
  }
`;
