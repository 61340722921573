import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  background: #fff;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  padding: 32px;

  box-sizing: border-box;
`;

export const Content = styled.div`
  width: 100%;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  box-sizing: border-box;

  ul {
    width: 90%;
    padding: 0 16px;
    margin: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      border-bottom: 1px solid #333;

      & + li {
        margin-left: 32px;
      }

      strong {
        font-size: 14px;
        color: #333;
        font-weight: normal;
      }

      p {
        font-size: 18px;
        padding: 5px 15px;
      }
    }
  }

  h1 {
    font-size: 18px;
    margin: 14px 0;
  }

  h2 {
    font-size: 14px;
  }

  hr {
    width: 80%;

    margin-top: 45px;

    height: 1px;
  }

  footer {
    margin-top: 25px;

    text-align: center;

    h2 {
      margin-bottom: 4px;
    }
  }
`;

export const Table = styled.table`
  border: 1px solid #333;

  width: 90%;

  tbody {
    tr {
      font-size: 10px;

      td {
        border-bottom: 1px solid #ddd;

        border-top: 1px solid #ddd;

        text-align: center;
      }

      &:first-child {
        font-size: 10px;

        font-weight: bold;

        color: #333;

        th {
          border-bottom: 1px solid #333;
        }
      }

      &:last-child {
        font-size: 10px;

        font-weight: bold;

        color: #333;

        th {
          border-top: 1px solid #333;

          small {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 8px;
            margin: 0;
            padding: 0;
            font-weight: bold;
          }
        }
      }
    }
  }
`;
