import React, { Component, Fragment } from 'react';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import API from '../services/api';
import Parameters from '../services/parameters';

// Images
import iconTitleDash from '../img/icons/title-dash.png';

// Components
import Loading from './components/Loading';
import DragAndDrop from './components/DragAndDrop';

const socket = io(Parameters.API_PATH);

class Import extends Component {
  state = {
    isConverting: false,
    isSending: false,
    isWaiting: false,
    importType: '',
  };

  componentDidMount() {
    this.registerToSocket();
  }

  componentWillUnmount() {
    this.unregisterToSocket();
  }

  handleImportAtl = async file => {
    const name = file[0].name.split('.');
    if (name[1] === 'txt' || name[1] === 'TXT') {
      this.setState({ isConverting: true });
      const convertedFile = await this.getWorkbookFromFile(file[0]);
      this.setState({ isConverting: false });
      await this.sendImportATL(convertedFile);
    } else {
      this.notifyError('NÃO É UM ARQUIVO VÁLIDO!');
    }
  };

  sendImportATL(data) {
    this.setState({ isSending: true });
    API.post(`calendarios/import`, data, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        this.setState({ isSending: false, isWaiting: true });
        this.notifyWarn('IMPORTAÇÃO ENVIADA PARA O SERVIDOR!');
      })
      .catch(() => {
        this.setState({ isSending: false, isWaiting: false, importType: '' });
        this.notifyError('Erro no envio, favor tentar novamente!');
      });
  }

  async getWorkbookFromFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        const data = event.target.result;
        var lines = data.split('\n');
        resolve(lines);
      };
      reader.readAsBinaryString(file);
    });
  }

  registerToSocket = () => {
    console.log(Parameters.URL_API);
    socket.on('import', data => {
      this.setState({ isWaiting: false });
      this.notifySucess(
        <div>
          {'IMPORTAÇÃO CONCLUÍDA COM SUCESSO!'} <br />
          {'ITEMS IMPORTADOS: ' + data.totalAceitos} <br />
        </div>
      );
      let mensagem = data.pisPasepRejeitados.map(pis => ' | ' + pis);
      if (data.pisPasepRejeitados.length > 0) {
        this.notifyWarn(
          <div>
            {'ITENS COM PIS/PASEP REJEITADOS: ' + data.totalRejeitados} <br />
            {mensagem}
            {' | '}
          </div>
        );
      }      
    });
  };

  unregisterToSocket = () => {
    socket.removeListener('import');
  };

  notifySucess = msg => {
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  notifyWarn = msg => {
    toast.warn(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  notifyError = msg => {
    toast.error(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  render() {
    return (
      <div>
        <div className="center">
          <div className="page-header">
            <h1>
              <img src={iconTitleDash} alt="" />
              Import
            </h1>
          </div>

          <center className="box-import">
            {this.state.isConverting ? (
              <Fragment>
                <Loading />
                <h2>CONVERTENDO... </h2>
              </Fragment>
            ) : this.state.isSending ? (
              <Fragment>
                <h2>{this.state.importType}</h2>
                <Loading />
                <h2>ENVIANDO DADOS PARA O SERVIDOR...</h2>
              </Fragment>
            ) : this.state.isWaiting ? (
              <Fragment>
                <h2>{this.state.importType}</h2>
                <Loading />
                <h2>ENVIADA COM SUCESSO! AGUARDANDO RESPOSTA DO SERVIDOR!</h2>
              </Fragment>
            ) : (
              <Fragment>
                <input
                  type="file"
                  onChange={event => this.handleImportAtl(event.target.files)}
                />

                <DragAndDrop handleDrop={this.handleImportAtl}>
                  <div className="box-drop">
                    <p>Arraste o arquivo para este local...</p>
                  </div>
                </DragAndDrop>
              </Fragment>
            )}
          </center>
        </div>
      </div>
    );
  }
}

export default Import;
