import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

import Loading from '../../components/Loading';
import { formatShortDate } from '../../../Utilities/Datetime';
import { ListStyled } from '../../../css/styles/list';

const alocacaoTurnoListItem = props =>
  props.alocacoesTurnos.map(alocacaoTurno => (
    <li key={alocacaoTurno.uuid}>
      <strong>{formatShortDate(alocacaoTurno.dt_inicio)}</strong>
      <strong>{alocacaoTurno.turno && alocacaoTurno.turno.nm_turno}</strong>
      <strong>
        {alocacaoTurno.turno && alocacaoTurno.turno.contrato.nm_contrato}
      </strong>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onAlocacaoTurnoDelete(alocacaoTurno.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </li>
  ));

function AlocacaoTurnoList(props) {
  const { isLoading, alocacoesTurnos } = props;
  return (
    <>
      {!isLoading || alocacoesTurnos != null ? (
        <>
          {alocacoesTurnos && alocacoesTurnos.length > 0 ? (
            <ListStyled>
              <header>
                <div className="titles">
                  <strong>INÍCIO</strong>
                  <strong>TURNO</strong>
                  <strong>CONTRATO</strong>
                  <strong />
                </div>
              </header>
              {alocacaoTurnoListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>SEM ALOCAÇÕES</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

AlocacaoTurnoList.propTypes = {
  isLoading: PropTypes.bool,
  alocacoesTurnos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      dt_inicio: PropTypes.string,
      turno: PropTypes.shape({
        nm_turno: PropTypes.string,
        contrato: PropTypes.shape({
          nm_contrato: PropTypes.string,
        }),
      }),
    })
  ),
};

AlocacaoTurnoList.defaultProps = {
  isLoading: false,
  alocacoesTurnos: [],
};

export default AlocacaoTurnoList;
