import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FormStyled } from '../../../css/styles/form';
import imgloading from '../../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ dt_inicio, cargoContratoUuid }) => {
  const errors = {};

  if (!cargoContratoUuid) errors.cargoContratoUuid = 'Obrigatório!';

  if (!dt_inicio) errors.dt_inicio = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onAlocacaoCargoCreation({ ...values });

  setSubmitting(false);

  resetForm();
};

const AlocacaoCargoForm = props => {
  const { alocacaoCargo, cargoContratos } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        dt_inicio: alocacaoCargo ? alocacaoCargo.dt_inicio : '',

        cargoContratoUuid: alocacaoCargo ? alocacaoCargo.cargoContratoUuid : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        dirty,
        errors,
        touched,
        isSubmitting,
      }) => (
        <FormStyled>
          <h1>NOVO CARGO</h1>
          <div className="formData">
            <strong>CARGO</strong>
            <select
              id="cargoContratoUuid"
              name="cargoContratoUuid"
              value={values.cargoContratoUuid}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <>
                <option value="" />
                {cargoContratos &&
                  cargoContratos.length > 0 &&
                  cargoContratos.map(object => (
                    <option key={object.uuid} value={object.uuid}>
                      {`${object.cargo.nm_cargo} - ${object.contrato.nm_contrato} `}
                    </option>
                  ))}
              </>
            </select>

            {errors.cargoContratoUuid && touched.cargoContratoUuid && (
              <span>{errors.cargoContratoUuid}</span>
            )}
          </div>
          <div className="formData">
            <strong>DATA INÍCIO</strong>
            <input
              id="dt_inicio"
              name="dt_inicio"
              type="date"
              value={values.dt_inicio}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.dt_inicio && touched.dt_inicio && (
              <span>{errors.dt_inicio}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

AlocacaoCargoForm.propTypes = {
  alocacaoCargo: PropTypes.shape({
    dt_inicio: PropTypes.string,
    cargoContratoUuid: PropTypes.string,
  }),
  cargoContratos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      cargo: PropTypes.shape({
        nm_cargo: PropTypes.string,
      }),
      contrato: PropTypes.shape({
        nm_contrato: PropTypes.string,
      }),
    })
  ),
};

AlocacaoCargoForm.defaultProps = {
  alocacaoCargo: null,
  cargoContratos: [],
};

export default AlocacaoCargoForm;
