import styled from 'styled-components';
import Modal from 'react-modal';

export const ModalStyled = styled(Modal)`
  width: 100%;

  height: 100%;

  padding: 32px;

  background: rgba(0, 0, 0, 0.35);

  display: flex;

  align-items: center;

  justify-content: center;
`;

export const ModalContent = styled.div`
  width: 100%;

  max-width: 850px;

  height: 100%;

  max-height: 450px;

  @media (max-width: 900px) {
    max-height: 450px;

    margin-bottom: 60px;
  }

  padding: 32px;

  overflow-y: auto;

  background: #eee;

  border-radius: 4px;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: flex-start;
`;

export const ModalHeader = styled.header`
  width: 100%;

  padding: 0 64px;

  margin-bottom: 32px;

  display: flex;

  align-items: center;

  justify-content: space-between;

  h1 {
    color: #333;

    font-size: 20px;

    font-weight: bold;
  }

  button {
    width: 25px;

    height: 25px;

    padding: 0;

    margin: 0;

    background: none;

    &.new {
      width: 150px;

      height: 40px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 16px;

      font-weight: bold;

      color: #fff;
    }
  }
`;
