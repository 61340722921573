import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import NewWindow from 'react-new-window';
import swal from '@sweetalert/with-react';

import API from '../../services/api';
import Loading from '../components/Loading';
import createAuditoria from '../../services/auditoria';

// Components

import AuditoriaFilterForm from './filterForm';
import AuditoriaList from './list';

// Styles
import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class AuditoriaContainer extends Component {
  state = {
    modalAddJust: false,
    isLoading: true,
    auditorias: null,    
    auditoria: null,    
    isLoadingAuditoria: false,
    isLoadingFrequency: false,    
    dt_inicial: moment().subtract(5, 'days'),
    dt_final: moment().add(1,'days')    
  };

  async componentDidMount() {
    const { match } = this.props;
    this.mount();
  }

  mount() {
    this.getAuditorias();
    this.getAreas();
    this.getCategorias();
  }

  async getAreas() {
    API.get(`auditorias/areas`).then(res => {
      const result = res.data;

      this.setState({
        areas: result,
      });
    });
  }

  async getCategorias() {
    API.get(`auditorias/categorias`).then(res => {
      const result = res.data;

      this.setState({
        categorias: result,
      });
    });
  }


  getAuditorias = async values => {
    await this.setState(prevState => ({
      isLoading: true,
      dt_inicial: values ? values.dt_inicial : prevState.dt_inicial,
      dt_final: values ? values.dt_final : prevState.dt_final,
      nm_area: values ? values.nm_area : prevState.nm_area,
      nm_categoria: values ? values.nm_categoria : prevState.nm_categoria,
      tx_detalhe: values ? values.tx_detalhe : prevState.tx_detalhe,
    }));

    const { dt_inicial, dt_final,nm_area, nm_categoria, tx_detalhe } = this.state;    
    await API.get(
      `auditorias/`,
      {
        params: {
          dt_inicial: moment(dt_inicial),
          dt_final: dt_final ? moment(dt_final) : null,
          nm_area : nm_area,
          nm_categoria : nm_categoria,
          tx_detalhe: tx_detalhe
        }
      }
    ).then(res => {
      const result = res.data;
      this.setState({
        auditorias: result,
        isLoading: false,
      });
    });
  };


  render() {
    const {
      isLoadingAuditoria,
      auditorias,
      nm_auditoria,      
      dt_inicial,
      dt_final,
      areas,
      categorias,
      isLoading,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>AUDITORIA</h1>
          </header>
          <Content>
            {!isLoadingAuditoria ? (
              <>
                <AuditoriaFilterForm
                  onList={this.getAuditorias}
                  nm_auditoria={nm_auditoria}
                  areas={areas}
                  categorias={categorias}
                  dt_inicial={dt_inicial}
                  dt_final={dt_final}
                />
                <AuditoriaList
                  isLoading={isLoading}
                  auditorias={auditorias}
                />
              </>
            ) : (
                <Loading />
              )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

AuditoriaContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default AuditoriaContainer;
