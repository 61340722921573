import React, { useRef } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import ReactSelect from 'react-select';

import SelectStyled from '../components/ReactSelect/styles';

import { FormStyled } from '../../css/styles/form';

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList({ ...values });

  setSubmitting(false);
};

const RelatorioFilterForm = props => {
  const {
    superiorUuid,
    superiores,
    nm_funcionario,
    turnos,
    turnoUuid,
    tipoPonto,
    begin,
    end,
  } = props;

  const formRef = useRef();
  const supRef = useRef();
  const turnoRef = useRef();

  const clearFields = () => {
    if (formRef) {
      supRef.current.select.clearValue();
      turnoRef.current.select.clearValue();
      formRef.current.resetForm();
    }
  };

  return (
    <Formik
      ref={formRef}
      enableReinitialize
      initialValues={{
        nm_funcionario: '',

        superiorUuid: '',

        turnoUuid: '',

        tipoPonto: '',

        begin,

        end,
      }}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>FILTROS</h1>

          <div className="line">
            <div className="formData">
              <strong>DATA INICIAL</strong>
              <input
                id="begin"
                name="begin"
                type="date"
                value={values.begin}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="formData">
              <strong>DATA FINAL</strong>
              <input
                id="end"
                name="end"
                type="date"
                value={values.end}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <div className="formData">
            <strong>NOME DO FUNCIONÁRIO</strong>

            <input
              placeholder="Digite o nome do funcionário..."
              id="nm_funcionario"
              name="nm_funcionario"
              value={values.nm_funcionario}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="formData">
            <strong>SUPERVISOR</strong>

            <ReactSelect
              id="superiorUuid"
              name="superiorUuid"
              ref={supRef}
              isMulti
              isSearchable
              isClearable={false}
              placeholder="Selecione um supervisor"
              styles={SelectStyled}
              className="basic-multi-select"
              classNamePrefix="select"
              value={values.superiorUuid.uuid}
              onChange={selectedDpt => {
                const opt = [];
                if (selectedDpt !== null) {
                  // eslint-disable-next-line array-callback-return
                  selectedDpt.map(item => {
                    opt.push(item.uuid);
                  });
                  setFieldValue('superiorUuid', opt);
                  if (values.superiorUuid && values.superiorUuid[0] === null) {
                    setFieldValue('superiorUuid', '');
                  }
                }
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={superiores}
              getOptionValue={item => item.uuid}
              getOptionLabel={item => item.nm_funcionario}
              noOptionsMessage={() => 'Não há itens para selecionar!'}
            />
          </div>

          <div className="formData">
            <strong>TURNO</strong>

            <ReactSelect
              id="turnoUuid"
              name="turnoUuid"
              ref={turnoRef}
              isMulti
              isSearchable
              isClearable={false}
              placeholder="Selecione um turno"
              styles={SelectStyled}
              className="basic-multi-select"
              classNamePrefix="select"
              value={values.turnoUuid.uuid}
              onChange={selectedDpt => {
                const opt = [];
                if (selectedDpt !== null) {
                  // eslint-disable-next-line array-callback-return
                  selectedDpt.map(item => {
                    opt.push(item.uuid);
                  });
                  setFieldValue('turnoUuid', opt);
                  if (values.turnoUuid && values.turnoUuid[0] === null) {
                    setFieldValue('turnoUuid', '');
                  }
                }
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={turnos}
              getOptionValue={item => item.uuid}
              getOptionLabel={item => item.nm_turno}
              noOptionsMessage={() => 'Não há itens para selecionar!'}
            />
          </div>

          <div className="formData">
            <strong>TIPO DE PONTO</strong>
            <select
              id="tipoPonto"
              name="tipoPonto"
              value={values.tipoPonto}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" />
              <option value="REGULAR">REGULAR</option>
              <option value="DESCONTADO">DESCONTADO</option>
              <option value="IRREGULAR">IRREGULAR</option>
              <option value="HORAS EXTRA">HORAS EXTRA</option>
              <option value="IRREGULAR + DESCONTADO">
                IRREGULAR + DESCONTADO
              </option>
              {/* <option value="DESCONTADO + JUSTIFICADO">
                DESCONTADO + JUSTIFICADO
              </option> */}
            </select>
          </div>

          <button type="submit" disabled={isSubmitting}>
            BUSCAR
          </button>

          <button
            type="button"
            disabled={isSubmitting || !dirty}
            onClick={clearFields}
          >
            LIMPAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

RelatorioFilterForm.propTypes = {
  superiores: PropTypes.arrayOf(
    PropTypes.shape({
      fl_responsavel_ponto: PropTypes.bool,
      uuid: PropTypes.string,
      nm_funcionario: PropTypes.string,
    })
  ),
  turnos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_turno: PropTypes.string,
    })
  ),
  nm_funcionario: PropTypes.string,
  superiorUuid: PropTypes.string,
  turnoUuid: PropTypes.string,
  tipoPonto: PropTypes.string,
  begin: PropTypes.string,
  end: PropTypes.string,
};

RelatorioFilterForm.defaultProps = {
  superiores: [],
  turnos: [],
  nm_funcionario: null,
  superiorUuid: null,
  turnoUuid: null,
  tipoPonto: null,
  begin: null,
  end: null,
};

export default RelatorioFilterForm;
