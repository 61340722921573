import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';

import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';

import Loading from '../components/Loading';

class JustificativaList extends Component {
  JustificativaListItem = justificativas =>
    justificativas.map(justificativa => (
      <li key={justificativa.uuid}>
        <Link
          to={`/justificativas/${justificativa.uuid}`}
          key={justificativa.uuid}
        >
          <strong>
            {moment(justificativa.createdAT).format('DD/MM/YYYY')}
          </strong>
          <strong>{justificativa.nm_justificativa}</strong>
          <strong>{justificativa.fl_abono ? ' SIM' : 'NÃO'}</strong>
          <strong>{justificativa.fl_compensar ? ' SIM' : 'NÃO'}</strong>
          <strong>{justificativa.fl_mostrar ? ' SIM' : 'NÃO'}</strong>
          <strong>{justificativa.fl_automatico ? ' SIM' : 'NÃO'}</strong>
          <strong>{justificativa.fl_indevida ? ' SIM' : 'NÃO'}</strong>
        </Link>
      </li>
    ));

  render() {
    const { isLoading, justificativas } = this.props;

    return (
      <>
        {!isLoading ? (
          <>
            {justificativas && (
              <>
                {justificativas.length > 0 ? (
                  <ListStyled>
                    <header>
                      <div className="total">
                        <h1>TOTAL: </h1>

                        <p>{justificativas.length}</p>
                      </div>

                      <div className="titles">
                        <strong className="name">DATA</strong>

                        <strong>DESCRIÇÃO</strong>

                        <strong>ABONO</strong>

                        <strong>B. DE HORAS</strong>

                        <strong>MOSTRAR</strong>

                        <strong>AUTOMATICO</strong>

                        <strong>H.E. INDEVIDA</strong>
                      </div>
                    </header>

                    {this.JustificativaListItem(justificativas)}
                  </ListStyled>
                ) : (
                  <ListStyled>
                    <strong>LISTA VAZIA</strong>
                  </ListStyled>
                )}
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

JustificativaList.propTypes = {
  isLoading: PropTypes.bool,

  justificativas: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      createdAT: PropTypes.string,

      nm_justificativa: PropTypes.string,

      fl_abono: PropTypes.bool,

      fl_compensar: PropTypes.bool,
    })
  ),
};

JustificativaList.defaultProps = {
  justificativas: [],

  isLoading: false,
};

export default JustificativaList;
