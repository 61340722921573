const ReactSelectStyle = {
  control: base => ({
    ...base,
    height: '60px',
    maxHeight: '100px',
    minHeight: '40px',
    border: '0px',
    borderRadius: '0px',
    borderBottom: '1px solid #333',
    marginTop: '10px',
    fontSize: '16px',
    color: '#333',
    padding: 'auto 15px',
    backgroundColor: '#f8f8f8',
  }),

  container: provided => ({
    ...provided,
    maxHeight: '100px',
    height: '60px',
    width: '100%',
  }),

  valueContainer: provided => ({
    ...provided,
    flexDirection: 'row',
    height: '100%',
    backgroundColor: '#fff',
  }),

  input: provided => ({
    ...provided,
    maxHeight: '100px',
  }),

  placeholder: provided => ({
    ...provided,
    color: '#999',
  }),

  option: (provided, state) => ({
    ...provided,
    background: '#fff',
    color: state.isSelected ? '#333' : '#999',
    maxHeight: '100px',
    fontSize: '14px',
    padding: '10px',
  }),

  multiValueRemove: base => ({
    ...base,
    display: 'none',
  }),
};

export default ReactSelectStyle;
