import axios from 'axios';

import parameters from './parameters';

const userUuid = localStorage.getItem('USER_UUID');

export default axios.create({
  baseURL: parameters.API_PATH,
  headers: {
    userUuid,
  },
  // baseURL: 'https://companyfy.herokuapp.com',
});
