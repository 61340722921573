import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../services/api';
import Loading from '../components/Loading';
import createAuditoria from '../../services/auditoria';

// Components

import TurnoForm from './form';
import TurnoList from './list';
import CronogramaContainer from './Cronograma';

// Styles

import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class TurnoContainer extends Component {
  state = {
    modalAddJust: false,
    isLoading: true,
    turnos: null,
    turno: null,
    isLoadingTurno: false,
    contratos: null,
  };

  async componentDidMount() {
    createAuditoria('TURNO',localStorage.getItem('USER_UUID'));
    const { match } = this.props;

    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getTurno(uuid);
    }
    this.getTurnos();

    this.getContratos();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  async getTurnos() {
    this.setState({
      isLoading: true,
    });

    API.get(`turnos`).then(res => {
      const turnos = res.data;

      this.setState({
        turnos,

        isLoading: false,
      });
    });
  }

  getTurno = async uuid => {
    this.setState({
      isLoadingTurno: true,

      modalAddJust: true,
    });

    API.get(`turnos/${uuid}`).then(res => {
      const turno = res.data[0];

      this.setState({
        turno,

        isLoadingTurno: false,
      });
    });
  };

  handleTurnoDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Turno?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`turnos/${uuid}`).then(() => {
        this.getTurnos();

        this.setState({
          turno: null,

          modalAddJust: false,
        });
      });
    }
  };

  handleTurnoCreation = async values => {
    try {
      const rawResponse = await API.post(
        'turnos',

        {
          contratoUuid: values.contratoUuid,

          nm_turno: values.nm_turno,

          dt_inicio_vigencia: `${values.dt_inicio_vigencia}T00:00:00`,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      const content = await rawResponse.data;

      this.getTurnos();

      this.getTurno(content.uuid);
    } catch (err) {
      alert(err);
    }
  };

  handleTurnoUpdate = async values => {
    const { turno } = this.state;

    try {
      await API.put(
        `turnos/${turno.uuid}`,

        {
          contratoUuid: values.contratoUuid,

          nm_turno: values.nm_turno,

          dt_inicio_vigencia: `${values.dt_inicio_vigencia}T00:00:00`,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getTurnos();

      this.setState({ modalAddJust: false, turno: null });
    } catch (err) {
      alert(err);
    }
  };

  async getContratos() {
    API.get(`contratos`).then(res => {
      const result = res.data;

      this.setState({
        contratos: result,
      });
    });
  }

  render() {
    const {
      modalAddJust,
      isLoadingTurno,
      turno,
      isLoading,
      turnos,
      contratos,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>TURNOS</h1>
            <Link
              to="/turnos"
              onClick={() =>
                this.setState(prevState => ({
                  modalAddJust: !prevState.modalAddJust,
                  turno: null,
                }))
              }
            >
              {!modalAddJust ? 'ADICIONAR' : 'LISTA'}
            </Link>
          </header>

          <Content>
            {modalAddJust ? (
              <>
                {!isLoadingTurno ? (
                  <Fragment>
                    <TurnoForm
                      onTurnoCreation={this.handleTurnoCreation}
                      onTurnoUpdate={this.handleTurnoUpdate}
                      isLoadingTurno={isLoadingTurno}
                      onTurnoDelete={this.handleTurnoDelete}
                      turno={turno}
                      contratos={contratos}
                    />

                    {turno && <CronogramaContainer turno={turno} />}
                  </Fragment>
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <TurnoList
                isLoading={isLoading}
                onTurnoDelete={this.handleTurnoDelete}
                onTurnoGet={this.getTurno}
                turnos={turnos}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

TurnoContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default TurnoContainer;
