import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { FormStyled } from '../../css/styles/form';
import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({
  nm_funcionario,
  cd_funcionario,
  nu_cpf,
  dt_admissao,
  dt_exclusao,
  departamentoUuid,
  end_funcionario,
  cep_funcionario,
  uf_funcionario,
  situacao,
  nu_pis_pasep,
}) => {
  const errors = {};

  if (!nm_funcionario) errors.nm_funcionario = 'Obrigatório!';
  if (!cd_funcionario) errors.cd_funcionario = 'Obrigatório!';
  if (!nu_cpf) errors.nu_cpf = 'Obrigatório!';
  if (!nu_pis_pasep) errors.nu_pis_pasep = 'Obrigatório!';
  if (!dt_admissao) errors.dt_admissao = 'Obrigatório!';
  if (!departamentoUuid) errors.departamentoUuid = 'Obrigatório!';
  if (!end_funcionario) errors.end_funcionario = 'Obrigatório!';
  if (!cep_funcionario) errors.cep_funcionario = 'Obrigatório!';
  if (!uf_funcionario) errors.uf_funcionario = 'Obrigatório!';
  if (situacao !== 'A' && !dt_exclusao)
    errors.dt_exclusao = 'Obrigatório para a situação!';
  if (situacao === 'A' && dt_exclusao)
    errors.dt_exclusao = 'Não pode estar preenchida!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {

  

  const funcionarioData = {
    ...values,  
    nm_funcionario: values.nm_funcionario.trim(),
    cd_funcionario: values.cd_funcionario.trim(),
    cd_externo_funcionario: values.cd_externo_funcionario.trim(),
    nu_pis_pasep: values.nu_pis_pasep.trim()
  }

  console.log(funcionarioData)

  if (!props.funcionario) {
    await props.onFuncionarioCreation(funcionarioData);
  } else {
    await props.onFuncionarioUpdate(funcionarioData);
  }

  setSubmitting(false);

  resetForm();
};

const FuncionarioForm = props => {
  const {
    funcionario,
    onFuncionarioDelete,
    departamentos,
    onShowFrequency,
    funcionarios,
  } = props;

  const funcionariosResponsaveis =
    funcionarios &&
    funcionarios.filter(
      funcionarioAtivo =>
        funcionarioAtivo.fl_responsavel_ponto &&
        (!funcionario || funcionarioAtivo.uuid !== funcionario.uuid)
    );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nm_funcionario: funcionario ? funcionario.nm_funcionario : '',
        cd_funcionario: funcionario ? funcionario.cd_funcionario : '',
        cd_externo_funcionario: funcionario
          ? funcionario.cd_externo_funcionario
          : '',
        nu_cpf: funcionario ? funcionario.nu_cpf : '',
        nu_pis_pasep: funcionario ? funcionario.nu_pis_pasep : '',
        dt_admissao: funcionario
          ? funcionario.dt_admissao.substring(0, 10)
          : '',
        departamentoUuid: funcionario ? funcionario.departamentoUuid : '',
        uf_funcionario: funcionario ? funcionario.uf_funcionario : '',
        cep_funcionario: funcionario ? funcionario.cep_funcionario : '',
        end_funcionario: funcionario ? funcionario.end_funcionario : '',
        situacao: funcionario ? funcionario.situacao : 'A',
        dt_exclusao:
          funcionario && funcionario.dt_exclusao
            ? funcionario.dt_exclusao.substring(0, 10)
            : '',
        // funcionario && !!(funcionario.fl_responsavel_ponto)
        fl_responsavel_ponto:
          funcionario && funcionario.fl_responsavel_ponto ? 'true' : 'false',
        funcionarioUuid: funcionario ? funcionario.funcionarioUuid : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
      }) => (
        <FormStyled>
          <h1>FUNCIONÁRIO</h1>
          <div className="formData">
            <strong>NOME</strong>
            <input
              id="nm_funcionario"
              name="nm_funcionario"
              value={values.nm_funcionario}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.nm_funcionario && touched.nm_funcionario && (
              <span>{errors.nm_funcionario}</span>
            )}
          </div>
          <div className="formData">
            <strong>MATRÍCULA</strong>

            <input
              id="cd_funcionario"
              name="cd_funcionario"
              value={values.cd_funcionario}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.cd_funcionario && touched.cd_funcionario && (
              <span>{errors.cd_funcionario}</span>
            )}
          </div>
          <div className="formData">
            <strong>MATR. EXTERNO</strong>

            <input
              id="cd_externo_funcionario"
              name="cd_externo_funcionario"
              value={values.cd_externo_funcionario || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.cd_externo_funcionario &&
              touched.cd_externo_funcionario && (
                <span>{errors.cd_externo_funcionario}</span>
              )}
          </div>
          <div className="formData">
            <strong>CPF</strong>
            <input
              id="nu_cpf"
              name="nu_cpf"
              value={values.nu_cpf}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.nu_cpf && touched.nu_cpf && <span>{errors.nu_cpf}</span>}
          </div>
          <div className="formData">
            <strong>NÚMERO PIS/PASEP</strong>
            <input
              id="nu_pis_pasep"
              name="nu_pis_pasep"
              value={values.nu_pis_pasep}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.nu_pis_pasep && touched.nu_pis_pasep && (
              <span>{errors.nu_pis_pasep}</span>
            )}
          </div>
          <div className="formData">
            <strong>DATA ADMISSÃO</strong>
            <input
              id="dt_admissao"
              name="dt_admissao"
              type="date"
              value={values.dt_admissao}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.dt_admissao && touched.dt_admissao && (
              <span>{errors.dt_admissao}</span>
            )}
          </div>
          <div className="formData">
            <strong>DEPARTAMENTO</strong>
            <select
              value={values.departamentoUuid}
              onChange={handleChange}
              onBlur={handleBlur}
              name="departamentoUuid"
              id="departamentoUuid"
            >
              {
                <>
                  <option value="">SELECIONE O DEPARTAMENTO</option>

                  {departamentos &&
                    departamentos.map(departamento => (
                      <option key={departamento.uuid} value={departamento.uuid}>
                        {departamento.nm_departamento}
                      </option>
                    ))}
                </>
              }
            </select>
            {errors.departamentoUuid && touched.departamentoUuid && (
              <span>{errors.departamentoUuid}</span>
            )}
          </div>
          <div className="formData">
            <strong>ENDEREÇO</strong>
            <input
              id="end_funcionario"
              name="end_funcionario"
              value={values.end_funcionario}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.end_funcionario && touched.end_funcionario && (
              <span>{errors.end_funcionario}</span>
            )}
          </div>
          <div className="formData">
            <strong>CEP</strong>
            <input
              id="cep_funcionario"
              name="cep_funcionario"
              value={values.cep_funcionario}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.cep_funcionario && touched.cep_funcionario && (
              <span>{errors.cep_funcionario}</span>
            )}
          </div>
          <div className="formData">
            <strong>UF</strong>
            <input
              id="uf_funcionario"
              name="uf_funcionario"
              value={values.uf_funcionario}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.uf_funcionario && touched.uf_funcionario && (
              <span>{errors.uf_funcionario}</span>
            )}
          </div>
          <div className="formData">
            <strong>SITUAÇÃO</strong>
            <select
              value={values.situacao}
              onChange={handleChange}
              onBlur={handleBlur}
              name="situacao"
              id="situacao"
            >
              {
                <>
                  <option value="A">ATIVO</option>

                  <option value="D">DEMITIDO</option>

                  <option value="E">AFASTADO</option>
                </>
              }
            </select>

            {errors.departamentoUuid && touched.departamentoUuid && (
              <span>{errors.departamentoUuid}</span>
            )}
          </div>
          <div className="formData">
            <strong>DATA AFASTAMENTO</strong>
            <input
              id="dt_exclusao"
              name="dt_exclusao"
              type="date"
              value={values.dt_exclusao}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.dt_exclusao && touched.dt_exclusao && (
              <span>{errors.dt_exclusao}</span>
            )}
          </div>
          <div className="formData">
            <strong>SUPERIOR</strong>
            <select
              value={values.funcionarioUuid}
              onChange={handleChange}
              onBlur={handleBlur}
              name="funcionarioUuid"
              id="funcionarioUuid"
            >
              {
                <>
                  <option value="">SELECIONE O SUPERIOR</option>

                  {funcionariosResponsaveis &&
                    funcionariosResponsaveis.map(funcionarioResponsavel =>
                      funcionarioResponsavel.fl_responsavel_ponto ? (
                        <option
                          key={funcionarioResponsavel.uuid}
                          value={funcionarioResponsavel.uuid}
                        >
                          {funcionarioResponsavel.nm_funcionario}
                        </option>
                      ) : (
                        ''
                      )
                    )}
                </>
              }
            </select>
            {errors.funcionarioUuid && touched.funcionarioUuid && (
              <span>{errors.funcionarioUuid}</span>
            )}
          </div>
          <div className="formData">
            <strong>RESPONSÁVEL PONTO</strong>
            <RadioGroup
              aria-label="fl_responsavel_ponto"
              name="fl_responsavel_ponto"
              value={values.fl_responsavel_ponto}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Sim"
                labelPlacement="end"
              />

              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Não"
                labelPlacement="end"
              />
            </RadioGroup>
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>
          {funcionario && (
            <button
              type="button"
              onClick={() => {
                onFuncionarioDelete(funcionario.uuid);
              }}
              className="delete"
            >
              EXCLUIR
            </button>
          )}

          {funcionario && (
            <button
              type="button"
              onClick={() => {
                onShowFrequency();
              }}
            >
              FICHA DE FREQUÊNCIA
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

FuncionarioForm.propTypes = {
  funcionario: PropTypes.shape({
    uuid: PropTypes.string,
    nm_funcionario: PropTypes.string,
    cd_funcionario: PropTypes.string,
    cd_externo_funcionario: PropTypes.string,
    nu_cpf: PropTypes.string,
    nu_pis_pasep: PropTypes.string,
    dt_admissao: PropTypes.string,
    departamentoUuid: PropTypes.string,
    uf_funcionario: PropTypes.string,
    cep_funcionario: PropTypes.string,
    end_funcionario: PropTypes.string,
    situacao: PropTypes.string,
    dt_exclusao: PropTypes.string,
    funcionarioUuid: PropTypes.string,
    fl_responsavel_ponto: PropTypes.bool,
  }),
  funcionarios: PropTypes.arrayOf(
    PropTypes.shape({
      fl_responsavel_ponto: PropTypes.bool,
    })
  ),
  onFuncionarioDelete: PropTypes.func.isRequired,
  departamentos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_departamento: PropTypes.string,
    })
  ),
  onShowFrequency: PropTypes.func.isRequired,
};

FuncionarioForm.defaultProps = {
  funcionario: null,
  departamentos: null,
  funcionarios: [],
};

export default FuncionarioForm;
