import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';
import Loading from '../components/Loading';
import * as DateTimeUIUtil from '../../Utilities/Datetime';

const funcionariosListItem = props =>
  props.funcionarios.map(funcionario => (
    <li key={funcionario.uuid}>
      <Link to={`/funcionarios/${funcionario.uuid}`} key={funcionario.uuid}>
        <strong>{funcionario.cd_funcionario} </strong>
        <strong>{funcionario.nm_funcionario}</strong>
        {funcionario.alocacao_cargos && funcionario.alocacao_cargos[0] ? (
          <strong>
            {funcionario.alocacao_cargos[0].cargo_contrato ? (
              <>
                {funcionario.alocacao_cargos[0].cargo_contrato.cargo
                  ? funcionario.alocacao_cargos[0].cargo_contrato.cargo.nm_cargo
                  : ''}
              </>
            ) : (
              ''
            )}
          </strong>
        ) : (
          <strong />
        )}
        {funcionario.alocacao_turnos && funcionario.alocacao_turnos[0] ? (
          <strong>
            {funcionario.alocacao_turnos[0].turno
              ? funcionario.alocacao_turnos[0].turno.nm_turno
              : ''}
          </strong>
        ) : (
          <strong />
        )}
        <strong>
          {DateTimeUIUtil.formatShortDate(funcionario.dt_admissao)}
        </strong>
      </Link>
    </li>
  ));

function FuncionarioList(props) {
  const { isLoading, funcionarios } = props;

  return (
    <>
      {!isLoading ? (
        <>
          {funcionarios.length > 0 ? (
            <ListStyled>
              <header>
                <div className="total">
                  <h1>TOTAL: </h1>
                  <p>{funcionarios.length}</p>
                </div>

                <div className="titles">
                  <strong>MAT</strong>
                  <strong>NOME</strong>
                  <strong>CARGO</strong>
                  <strong>TURNO</strong>
                  <strong>ADMISSÃO</strong>
                </div>
              </header>
              {funcionariosListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>LISTA VAZIA</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

FuncionarioList.propTypes = {
  isLoading: PropTypes.bool,
  funcionarios: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_funcionario: PropTypes.string,
      cd_funcionario: PropTypes.string,
      dt_admissao: PropTypes.string,
    })
  ),
};

FuncionarioList.defaultProps = {
  funcionarios: [],
  isLoading: false,
};
export default FuncionarioList;
