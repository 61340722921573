import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ListStyled = styled.ul`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 16px 0;

  strong {
    font-size: 14px;
    color: #193441;
  }

  header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    div.titles {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      strong {
        font-size: 14px;
        color: #193441;

        &.name {
          width: 200px;
        }
      }
    }

    div.total {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 16px;

      h1 {
        font-size: 20px;
        color: #193441;
        margin-right: 8px;
      }

      p {
        color: #333;
        font-size: 20px;
      }
    }
  }

  li {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #fff;
    border: 2px solid #193441;
    border-radius: 10px;
    padding: 0 32px;
    box-sizing: border-box;

    margin: 16px 0;

    strong {
      font-size: 14px;
      color: #193441;
      &.name {
        width: 200px;
      }
    }

    button {
      background: none;
      border: 0;

      border-left: 1px solid #ddd;
      padding-left: 16px;
      margin-left: 16px;

      &:disabled {
        cursor: default;
      }
    }

    &:hover {
      background: #193441;
      border: #193441;

      strong {
        color: #fff;
      }
    }

    &.irregular {
      background: #d92323;
      border: #d92323;
      strong {
        color: #fff;
      }

      &:hover {
        background: #d92323;
        border: #d92323;

        strong {
          color: #fff;
        }
      }
    }

    &.descontado {
      border: 2px solid #d92323;
      strong {
        color: #d92323;
      }

      &:hover {
        background: #d92323;
        border: #d92323;

        strong {
          color: #fff;
        }
      }
    }

    a {
      cursor: pointer;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      text-decoration: none;
    }
  }
`;

export const SubListStyled = styled.div`
  width: 100%;
  min-width: 100%;

  display: ${props => (props.visible ? 'grid' : 'none')};
  flex: 1;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;

  margin: 16px 0;
  padding-bottom: 32px;
  border-bottom: 2px solid #193441;

  strong {
    font-size: 14px;
    color: #193441;
    padding: 8px 0 8px 8px;
    border-left: 1px solid #666;
  }
`;

export const ListGridStyled = styled.ul`
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  margin: 32px 0;

  header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    div.total {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h1 {
        font-size: 20px;
        color: #011826;
        margin-right: 8px;
      }

      p {
        color: #333;
        font-size: 20px;
      }
    }

    div.titles {
      width: 100%;
      display: grid;
      grid-template-columns: ${props => props.gridheader};
      column-gap: 10px;
      align-items: center;

      text-align: center;

      strong {
        font-size: 14px;
        color: #011826;

        &.name {
          width: 100%;
        }
      }
    }
  }
`;

export const ListGridLink = styled(Link)`
  width: 100%;
  height: 50px;

  display: grid;
  grid-template-columns: ${props => props.gridlist};
  column-gap: 10px;
  align-items: center;

  padding: 0 32px;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;

  background: ${props => props.color};
  border: 2px solid #ddd;
  cursor: pointer;

  & + a {
    margin-top: 16px;
  }

  &:hover {
    background: #fff;
    border: 2px solid #333;

    strong {
      color: #333;
    }
  }

  strong {
    font-size: 14px;
    color: ${props => props.strongcolor};
  }

  button {
    background: none;
    border: 0;

    border-left: 1px solid #ddd;
    padding-left: 16px;
    margin-left: 16px;

    &:disabled {
      cursor: default;
    }
  }
`;
