/* eslint-disable no-nested-ternary */

import React from 'react';

import { Formik } from 'formik';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import imgloading from '../../../img/load.gif';

import { FormStyled } from '../../../css/styles/form';

import MaskInput from '../../components/MaskInput';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({
  opening_date,

  ending_date,

  time,

  diaTodo,

  justificativaUuid,
}) => {
  const errors = {};

  if (!opening_date) errors.opening_date = 'Obrigatório!';

  if (!time && !diaTodo && !ending_date) errors.time = 'Obrigatório!';

  if ((diaTodo || (opening_date && ending_date)) && !justificativaUuid)
    errors.justificativaUuid = 'Obrigatório';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.data_justificada) {
    const data = {
      justificativaUuid: values.justificativaUuid,
      motivoUuid: values.motivoUuid,
      extra100: values.extra100,
      extra50: values.extra50,
      noturno: values.noturno,
      descontado: values.descontado,
      credito: values.credito,
      trabalhado: values.trabalhado,
      date: values.opening_date,
    };

    await props.onJustificativaUpdate({ ...data });
  } else if (!props.calendario) {
    await props.onCreation({ ...values }, props.cronograma);
  } else {
    await props.onUpdate({ ...values }, props.cronograma);
  }

  setSubmitting(false);

  resetForm();
};

const frequenciaForm = props => {
  const {
    begin,

    cronograma,

    dataBase,

    calendario,

    justificativas,

    motivos,

    onDelete,

    onJustificativaDelete,

    data_justificada,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={
        !data_justificada
          ? {
              opening_date: !calendario
                ? cronograma
                  ? dataBase && dataBase.substring(0, 10)
                  : begin || ''
                : calendario.dt_calendario.substring(0, 10),

              dt_base:
                calendario && calendario.dt_base
                  ? calendario.dt_base.substring(0, 10)
                  : '',

              ending_date: '',

              time: !calendario
                ? ''
                : calendario.dt_calendario.substring(11, 16),

              justificativaUuid: calendario
                ? calendario.justificativaUuid
                  ? calendario.justificativaUuid
                  : '127f2384-1862-4319-9f04-042e675fed56'
                : '127f2384-1862-4319-9f04-042e675fed56',

              motivoUuid: calendario
                ? calendario.motivoUuid
                  ? calendario.motivoUuid
                  : ''
                : '',

              // se calendario nulo e cronograma preenchido eh dia todo

              diaTodo: !!(!calendario && cronograma),
            }
          : {
              opening_date: dataBase ? dataBase.substring(0, 10) : '',

              ending_date: '',

              time: '',

              justificativaUuid:
                data_justificada.justificativaUuid ||
                '127f2384-1862-4319-9f04-042e675fed56',

              motivoUuid: data_justificada.motivoUuid,

              extra50: data_justificada.extra50,

              extra100: data_justificada.extra100,

              noturno: data_justificada.noturno,

              descontado: data_justificada.descontado,

              credito: data_justificada.credito,

              trabalhado: data_justificada.trabalhado,

              diaTodo: true,
            }
      }
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleChange,

        handleBlur,

        errors,

        touched,

        isSubmitting,

        handleReset,

        dirty,
      }) => (
        <FormStyled>
          <div className="line">
            <div className="formData">
              <strong>DATA</strong>

              <input
                id="opening_date"
                name="opening_date"
                type="date"
                value={values.opening_date}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.opening_date && touched.opening_date)}
              />

              {errors.opening_date && touched.opening_date && (
                <span>{errors.opening_date}</span>
              )}
            </div>

            <div className="formData">
              <strong>DATA TÉRMINO</strong>

              <input
                id="ending_date"
                name="ending_date"
                type="date"
                disabled={!values.diaTodo || data_justificada}
                value={values.ending_date}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.ending_date && touched.ending_date)}
              />

              {errors.ending_date && touched.ending_date && (
                <span>{errors.ending_date}</span>
              )}
            </div>
          </div>

          <div className="formData">
            <strong>HORA</strong>

            <input
              id="time"
              name="time"
              type="time"
              disabled={!!(values.diaTodo || values.ending_date)}
              value={values.time}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.time && touched.time)}
            />

            {errors.time && touched.time && <span>{errors.time}</span>}
          </div>

          <hr />

          <div className="line">
            <div className="formData">
              <strong>JUSTIFICATIVA</strong>

              <select
                id="justificativaUuid"
                name="justificativaUuid"
                value={values.justificativaUuid}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  !!(errors.justificativaUuid && touched.justificativaUuid)
                }
              >
                <>
                  {justificativas &&
                    justificativas.length > 0 &&
                    justificativas.map(justificativa => (
                      <option
                        key={justificativa.uuid}
                        value={justificativa.uuid}
                      >
                        {justificativa.nm_justificativa}
                      </option>
                    ))}
                </>
              </select>

              {errors.justificativaUuid && touched.justificativaUuid && (
                <span>{errors.justificativaUuid}</span>
              )}
            </div>

            <div className="formData">
              <strong>MOTIVO</strong>

              <select
                id="motivoUuid"
                name="motivoUuid"
                value={values.motivoUuid}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.motivoUuid && touched.motivoUuid)}
              >
                <>
                  <option value="" />

                  {motivos &&
                    motivos.length > 0 &&
                    motivos.map(motivo => (
                      <option key={motivo.uuid} value={motivo.uuid}>
                        {motivo.nm_motivo}
                      </option>
                    ))}
                </>
              </select>

              {errors.motivoUuid && touched.motivoUuid && (
                <span>{errors.motivoUuid}</span>
              )}
            </div>

            {!values.diaTodo && (
              <div className="formData">
                <strong>DATA BASE</strong>

                <input
                  id="dt_base"
                  name="dt_base"
                  type="date"
                  disabled={values.diaTodo}
                  value={values.dt_base}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(errors.dt_base && touched.dt_base)}
                />

                {errors.motivoUuid && touched.motivoUuid && (
                  <span>{errors.motivoUuid}</span>
                )}
              </div>
            )}
          </div>

          {values.diaTodo && (
            <>
              <div className="line">
                <div className="formData">
                  <strong>TRABALHADO</strong>

                  <MaskInput
                    id="trabalhado"
                    name="trabalhado"
                    mask="99:99"
                    disabled={!values.diaTodo}
                    value={values.trabalhado}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.trabalhado && touched.trabalhado)}
                  />

                  {errors.trabalhado && touched.trabalhado && (
                    <span>{errors.trabalhado}</span>
                  )}
                </div>

                <div className="formData">
                  <strong>EXTRA 50</strong>

                  <MaskInput
                    id="extra50"
                    name="extra50"
                    mask="99:99"
                    disabled={!values.diaTodo}
                    value={values.extra50}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.extra50 && touched.extra50)}
                  />

                  {errors.extra50 && touched.extra50 && (
                    <span>{errors.extra50}</span>
                  )}
                </div>

                <div className="formData">
                  <strong>EXTRA 100</strong>

                  <MaskInput
                    id="extra100"
                    name="extra100"
                    mask="99:99"
                    disabled={!values.diaTodo}
                    value={values.extra100}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.extra100 && touched.extra100)}
                  />

                  {errors.extra100 && touched.extra100 && (
                    <span>{errors.extra100}</span>
                  )}
                </div>
              </div>

              <div className="line">
                <div className="formData">
                  <strong>ADICIONAL NOTURNO</strong>

                  <MaskInput
                    id="noturno"
                    name="noturno"
                    mask="99:99"
                    disabled={!values.diaTodo}
                    value={values.noturno}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.noturno && touched.noturno)}
                  />

                  {errors.noturno && touched.noturno && (
                    <span>{errors.noturno}</span>
                  )}
                </div>

                <div className="formData">
                  <strong>DESCONTADO</strong>

                  <MaskInput
                    id="descontado"
                    name="descontado"
                    mask="99:99"
                    disabled={!values.diaTodo}
                    value={values.descontado}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.descontado && touched.descontado)}
                  />

                  {errors.descontado && touched.descontado && (
                    <span>{errors.descontado}</span>
                  )}
                </div>

                <div className="formData">
                  <strong>SALDO DE HORAS</strong>

                  <MaskInput
                    id="credito"
                    name="credito"
                    mask="99:99"
                    disabled={!values.diaTodo}
                    value={values.credito}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.credito && touched.credito)}
                  />

                  {errors.credito && touched.credito && (
                    <span>{errors.credito}</span>
                  )}
                </div>
              </div>
            </>
          )}

          <button variant="contained" type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>

          {(calendario || cronograma) && (
            <>
              {calendario && calendario.fl_ativo === false ? (
                <> </>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    if (!data_justificada) onDelete();
                    else onJustificativaDelete();
                  }}
                  className="btn excluir"
                  style={{ margin: '10px' }}
                  disabled={calendario && calendario.fl_ativo === false}
                >
                  EXCLUIR
                </button>
              )}
            </>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

frequenciaForm.propTypes = {
  begin: PropTypes.string,

  cronograma: PropTypes.shape({
    data_justificada: PropTypes.shape({}),
  }),

  dataBase: PropTypes.string,

  contratos: PropTypes.shape({}),

  calendario: PropTypes.shape({
    justificativaUuid: PropTypes.string,

    motivoUuid: PropTypes.string,

    dt_calendario: PropTypes.string,

    dt_base: PropTypes.string,

    fl_ativo: PropTypes.bool,
  }),

  justificativas: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      nm_justificativa: PropTypes.string,
    })
  ),

  motivos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      nm_motivo: PropTypes.string,
    })
  ),

  data_justificada: PropTypes.shape({
    extra50: PropTypes.string,
    extra100: PropTypes.string,
    noturno: PropTypes.string,
    descontado: PropTypes.string,
    credito: PropTypes.string,
    trabalhado: PropTypes.string,
    motivoUuid: PropTypes.string,
    justificativaUuid: PropTypes.string,
    justificativa: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }),

  onDelete: PropTypes.func.isRequired,

  onJustificativaDelete: PropTypes.func.isRequired,
};

frequenciaForm.defaultProps = {
  begin: null,

  calendario: null,

  contratos: null,

  dataBase: null,

  cronograma: null,

  justificativas: [],

  motivos: [],

  data_justificada: null,
};

export default frequenciaForm;
