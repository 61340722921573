import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../services/api';
import Loading from '../components/Loading';
import Parameters from '../../services/parameters';
import createAuditoria from '../../services/auditoria';

// Components

import ContratoForm from './form';
import ContratoList from './list';

// Styles

import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class ContratoContainer extends Component {
  state = {
    modalAddJust: false,
    isLoading: true,
    contratos: null,
    contrato: null,
    isLoadingContrato: false,
  };

  async componentDidMount() {
    createAuditoria('CONTRATOS',localStorage.getItem('USER_UUID'));
    const { match } = this.props;
    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getContrato(uuid);
    }
    this.getContratos();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  async getContratos() {
    this.setState({
      isLoading: true,
    });

    API.get(`contratos`).then(res => {
      const contratos = res.data;

      this.setState({
        contratos,

        isLoading: false,
      });
    });
  }

  getContrato = async uuid => {
    this.setState({
      isLoadingContrato: true,

      modalAddJust: true,
    });

    API.get(`contratos/${uuid}`).then(res => {
      const contrato = res.data[0];

      this.setState({
        contrato,

        isLoadingContrato: false,
      });
    });
  };

  handleContratoDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Contrato?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`contratos/${uuid}`).then(() => {
        this.getContratos();

        this.setState({
          contrato: null,

          modalAddJust: false,
        });
      });
    }
  };

  handleContratoCreation = async values => {
    try {
      await API.post(
        'contratos',

        {
          nm_contrato: values.nm_contrato,

          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getContratos();

      this.setState({ modalAddJust: false });
    } catch (err) {
      alert(err);
    }
  };

  handleContratoUpdate = async values => {
    const { contrato } = this.state;

    try {
      await API.put(
        `contratos/${contrato.uuid}`,

        {
          nm_contrato: values.nm_contrato,

          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getContratos();

      this.setState({ modalAddJust: false, contrato: null });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      modalAddJust,
      isLoadingContrato,
      contrato,
      isLoading,
      contratos,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>CONTRATOS</h1>

            <Link
              to="/contratos"
              onClick={() =>
                this.setState(prevState => ({
                  modalAddJust: !prevState.modalAddJust,

                  contrato: null,
                }))
              }
            >
              {!modalAddJust ? 'ADICIONAR' : 'LISTA'}
            </Link>
          </header>

          <Content>
            {modalAddJust ? (
              <>
                {!isLoadingContrato ? (
                  <ContratoForm
                    onContratoCreation={this.handleContratoCreation}
                    onContratoUpdate={this.handleContratoUpdate}
                    isLoadingContrato={isLoadingContrato}
                    onContratoDelete={this.handleContratoDelete}
                    contrato={contrato}
                  />
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <ContratoList
                isLoading={isLoading}
                onContratoDelete={this.handleContratoDelete}
                onContratoGet={this.getContrato}
                contratos={contratos}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

ContratoContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default ContratoContainer;
