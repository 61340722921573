import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ListGridStyled, ListGridLink } from '../../css/styles/list';

import Loading from '../components/Loading';

const situation = {
  'A': 'Ativo',
  'D': 'Demitido',
  'E': 'Afastado'
}

class FechamentoList extends Component {
  FechamentoListItem = () => {
    const { fechamentos } = this.props;

    return fechamentos.map(fechamento => (
      <ListGridLink
        gridheader="3fr repeat(8,1fr) 0.5fr"
        gridlist="3fr repeat(8,1fr) 0.5fr"
        color="#011826"
        strongcolor="#fff"
        key={fechamento.uuid}
        to={`/funcionarios/${fechamento.funcionarioUuid}`}
      >
        <strong>{fechamento.funcionario.nm_funcionario}</strong>

        <strong>
          {fechamento.trabalhado ? fechamento.trabalhado : '00:00'}
        </strong>

        <strong>{fechamento.extra_50 ? fechamento.extra_50 : '00:00'}</strong>

        <strong>{fechamento.extra_100 ? fechamento.extra_100 : '00:00'}</strong>

        <strong>
          {fechamento.descontado ? fechamento.descontado : '00:00'}
        </strong>

        <strong>{fechamento.noturno ? fechamento.noturno : '00:00'}</strong>

        <strong>
          {fechamento.noturno_calculado
            ? fechamento.noturno_calculado
            : '00:00'}
        </strong>

        <strong>
          {fechamento.banco_de_horas ? fechamento.banco_de_horas : '00:00'}
        </strong>

        <strong>{fechamento.diasTrabalhados}</strong>

        <strong>{situation[fechamento.funcionario.situacao]}</strong>
      </ListGridLink>
    ));
  };

  PendenteListItem = () => {
    const { fechamentos } = this.props;

    return fechamentos.map(funcionario => (
      <ListGridLink
        gridheader="10fr"
        gridlist="10fr"
        color="#011826"
        strongcolor="#fff"
        key={funcionario.uuid}
        to={`/funcionarios/${funcionario.uuid}`}
      >
        <strong>{funcionario.nm_funcionario}</strong>
      </ListGridLink>
    ));
  };

  render() {
    const { isLoading, fechamentos, tipo } = this.props;

    return (
      <>
        {!isLoading ? (
          <>
            {fechamentos && (
              <>
                {fechamentos.length > 0 ? (
                  <ListGridStyled
                    gridheader="3fr repeat(8,1fr) 0.5fr"
                    gridlist="3fr repeat(8,1fr) 0.5fr"
                  >
                    {tipo !== 'PENDENTE' ? (
                      <>
                        <header>
                          <div className="total">
                            <h1>TOTAL: </h1>
                            <p>{fechamentos.length}</p>
                          </div>

                          <div className="titles">
                            <strong className="name">FUNCIONÁRIO</strong>

                            <strong>TRABALHADO</strong>

                            <strong>EXTRA50</strong>

                            <strong>EXTRA100</strong>

                            <strong>DESCONTADO</strong>

                            <strong>NOTURNO</strong>

                            <strong>NOTURNO CALC.</strong>

                            <strong>BANCO</strong>

                            <strong>DIAS</strong>

                            <strong>STATUS</strong>
                          </div>
                        </header>
                        {this.FechamentoListItem()}
                      </>
                    ) : (
                      <>
                        <header>
                          <div className="total">
                            <h1>TOTAL: </h1>
                            <p>{fechamentos.length}</p>
                          </div>

                          <div className="titles">
                            <strong className="name">FUNCIONÁRIO</strong>
                          </div>
                        </header>
                        {this.PendenteListItem()}
                      </>
                    )}
                  </ListGridStyled>
                ) : (
                  <ListGridStyled>
                    <strong>LISTA VAZIA</strong>
                  </ListGridStyled>
                )}
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

FechamentoList.propTypes = {
  isLoading: PropTypes.bool,
  tipo: PropTypes.string,
  fechamentos: PropTypes.arrayOf(
    PropTypes.shape({
      funcionarioUuid: PropTypes.string,
      corpo: PropTypes.string,
      trabalhado: PropTypes.string,
      extra_50: PropTypes.string,
      extra_100: PropTypes.string,
      descontado: PropTypes.string,
      noturno: PropTypes.string,
      noturno_calculado: PropTypes.string,
      banco_de_horas: PropTypes.string,
      diasTrabalhados: PropTypes.string,

      funcionario: PropTypes.shape({
        nm_funcionario: PropTypes.string,
      }),
    })
  ),
};

FechamentoList.defaultProps = {
  fechamentos: [],
  tipo: null,
  isLoading: false,
};

export default FechamentoList;
