import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FormStyled } from '../../css/styles/form';
import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ nm_turno, dt_inicio_vigencia, contratoUuid }) => {
  const errors = {};

  if (!contratoUuid) errors.contratoUuid = 'Obrigatório!';

  if (!nm_turno) errors.nm_turno = 'Obrigatório!';

  if (!dt_inicio_vigencia) errors.dt_inicio_vigencia = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.turno) {
    await props.onTurnoCreation({ ...values });
  } else {
    await props.onTurnoUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const TurnoForm = props => {
  const { turno, onTurnoDelete, contratos } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        contratoUuid: turno ? turno.contratoUuid : '',

        nm_turno: turno ? turno.nm_turno : '',

        dt_inicio_vigencia: turno
          ? turno.dt_inicio_vigencia.substring(0, 10)
          : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleBlur,
        handleChange,
        handleReset,
        errors,
        touched,
        dirty,
        isSubmitting,
      }) => (
        <FormStyled>
          <h1>TURNO</h1>
          <div className="formData">
            <strong>CONTRATO</strong>
            <select
              value={values.contratoUuid}
              onChange={handleChange}
              onBlur={handleBlur}
              name="contratoUuid"
              id="contratoUuid"
            >
              {
                <>
                  <option value="">SELECIONE O CONTRATO</option>

                  {contratos &&
                    contratos.map(contrato => (
                      <option key={contrato.uuid} value={contrato.uuid}>
                        {contrato.nm_contrato}
                      </option>
                    ))}
                </>
              }
            </select>

            {errors.contratoUuid && touched.contratoUuid && (
              <span>{errors.contratoUuid}</span>
            )}
          </div>
          <div className="formData">
            <strong>NOME DO TURNO</strong>
            <input
              id="nm_turno"
              name="nm_turno"
              value={values.nm_turno}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.nm_turno && touched.nm_turno && (
              <span>{errors.nm_turno}</span>
            )}
          </div>
          <div className="formData">
            <strong>DATA DE INÍCIO DA VIGÊNCIA</strong>

            <input
              id="dt_inicio_vigencia"
              name="dt_inicio_vigencia"
              type="date"
              value={values.dt_inicio_vigencia}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.dt_inicio_vigencia && touched.dt_inicio_vigencia && (
              <span>{errors.dt_inicio_vigencia}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>
          {turno && (
            <button
              type="button"
              onClick={() => {
                onTurnoDelete(turno.uuid);
              }}
              className="delete"
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

TurnoForm.propTypes = {
  turno: PropTypes.shape({
    uuid: PropTypes.string,
    contratoUuid: PropTypes.string,
    nm_turno: PropTypes.string,
    dt_inicio_vigencia: PropTypes.string,
  }),
  onTurnoDelete: PropTypes.func.isRequired,
  contratos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_contrato: PropTypes.string,
    })
  ),
};

TurnoForm.defaultProps = {
  turno: null,
  contratos: null,
};

export default TurnoForm;
