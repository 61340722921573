import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';

import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';

import Loading from '../components/Loading';

class FeriadoList extends Component {
  FeriadoListItem = feriados =>
    feriados.map(feriado => (
      <li key={feriado.uuid}>
        <Link to={`/feriados/${feriado.uuid}`} key={feriado.uuid}>
          <strong>{moment.utc(feriado.dt_feriado).format('DD/MM/YYYY')}</strong>

          <strong>{feriado.nm_feriado ? feriado.nm_feriado : ''}</strong>

          <strong>
            {feriado.cd_tipo_apontamento
              ? feriado.cd_tipo_apontamento == 'N'
                ? 'Nacional'
                : feriado.cd_tipo_apontamento == 'M'
                ? 'Municipal'
                : 'Estadual'
              : ''}
          </strong>
        </Link>
      </li>
    ));

  render() {
    const { isLoading, feriados } = this.props;

    return (
      <>
        {!isLoading ? (
          <>
            {feriados && (
              <>
                {feriados.length > 0 ? (
                  <ListStyled>
                    <header>
                      <div className="total">
                        <h1>TOTAL: </h1>

                        <p>{feriados.length}</p>
                      </div>

                      <div className="titles">
                        <strong>DATA</strong>

                        <strong>DESCRIÇÃO</strong>

                        <strong>APONTAMENTO</strong>
                      </div>
                    </header>

                    {this.FeriadoListItem(feriados)}
                  </ListStyled>
                ) : (
                  <ListStyled>
                    <strong>LISTA VAZIA</strong>
                  </ListStyled>
                )}
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

FeriadoList.propTypes = {
  isLoading: PropTypes.bool,

  feriados: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      dt_feriado: PropTypes.string,

      nm_feriado: PropTypes.string,

      cd_tipo_apontamento: PropTypes.string,
    })
  ),
};

FeriadoList.defaultProps = {
  feriados: [],

  isLoading: false,
};

export default FeriadoList;
