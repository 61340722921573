import API from './api';

async function createAuditoria (nm_area, uuserUuid){
    try {        
         await API.post(
            'auditorias/acesso',
            {
                nm_area: nm_area,
                user: uuserUuid,
            },
            { headers: { 'Content-Type': 'application/json' }, }
        ).catch(error => { throw error; });
    } catch (ex) {
        console.log("Não foi possivel inserir auditoria ", ex);
    }
};

export default createAuditoria;