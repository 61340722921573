import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../services/api';
import Loading from '../components/Loading';
import Parameters from '../../services/parameters';
import createAuditoria from '../../services/auditoria';

// Components

import DepartamentoForm from './form';
import DepartamentoList from './list';

// Styles

import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class DepartamentoContainer extends Component {
  
  state = {
    modalAddJust: false,
    isLoading: true,
    departamentos: null,
    departamento: null,
    isLoadingDepartamento: false,
      };

  async componentDidMount() {        
    createAuditoria('DEPARTAMENTO',localStorage.getItem('USER_UUID'));
    const { match } = this.props;        
    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getDepartamento(uuid);
    }

    this.getDepartamentos();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  async getDepartamentos() {
    this.setState({
      isLoading: true,
    });

    API.get(`departamentos`).then(res => {
      const departamentos = res.data;

      this.setState({
        departamentos,

        isLoading: false,
      });
    });
  }

  getDepartamento = async uuid => {
    this.setState({
      isLoadingDepartamento: true,

      modalAddJust: true,
    });

    API.get(`departamentos/${uuid}`).then(res => {
      const departamento = res.data[0];

      this.setState({
        departamento,

        isLoadingDepartamento: false,
      });
    });
  };

  handleDepartamentoDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Departamento?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`departamentos/${uuid}`).then(() => {
        this.getDepartamentos();

        this.setState({
          departamento: null,

          modalAddJust: false,
        });
      });
    }
  };

  handleDepartamentoCreation = async values => {
    try {
      await API.post(
        'departamentos',

        {
          nm_departamento: values.nm_departamento,

          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getDepartamentos();

      this.setState({ modalAddJust: false });
    } catch (err) {
      alert(err);
    }
  };

  handleDepartamentoUpdate = async values => {
    const { departamento } = this.state;

    try {
      await API.put(
        `departamentos/${departamento.uuid}`,

        {
          nm_departamento: values.nm_departamento,

          empresaUuid: Parameters.EMPRESA_DEFAULT,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getDepartamentos();

      this.setState({ modalAddJust: false, departamento: null });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      modalAddJust,
      isLoadingDepartamento,
      departamento,
      isLoading,
      departamentos,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>DEPARTAMENTOS</h1>

            <Link
              to="/departamentos"
              onClick={() =>
                this.setState(prevState => ({
                  modalAddJust: !prevState.modalAddJust,
                  departamento: null,
                }))
              }
            >
              {!modalAddJust ? 'ADICIONAR' : 'LISTA'}
            </Link>
          </header>

          <Content>
            {modalAddJust ? (
              <>
                {!isLoadingDepartamento ? (
                  <DepartamentoForm
                    onDepartamentoCreation={this.handleDepartamentoCreation}
                    onDepartamentoUpdate={this.handleDepartamentoUpdate}
                    isLoadingDepartamento={isLoadingDepartamento}
                    onDepartamentoDelete={this.handleDepartamentoDelete}
                    departamento={departamento}
                  />
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <DepartamentoList
                isLoading={isLoading}
                onDepartamentoDelete={this.handleDepartamentoDelete}
                onDepartamentoGet={this.getDepartamento}
                departamentos={departamentos}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

DepartamentoContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default DepartamentoContainer;
