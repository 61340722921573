import React, { Component } from 'react';

import ReactToPrint from 'react-to-print';

import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdCancel,
} from 'react-icons/md';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import moment from 'moment';

import API from '../../../services/api';

import parameters from '../../../services/parameters';

import createAuditoria from '../../../services/auditoria';

// Components

import FrequenciaForm from './form';

import FrequenciaFilterForm from './filterForm';

import FrequenciaTable from './table';

import ReportTable from '../Report';

// Styles

import { Container, Content, Pagination } from '../../../css/styles/wrapper';
import { List } from './styles';

import {
  ModalStyled,
  ModalContent,
  ModalHeader,
} from '../../../css/styles/modal';

class FrequenciaContainer extends Component {
  state = {
    begin: null,

    calendario: null,

    data_justificada: null,

    dataBase: null,

    formState: false,

    reportView: false,

    motivos: [],

    justificativas: [],

    naoAlocados: [],

    loading: false,
  };

  async componentDidMount() {
    createAuditoria('FICHA FREQUENCIA', localStorage.getItem('USER_UUID'));

    this.getJustificativas();
    this.getMotivos();
  }

  componentDidUpdate(prevState) {
    const { frequencia } = this.props;

    if (frequencia !== prevState.frequencia) {
      let listNaoAlocados = new Array();

      if (
        frequencia &&
        frequencia.pontosNaoAlocados &&
        frequencia.pontosNaoAlocados[0]
      ) {
        listNaoAlocados = frequencia.pontosNaoAlocados;
      }

      this.setState({
        naoAlocados: listNaoAlocados,
      });
    }
  }

  handleCloseFrequency = async () => {
    const {
      funcionario,
      frequencia,
      mesInicial,
      anoInicial,
      onSearchFrequency,
    } = this.props;

    const corpo = {
      dados: frequencia.dados,
    };

    const data = {
      mes_base: parseInt(mesInicial, 10) + 1,
      ano_base: anoInicial,
      corpo: JSON.stringify(corpo),
      trabalhado: frequencia.totalTrabalhadoGeral,
      extra_50: frequencia.totalExtra50Geral,
      extra_100: frequencia.totalExtra100Geral,
      descontado: frequencia.totalDescontadoGeral,
      noturno: frequencia.totalNoturnoGeral,
      noturno_calculado: frequencia.totalNoturnoMultiplicado,
      banco_de_horas: frequencia.totalBancoDeHoraGeral,
      funcionarioUuid: funcionario.uuid,
    };

    try {
      this.setState({ loading: true });

      await API.post('fichaDeFrequencias', data, {
        headers: { 'Content-Type': 'application/json' },
      }).catch(error => {
        throw error;
      });
      await this.setState({
        calendario: null,

        cronograma: null,

        dataBase: null,

        formState: false,
      });

      await onSearchFrequency();

      this.setState({ loading: false });
    } catch (err) {
      alert(err);
    }
  };

  handleOpenFrequency = async () => {
    const { frequencia, onSearchFrequency } = this.props;

    try {
      this.setState({ loading: true });

      // / DIA TODO sem data final
      await API.delete(`fichaDeFrequencias/${frequencia.uuid}`, {
        headers: { 'Content-Type': 'application/json' },
      }).catch(error => {
        throw error;
      });
      await this.setState({
        calendario: null,

        cronograma: null,

        dataBase: null,

        formState: false,
      });

      await onSearchFrequency();

      this.setState({ loading: false });
    } catch (err) {
      alert(err);
    }
  };

  handleCreation = async object => {
    const { funcionario, onSearchFrequency } = this.props;

    try {
      // / DIA TODO sem data final

      if (object.diaTodo && !object.ending_date) {
        await this.createAllDay(object);

        // / DIA TODO com data final == periodo
      } else if (object.diaTodo && object.ending_date) {
        await this.createPeriod(object);
      } else {
        // APENAS UM HORARIO

        await API.post(
          'calendarios',

          {
            // eslint-disable-next-line new-cap

            dt_calendario: new moment.utc(object.opening_date).add(
              moment.duration(object.time)
            ),

            dt_base: new moment.utc(object.dt_base),

            funcionarioUuid: funcionario.uuid,

            justificativaUuid: object.justificativaUuid
              ? object.justificativaUuid
              : null,
            motivoUuid: object.motivoUuid ? object.motivoUuid : null,
          },

          { headers: { 'Content-Type': 'application/json' } }
        ).catch(error => {
          throw error;
        });
      }

      await this.setState({
        calendario: null,

        cronograma: null,

        dataBase: null,

        formState: false,
      });

      onSearchFrequency();
    } catch (err) {
      alert(err);
    }
  };

  createPeriod = async object => {
    const { funcionario } = this.props;

    await API.post(
      'justificativasDataFuncionarios/periodo',

      {
        date: object.opening_date,
        date_final: object.ending_date,
        funcionarioUuid: funcionario.uuid,
        extra50: object.extra50,
        extra100: object.extra100,
        noturno: object.noturno,
        descontado: object.descontado,
        credito: object.credito,
        trabalhado: object.trabalhado,
        justificativaUuid: object.justificativaUuid
          ? object.justificativaUuid
          : null,
        motivoUuid: object.motivoUuid ? object.motivoUuid : null,
      },

      { headers: { 'Content-Type': 'application/json' } }
    ).catch(error => {
      throw error;
    });

    this.setState({ formState: false });
  };

  createAllDay = async object => {
    const { funcionario } = this.props;

    await API.post(
      'justificativasDataFuncionarios',

      {
        date: object.opening_date,
        funcionarioUuid: funcionario.uuid,
        extra50: object.extra50,
        extra100: object.extra100,
        noturno: object.noturno,
        descontado: object.descontado,
        credito: object.credito,
        trabalhado: object.trabalhado,
        justificativaUuid: object.justificativaUuid
          ? object.justificativaUuid
          : null,
        motivoUuid: object.motivoUuid ? object.motivoUuid : null,
      },

      { headers: { 'Content-Type': 'application/json' } }
    ).catch(error => {
      throw error;
    });

    this.setState({ formState: false });
  };

  handleEdit = async (calendario, cronograma, dataBase) => {
    if (cronograma && cronograma.data_justificada && calendario) {
      await this.setState({
        calendario,

        cronograma: null,

        dataBase: calendario.dataBase,

        data_justificada: cronograma.data_justificada,
      });
    } else {
      await this.setState({
        calendario,

        cronograma,

        dataBase,

        data_justificada: null,
      });
    }

    this.setState({
      formState: true,
    });
  };

  handleUpdate = async object => {
    const { funcionario, onSearchFrequency } = this.props;

    const { calendario } = this.state;

    try {
      await API.put(
        `calendarios/${calendario.uuid}`,

        {
          // eslint-disable-next-line new-cap
          dt_calendario: new moment.utc(object.opening_date).add(
            moment.duration(object.time)
          ),

          dt_base: new moment.utc(object.dt_base),

          funcionarioUuid: funcionario.uuid,

          justificativaUuid: object.justificativaUuid
            ? object.justificativaUuid
            : null,
          motivoUuid: object.motivoUuid ? object.motivoUuid : null,
        },

        { headers: { 'Content-Type': 'application/json' } }
      ).catch(error => {
        throw error;
      });

      await this.setState({
        calendario: null,

        cronograma: null,

        dataBase: null,

        formState: false,
      });

      onSearchFrequency();
    } catch (err) {
      alert(err);
    }
  };

  handleJustificativaUpdate = async object => {
    const { onSearchFrequency } = this.props;

    const { data_justificada } = this.state;

    try {
      await API.put(
        `justificativasDataFuncionarios/${data_justificada.uuid}`,

        {
          // eslint-disable-next-line new-cap
          date: new moment.utc(object.date),
          extra50: object.extra50,
          extra100: object.extra100,
          noturno: object.noturno,
          descontado: object.descontado,
          credito: object.credito,
          trabalhado: object.trabalhado,
          justificativaUuid: object.justificativaUuid
            ? object.justificativaUuid
            : null,
          motivoUuid: object.motivoUuid ? object.motivoUuid : null,
        },

        { headers: { 'Content-Type': 'application/json' } }
      ).catch(error => {
        throw error;
      });

      await this.setState({
        calendario: null,

        cronograma: null,

        dataBase: null,

        data_justificada: null,

        formState: false,
      });

      onSearchFrequency();
    } catch (err) {
      alert(err);
    }
  };

  handleDelete = async () => {
    const { onSearchFrequency } = this.props;

    const { calendario } = this.state;

    try {
      await API.delete(
        `calendarios/${calendario.uuid}`,

        { headers: { 'Content-Type': 'application/json' } }
      ).catch(error => {
        throw error;
      });

      await this.setState({
        calendario: null,

        cronograma: null,

        dataBase: null,

        formState: false,
      });

      onSearchFrequency();
    } catch (err) {
      // alert(err);
    }
  };

  handleJustificativaDelete = async () => {
    const { onSearchFrequency } = this.props;

    const { data_justificada } = this.state;

    try {
      await API.delete(
        `justificativasDataFuncionarios/${data_justificada.uuid}`
      ).catch(error => {
        throw error;
      });

      await this.setState({
        calendario: null,

        cronograma: null,

        dataBase: null,

        data_justificada: null,

        formState: false,
      });

      onSearchFrequency();
    } catch (err) {
      alert(err);
    }
  };

  handleRestore = async () => {
    const { onSearchFrequency } = this.props;

    const { calendario } = this.state;

    try {
      await API.put(
        `calendarios/${calendario.uuid}`,

        {
          // eslint-disable-next-line new-cap
          fl_ativo: true,
        },

        { headers: { 'Content-Type': 'application/json' } }
      ).catch(error => {
        throw error;
      });

      await this.setState({
        calendario: null,

        cronograma: null,

        dataBase: null,

        formState: false,
      });

      onSearchFrequency();
    } catch (err) {
      alert(err);
    }
  };

  handleNew = begin => {
    this.setState({
      begin: begin || null,

      calendario: null,

      cronograma: null,

      dataBase: null,

      data_justificada: null,
    });
  };

  async getJustificativas() {
    API.get(`justificativas/${parameters.EMPRESA_DEFAULT}`).then(res => {
      const result = res.data;

      this.setState({
        justificativas: result,
      });
    });
  }

  async getMotivos() {
    API.get(`motivos/${parameters.EMPRESA_DEFAULT}`).then(res => {
      const result = res.data;

      this.setState({
        motivos: result,
      });
    });
  }

  refCalendar = React.createRef();

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  modalTitle = ({ calendario, cronograma }) => {
    if (!calendario && cronograma) {
      return 'NOVA JUSTIFICATIVA';
    }
    if (
      calendario &&
      calendario.cronograma &&
      calendario.cronograma.data_justificada &&
      !cronograma
    ) {
      return 'ALTERAR JUSTIFICATIVA';
    }
    if (calendario && !cronograma) {
      return 'ALTERAR PONTO';
    }
    if (!calendario && !cronograma) {
      return 'NOVO PONTO';
    }
    if (calendario && cronograma) {
      return 'ALTERAR PONTO';
    }
    return 'ALTERAR';
  };

  render() {
    const {
      frequencia,

      onSearchFrequency,

      isLoadingFrequency,

      funcionario,

      onShowFrequencyChange,

      onList,

      printState,

      anteriorFull,

      anterior,

      proximoFull,

      proximo,

      mesInicial,

      anoInicial,

      closed,
    } = this.props;

    const {
      begin,

      calendario,

      cronograma,

      dataBase,

      justificativas,

      motivos,

      data_justificada,

      formState,

      reportView,

      naoAlocados,

      loading,
    } = this.state;

    return (
      <Container>
        <header>
          <h1>FICHA DE FREQUÊNCIA</h1>

          <div className="header_button">
            <button type="button" onClick={onShowFrequencyChange}>
              FUNCIONÁRIO
            </button>
            <button type="button" onClick={onList}>
              LISTA
            </button>
          </div>
        </header>

        <Content>
          {funcionario && (
            <Pagination>
              {anteriorFull && (
                <Link to={`/funcionarios/${anteriorFull && anteriorFull.uuid}`}>
                  <MdKeyboardArrowLeft color="#bf1523" size={35} />
                </Link>
              )}
              {anterior && (
                <Link to={`/funcionarios/${anterior && anterior.uuid}`}>
                  <MdKeyboardArrowLeft color="#333" size={35} />
                </Link>
              )}
              <h1>{funcionario.nm_funcionario}</h1>
              {proximo && (
                <Link to={`/funcionarios/${proximo && proximo.uuid}`}>
                  <MdKeyboardArrowRight color="#333" size={35} />
                </Link>
              )}
              {proximoFull && (
                <Link to={`/funcionarios/${proximoFull && proximoFull.uuid}`}>
                  <MdKeyboardArrowRight color="#bf1523" size={35} />
                </Link>
              )}
            </Pagination>
          )}

          <ModalStyled
            isOpen={!!formState}
            contentLabel="FrequenciaForm"
            ariaHideApp={false}
          >
            <ModalContent>
              <ModalHeader>
                <h1>{this.modalTitle({ calendario, cronograma })}</h1>

                {(calendario || cronograma) && (
                  <>
                    {calendario && calendario.fl_ativo === false ? (
                      <button
                        className="new"
                        type="button"
                        onClick={this.handleRestore}
                      >
                        RESTAURAR
                      </button>
                    ) : (
                      <button
                        className="new"
                        type="button"
                        onClick={this.handleNew}
                      >
                        NOVO PONTO
                      </button>
                    )}
                  </>
                )}

                <button
                  type="button"
                  onClick={() => this.setState({ formState: false })}
                >
                  <MdCancel size={25} color="#F24B4B" />
                </button>
              </ModalHeader>

              <FrequenciaForm
                className="modalForm"
                begin={begin}
                onCreation={this.handleCreation}
                onUpdate={this.handleUpdate}
                onJustificativaUpdate={this.handleJustificativaUpdate}
                onNew={this.handleNew}
                onDelete={this.handleDelete}
                onJustificativaDelete={this.handleJustificativaDelete}
                onReacreate={this.handleRecreate}
                calendario={calendario}
                cronograma={cronograma}
                dataBase={dataBase}
                justificativas={justificativas}
                motivos={motivos}
                data_justificada={data_justificada}
              />
            </ModalContent>
          </ModalStyled>

          <FrequenciaFilterForm onSearchFrequency={onSearchFrequency} />

          {printState && (
            <>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  if (closed) {
                    this.handleOpenFrequency();
                  } else {
                    this.handleCloseFrequency();
                  }
                }}
                style={{
                  display: 'flex',

                  alignItems: 'center',

                  justifyContent: 'center',

                  height: '40px',

                  borderRadius: '4px',

                  padding: '0 15px',

                  boxSizing: 'border-box',

                  background: '#59d99d',

                  fontSize: '16px',

                  fontWeight: 'bold',

                  color: '#fff',

                  marginTop: '32px',
                }}
              >
                {closed
                  ? 'REABRIR FICHA DE FREQUÊNCIA'
                  : 'FECHAR FICHA DE FREQUÊNCIA'}
              </button>

              {closed && (
                <ReactToPrint
                  trigger={() => (
                    <button
                      type="button"
                      disabled={isLoadingFrequency}
                      style={{
                        display: 'flex',

                        alignItems: 'center',

                        justifyContent: 'center',

                        height: '40px',

                        borderRadius: '4px',

                        padding: '0 15px',

                        boxSizing: 'border-box',

                        background: '#59d99d',

                        fontSize: '16px',

                        fontWeight: 'bold',

                        color: '#fff',

                        marginTop: '32px',
                      }}
                    >
                      PRÉVIA DA IMPRESSÃO
                    </button>
                  )}
                  content={() => this.componentRef}
                  onBeforeGetContent={async () => {
                    await this.setState({ reportView: true });
                  }}
                  onAfterPrint={() => this.setState({ reportView: false })}
                />
              )}
            </>
          )}

          {naoAlocados && naoAlocados[0] && (
            <List>
              <small>PONTOS NÃO ALOCADOS:</small>
              <ul>
                {naoAlocados.map(item => (
                  <li key={item.uuid}>
                    <button type="button" onClick={() => this.handleEdit(item)}>
                      <strong>
                        {moment(item.dt_calendario)
                          .utc()
                          .format('DD/MM/YYYY - HH:mm')}
                      </strong>
                    </button>
                  </li>
                ))}
              </ul>
            </List>
          )}

          <FrequenciaTable
            // eslint-disable-next-line no-return-assign
            frequencia={frequencia}
            onSearchFrequency={onSearchFrequency}
            isLoadingFrequency={isLoadingFrequency}
            loading={loading}
            funcionario={funcionario}
            onEdit={this.handleEdit}
            onAdd={date => {
              this.handleNew(date);

              this.setState({ formState: true });
            }}
            closed={closed}
          />

          {reportView && (
            <ReportTable
              // eslint-disable-next-line no-return-assign
              ref={el => (this.componentRef = el)}
              frequencia={frequencia}
              funcionario={funcionario}
              mesInicial={mesInicial}
              anoInicial={anoInicial}
            />
          )}
        </Content>
      </Container>
    );
  }
}

FrequenciaContainer.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),

  funcionario: PropTypes.shape({
    nm_funcionario: PropTypes.string,

    nu_pis_pasep: PropTypes.string,

    uuid: PropTypes.string,
  }).isRequired,

  frequencia: PropTypes.shape({
    dados: PropTypes.array,
    diasInvalidos: PropTypes.arrayOf(PropTypes.shape({})),
    pontosNaoAlocados: PropTypes.arrayOf(PropTypes.shape({})),
    totalTrabalhadoGeral: PropTypes.string,
    totalExtra50Geral: PropTypes.string,
    totalExtra100Geral: PropTypes.string,
    totalDescontadoGeral: PropTypes.string,
    totalNoturnoGeral: PropTypes.string,
    totalNoturnoMultiplicado: PropTypes.string,
    totalBancoDeHoraGeral: PropTypes.string,
    uuid: PropTypes.string,
  }),

  printState: PropTypes.bool,

  onSearchFrequency: PropTypes.func.isRequired,

  isLoadingFrequency: PropTypes.bool.isRequired,

  onShowFrequencyChange: PropTypes.func.isRequired,

  onList: PropTypes.func.isRequired,

  anterior: PropTypes.shape({
    uuid: PropTypes.string,
  }),

  anteriorFull: PropTypes.shape({
    uuid: PropTypes.string,
  }),

  proximo: PropTypes.shape({
    uuid: PropTypes.string,
  }),

  proximoFull: PropTypes.shape({
    uuid: PropTypes.string,
  }),

  mesInicial: PropTypes.number,

  anoInicial: PropTypes.string,

  closed: PropTypes.bool,
};

FrequenciaContainer.defaultProps = {
  frequencia: null,

  classes: null,

  printState: false,

  anterior: null,

  anteriorFull: null,

  proximo: null,

  proximoFull: null,

  mesInicial: null,

  anoInicial: null,

  closed: false,
};

export default FrequenciaContainer;
