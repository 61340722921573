import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { FormStyled } from '../../css/styles/form';

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList({ ...values });

  setSubmitting(false);
};

const FuncionarioFilterForm = props => {
  const { funcionarios, situacao, nm_funcionario, funcionarioUuid } = props;

  const funcionariosResponsaveis =
    funcionarios &&
    funcionarios.filter(
      funcionarioAtivo => funcionarioAtivo.fl_responsavel_ponto
    );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nm_funcionario: nm_funcionario || '',

        situacao: situacao || 'A',

        funcionarioUuid: funcionarioUuid || '',
      }}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleBlur,
        handleReset,
        handleChange,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>FILTROS</h1>
          <div className="formData">
            <strong>SITUAÇÃO</strong>
            <select
              id="situacao"
              name="situacao"
              value={values.situacao}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="A">ATIVO</option>

              <option value="E">AFASTADO</option>

              <option value="D">DEMITIDO</option>
            </select>
          </div>
          <div className="formData">
            <strong>NOME</strong>
            <input
              placeholder="Digite o nome do funcionário..."
              id="nm_funcionario"
              name="nm_funcionario"
              value={values.nm_funcionario}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="formData">
            <strong>SUPERVISOR</strong>
            <select
              id="funcionarioUuid"
              name="funcionarioUuid"
              value={values.funcionarioUuid}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" />

              {funcionariosResponsaveis &&
                funcionariosResponsaveis.length > 0 &&
                funcionariosResponsaveis.map(object => (
                  <option key={object.uuid} value={object.uuid}>
                    {object.nm_funcionario}
                  </option>
                ))}
            </select>
          </div>

          <button type="submit" disabled={isSubmitting}>
            BUSCAR
          </button>

          <button
            type="button"
            disabled={isSubmitting || !dirty}
            onClick={handleReset}
          >
            DESFAZER
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

FuncionarioFilterForm.propTypes = {
  funcionarios: PropTypes.arrayOf(
    PropTypes.shape({
      fl_responsavel_ponto: PropTypes.bool,
    })
  ),
  situacao: PropTypes.string,
  nm_funcionario: PropTypes.string,
  funcionarioUuid: PropTypes.string,
};

FuncionarioFilterForm.defaultProps = {
  funcionarios: [],
  situacao: null,
  nm_funcionario: null,
  funcionarioUuid: null,
};

export default FuncionarioFilterForm;
