import styled from 'styled-components';

const buttonLabelColor = apontamento => {
  let color = '#000';

  switch (apontamento) {
    case 'M':
      color = '#CC0034';

      break;

    case 'J':
      color = '#D98E04';

      break;

    case 'A':
      color = '#2039CC';

      break;

    case 'I':
      color = '#000';

      break;

    case 'D':
      color = '#67497F';

      break;

    default:
      color = '#000';

      break;
  }

  return color;
};

export const TableContainer = styled.div`
  width: 100%;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  margin: 32px 0;

  box-sizing: border-box;
`;

export const TableStyled = styled.table`
  width: 115%;

  border: 1px solid #193441;

  border-radius: 4px;

  -webkit-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  -moz-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);
`;

export const Row = styled.tr`
  &:first-child {
    height: 35px;

    font-size: 14px;

    font-weight: bold;

    background: #193441;

    color: #fff;
  }

  &:last-child {
    height: 35px;

    font-size: 14px;

    font-weight: bold;

    background: #193441;

    color: #fff;
  }

  font-size: 14px;

  color: #333;

  height: 30px;

  th {
    border-bottom: 1px solid #333;
    text-align: center;

    small {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 16px;

      font-size: 10px;
      font-weight: bold;
    }

    &:last-child {
      display: flex;

      align-items: center;

      justify-content: center;

      height: 35px;

      width: 25px;

      button {
        background: #193441;

        border: 0;

        display: flex;

        align-items: center;

        justify-content: center;
      }
    }
  }

  td {
    border-bottom: 1px solid #ddd;

    border-top: 1px solid #ddd;

    text-align: center;

    &.irregular {
      border-bottom: 1px solid #ddd;

      border-top: 1px solid #ddd;

      color: #fff;

      background: #bd4332;

      button {
        strong {
          color: #fff;
        }
      }
    }

    &.database {
      border-right: 1px solid #ddd;

      background: red;

      color: #fff;

      strong {
        font-weight: bold;
      }
    }

    &.last {
      border-top: 1px solid #ddd;

      border-bottom: 1px solid #ddd;

      width: 25px;
    }
  }
`;

export const TableButton = styled.button`
  width: 100%;

  display: flex;

  align-items: center;

  justify-content: center;

  background: none;

  font-size: 14px;

  font-weight: ${props => (props.apontamento === 'D' ? 'bold' : 'normal')};

  color: ${props =>
    props.apontamento ? buttonLabelColor(props.apontamento) : '#333'};

  &:disabled {
    cursor: default;
  }

  strong {
    font-weight: bold;
  }
`;
