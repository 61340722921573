const dev = {
  API_PATH: 'http://localhost:4000',

  EMPRESA_DEFAULT: '1c281efa-6ab1-444d-8384-a7450fa0dfe1',
};

const qa = {
  API_PATH: 'https://companyfy-qa.herokuapp.com',

  EMPRESA_DEFAULT: '1c281efa-6ab1-444d-8384-a7450fa0dfe1',
};

const prod = {
  API_PATH: 'https://companyfy.herokuapp.com',

  EMPRESA_DEFAULT: '1c281efa-6ab1-444d-8384-a7450fa0dfe1',
};

const configSystem =
  process.env.REACT_APP_STAGE === 'production'
    ? prod
    : process.env.REACT_APP_STAGE === 'qa'
    ? qa
    : dev;

/*  SE ESTIVER EM DEV E QUISER TROCAR O AMBIENTE

      TROQUE APENAS ESSA LINHA ACIMA

  */

export default configSystem;
