import React from 'react';
import logo from '../../../img/LogoLauer.png';

export default function HeaderReport() {
  return (
    <header className="main-header">
      <center>
        <div className="logo">
          <img
            src={logo}
            alt=""
            style={{
              marginTop: '15px',
            }}
          />
        </div>
      </center>
    </header>
  );
}
