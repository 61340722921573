import React from 'react';
import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';
import Loading from '../components/Loading';
import * as DateTimeUIUtil from '../../Utilities/Datetime';

const auditoriasListItem = props =>
  props.auditorias.map(auditoria => (
    <li key={auditoria.uuid}>      
        <strong>{DateTimeUIUtil.formatShortDateTime(auditoria.dt_auditoria)}</strong>
        <strong>{auditoria.nm_area}</strong>
        <strong>{auditoria.nm_categoria}</strong>
        <strong>{auditoria.username}</strong>              
    </li>
  ));

function AuditoriaList(props) {
  const { isLoading, auditorias } = props;  
  return (
    <>
      {!isLoading ? (
        <>
          {auditorias.length > 0 ? (
            <ListStyled>
              <header>
                <div className="total">
                  <h1>TOTAL: </h1>
                  <p>{auditorias.length}</p>
                </div>

                <div className="titles">
                  <strong>DATA</strong>
                  <strong>AREA</strong>
                  <strong>CATEGORIA</strong>
                  <strong>USUARIO</strong>                  
                </div>
              </header>
              {auditoriasListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>LISTA VAZIA</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

AuditoriaList.propTypes = {
  isLoading: PropTypes.bool,
  auditorias: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      dt_auditoria: PropTypes.instanceOf(Date),
      nm_area: PropTypes.string,
      nm_categoria: PropTypes.string,
      tx_detalhe: PropTypes.string,
      userUuid: PropTypes.string,
    })
  ),
};

AuditoriaList.defaultProps = {
  auditorias: [],
  isLoading: false,
};
export default AuditoriaList;
