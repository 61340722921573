import React from 'react';

import { Formik } from 'formik';

import styled from 'styled-components';

import moment from 'moment';

import PropTypes from 'prop-types';

import { FormStyled } from '../../css/styles/form';

import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ dt_feriado, cd_tipo_apontamento }) => {
  const errors = {};

  if (!dt_feriado) errors.dt_feriado = 'Obrigatório!';

  if (!cd_tipo_apontamento) errors.cd_tipo_apontamento = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.feriado) {
    await props.onFeriadoCreation({ ...values });
  } else {
    await props.onFeriadoUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const FeriadoForm = props => {
  const { feriado, onFeriadoDelete } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        dt_feriado:
          feriado && feriado.dt_feriado
            ? moment.utc(feriado.dt_feriado).format('YYYY-MM-DD')
            : '',

        cd_tipo_apontamento: feriado && (feriado.cd_tipo_apontamento || ''),

        nm_feriado: feriado && (feriado.nm_feriado || ''),
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleChange,

        handleReset,

        handleBlur,

        errors,

        touched,

        dirty,

        isSubmitting,
      }) => (
        <FormStyled>
          <h1>FERIADO</h1>

          <div className="formData">
            <strong>DESCRIÇÃO</strong>

            <input
              id="nm_feriado"
              name="nm_feriado"
              value={values.nm_feriado}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.nm_feriado && touched.nm_feriado && (
              <span>{errors.nm_feriado}</span>
            )}
          </div>

          <div className="formData">
            <strong>DATA</strong>

            <input
              id="dt_feriado"
              name="dt_feriado"
              type="date"
              value={values.dt_feriado}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.dt_feriado && touched.dt_feriado && (
              <span>{errors.dt_feriado}</span>
            )}
          </div>

          <div className="formData">
            <strong>TIPO DE APONTAMENTO</strong>

            <select
              id="cd_tipo_apontamento"
              name="cd_tipo_apontamento"
              value={values.cd_tipo_apontamento}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" />

              <option value="N">Nacional</option>

              <option value="E">Estadual</option>

              <option value="M">Municipal</option>
            </select>

            {errors.cd_tipo_apontamento && touched.cd_tipo_apontamento && (
              <span>{errors.cd_tipo_apontamento}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>

          {feriado && (
            <button
              type="button"
              onClick={() => {
                onFeriadoDelete(feriado.uuid);
              }}
              className="delete"
              style={{ margin: '10px' }}
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

FeriadoForm.propTypes = {
  feriado: PropTypes.shape({
    uuid: PropTypes.string,

    dt_feriado: PropTypes.string,

    cd_tipo_apontamento: PropTypes.string,

    nm_feriado: PropTypes.string,
  }),

  onFeriadoDelete: PropTypes.func.isRequired,
};

FeriadoForm.defaultProps = {
  feriado: null,
};

export default FeriadoForm;
