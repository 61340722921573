import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FormStyled } from '../../css/styles/form';
import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ nm_contrato }) => {
  const errors = {};

  if (!nm_contrato) errors.nm_contrato = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.contrato) {
    await props.onContratoCreation({ ...values });
  } else {
    await props.onContratoUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const ContratoForm = props => {
  const { contrato, onContratoDelete } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nm_contrato: contrato ? contrato.nm_contrato : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        isSubmitting,
        dirty,
        errors,
        touched,
      }) => (
        <FormStyled>
          <h1>CONTRATO</h1>
          <div className="formData">
            <strong>NOME DO CONTRATO</strong>
            <input
              id="nm_contrato"
              name="nm_contrato"
              value={values.nm_contrato}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.nm_contrato && touched.nm_contrato && (
              <span>{errors.nm_contrato}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>
          {contrato && (
            <button
              type="button"
              onClick={() => {
                onContratoDelete(contrato.uuid);
              }}
              className="delete"
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

ContratoForm.propTypes = {
  contrato: PropTypes.shape({
    uuid: PropTypes.string,
    nm_contrato: PropTypes.string,
  }),
  onContratoDelete: PropTypes.func.isRequired,
};

ContratoForm.defaultProps = {
  contrato: null,
};

export default ContratoForm;
