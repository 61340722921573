import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';

import API from '../../services/api';

import Loading from '../components/Loading';

import createAuditoria from '../../services/auditoria';

// Components

import FeriadoForm from './form';

import FeriadoList from './list';

// Styles

import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class FeriadoContainer extends Component {
  state = {
    modalAddFeriado: false,

    isLoading: true,

    feriados: [],

    feriado: null,

    isLoadingFeriado: false,
  };

  async componentDidMount() {
    createAuditoria('FERIADO', localStorage.getItem('USER_UUID'));

    const { match } = this.props;

    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getFeriado(uuid);
    }

    this.getFeriados();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  async getFeriados() {
    this.setState({
      isLoading: true,
    });

    await API.get(`feriados`).then(res => {
      const feriados = res.data;

      console.log('###', res.data);

      this.setState({
        feriados,

        isLoading: false,
      });
    });
  }

  async getFeriado(uuid) {
    this.setState({
      isLoadingFeriado: true,

      modalAddFeriado: true,
    });

    await API.get(`feriados/${uuid}`).then(res => {
      const feriado = res.data[0];

      this.setState({
        feriado,

        isLoadingFeriado: false,
      });
    });
  }

  handleFeriadoDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Feriado?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      await API.delete(`feriados/${uuid}`).then(() => {
        this.getFeriados();

        this.setState({
          feriado: null,

          modalAddFeriado: false,
        });
      });
    }
  };

  handleFeriadoCreation = async values => {
    try {
      await API.post(
        'feriados',

        {
          dt_feriado: values.dt_feriado,

          cd_tipo_apontamento: values.cd_tipo_apontamento,

          nm_feriado: values.nm_feriado,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getFeriados();

      this.setState({ modalAddFeriado: false });
    } catch (err) {
      alert(err);
    }
  };

  handleFeriadoUpdate = async values => {
    const { feriado } = this.state;

    try {
      await API.put(
        `feriados/${feriado.uuid}`,

        {
          dt_feriado: values.dt_feriado,

          cd_tipo_apontamento: values.cd_tipo_apontamento,

          nm_feriado: values.nm_feriado,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getFeriados();

      this.setState({ modalAddFeriado: false, feriado: null });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      modalAddFeriado,

      isLoadingFeriado,

      feriados,

      isLoading,

      feriado,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>FERIADOS</h1>

            <Link
              to="/feriados"
              onClick={() =>
                this.setState(prevState => ({
                  modalAddFeriado: !prevState.modalAddFeriado,

                  feriado: null,
                }))
              }
            >
              {!modalAddFeriado ? 'ADICIONAR' : 'LISTA'}
            </Link>
          </header>

          <Content>
            {modalAddFeriado ? (
              <>
                {!isLoadingFeriado ? (
                  <FeriadoForm
                    onFeriadoCreation={this.handleFeriadoCreation}
                    onFeriadoUpdate={this.handleFeriadoUpdate}
                    isLoadingFeriado={isLoadingFeriado}
                    onFeriadoDelete={this.handleFeriadoDelete}
                    feriado={feriado}
                  />
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <FeriadoList
                isLoading={isLoading}
                onFeriadoGet={this.getFeriado}
                feriados={feriados}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

FeriadoContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default FeriadoContainer;
