import React, { Component } from 'react';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import API from '../../services/api';

// Components
import RelatorioFilterForm from './filterForm';
import RelatorioList from './list';

// Styles
import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class RelatorioContainer extends Component {
  state = {
    isLoading: false,
    superiores: null,
    superiorUuid: null,
    funcionarios: null,
    nm_funcionario: null,
    turnos: null,
    turnoUuid: null,
    tipoPonto: null,
    begin: moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };

  componentWillMount() {
    this.getSuperiores();
    this.getTurnos();
  }

  getSuperiores = async () => {
    await API.get(`funcionarios/A/null/null`).then(res => {
      const result = res.data;

      const superiores =
        result &&
        result.filter(funcionario => funcionario.fl_responsavel_ponto);

      this.setState({
        superiores,
      });
    });
  };

  getTurnos = async () => {
    API.get(`turnos`).then(res => {
      const result = res.data;

      this.setState({
        turnos: result,
      });
    });
  };

  getFuncionarios = async values => {
    await this.setState(prevState => ({
      isLoading: true,
      nm_funcionario: values ? values.nm_funcionario : prevState.nm_funcionario,
      superiorUuid: values ? values.superiorUuid : prevState.superiorUuid,
      turnoUuid: values ? values.turnoUuid : prevState.turnoUuid,
      tipoPonto: values ? values.tipoPonto : prevState.tipoPonto,
      begin: values ? values.begin : prevState.begin,
      end: values ? values.end : prevState.end,
    }));

    const {
      nm_funcionario,
      superiorUuid,
      turnoUuid,
      tipoPonto,
      begin,
      end,
    } = this.state;

    await API.get(
      `/calendarios/lista/${nm_funcionario || null}/${superiorUuid ||
        null}/${turnoUuid || null}/${tipoPonto || null}/${begin ||
        null}/${end || null}`
    ).then(res => {
      const result = res.data;

      this.setState({
        funcionarios: result.funcionarios,
      });
    });

    this.setState({
      isLoading: false,
    });
  };

  exportToCSV = (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  generateExcelDataTotais = funcionarios => {
    const arrayExcel = [];

    funcionarios.forEach(funcionario => {
      const { folha, nm_funcionario } = funcionario;

      const {
        totalTrabalhadoGeral,
        totalDescontadoGeral,
        totalExtra100Geral,
        totalExtra50Geral,
        totalNoturnoGeral,
      } = folha;

      const NOME = nm_funcionario;
      let TRABALHADO = '-';
      let DESCONTADO = '-';
      let EXTRA100 = '-';
      let EXTRA50 = '-';
      let NOTURNO = '-';

      const pontoImpar = folha.diasInvalidos[0]
        ? folha.diasInvalidos.filter(item => item.tipo !== 'FALTA')
        : null;

      if (pontoImpar && pontoImpar[0]) {
        TRABALHADO = 'PONTO IRREGULAR';
        DESCONTADO = '-';
        EXTRA100 = '-';
        EXTRA50 = '-';
        NOTURNO = '-';
      } else {
        TRABALHADO = totalTrabalhadoGeral || '-';
        DESCONTADO = totalDescontadoGeral || '-';
        EXTRA100 = totalExtra100Geral || '-';
        EXTRA50 = totalExtra50Geral || '-';
        NOTURNO = totalNoturnoGeral || '-';
      }

      const objeto = {
        NOME,
        TRABALHADO,
        DESCONTADO,
        EXTRA100,
        EXTRA50,
        NOTURNO,
      };

      arrayExcel.push(objeto);
    });

    return arrayExcel;
  };

  generateExcelData = funcionarios => {
    const arrayExcel = [];
    const { tipoPonto } = this.state;

    funcionarios.forEach(funcionario => {
      const { folha, nm_funcionario } = funcionario;

      const {
        totalTrabalhadoGeral,
        totalDescontadoGeral,
        totalExtra100Geral,
        totalExtra50Geral,
        totalNoturnoGeral,
        pontosNaoAlocados,
        dados,
      } = folha;

      let COL_01 = 'NOME';
      let COL_02 = 'DATA';
      let COL_03 = 'TIPO';
      let COL_04 = 'PONTOS';
      let COL_05 = 'EXPEDIENTE';
      let COL_06 = 'PERIODO TOTAL';
      let COL_07 = 'DESCONTADO';
      let COL_08 = 'EXTRA 50%';
      let COL_09 = 'EXTRA 100%';
      let COL_10 = 'NOTURNO';
      let COL_11 = 'NOTURNO CALCULADO';
      let COL_12 = 'JUSTIFICATIVA';

      const linha01 = {
        COL_01,
        COL_02,
        COL_03,
        COL_04,
        COL_05,
        COL_06,
        COL_07,
        COL_08,
        COL_09,
        COL_10,
        COL_11,
        COL_12,
      };

      arrayExcel.push(linha01);

      COL_01 = nm_funcionario;
      COL_02 = '';
      COL_03 = '';
      COL_04 = '';
      COL_05 = '';
      COL_06 = '';
      COL_07 = '';
      COL_08 = '';
      COL_09 = '';
      COL_10 = '';
      COL_11 = '';
      COL_12 = '';

      const linha02 = {
        COL_01,
        COL_02,
        COL_03,
        COL_04,
        COL_05,
        COL_06,
        COL_07,
        COL_08,
        COL_09,
        COL_10,
        COL_11,
        COL_12,
      };

      arrayExcel.push(linha02);

      dados.forEach(dia => {
        const {
          calendarios,
          cronograma,
          dataBase,
          totais
        } = dia;

        const {
          cd_cronograma,
          data_justificada,
          fl_virada,
          qt_hr_trabalho,
        } = cronograma;

        // busca data
        let data = moment.utc(dataBase).format('DD/MM');

        // busca tipo
        let tipo;

        if (calendarios.length % 2 !== 0 && calendarios.length <= 4) {
          tipo = 'PONTO IMPAR';
        } else {
          switch (cd_cronograma) {
            case 'TRABALHO':
              tipo = 'TRABALHO';
              break;
            case 'FOLGA':
              tipo = 'FOLGA';
              break;
            case 'FERIADO':
              tipo = 'FERIADO';
              break;
            default:
              tipo = cd_cronograma;
              break;
          }
        }

        // busca pontos
        let pontosCalendario = calendarios.map(calendario => {
          return `${moment(calendario.dt_calendario).utc().format('HH:mm')}`
        });

        let pontosString = pontosCalendario.length > 0 ? pontosCalendario.join(' - ') : '-';

        if(pontosString !== '-' && fl_virada) {
          pontosString = `+${pontosString}`;
        }

        // busca expediente
        let expediente = cd_cronograma === 'TRABALHO'
            ? qt_hr_trabalho.substring(0, 5)
            : '-';

        // busca periodo total
        let periodoTotal = totais && totais.trabalhado
          ? totais.trabalhado
          : '-';

        // busca descontado
        let descontado = totais && totais.descontado
          ? totais.descontado
          : '-';

        // busca extra50 e extra100
        let extra50 = totais && totais.extra50
          ? totais.extra50
          : '-';
        let extra100 = totais && totais.extra100
          ? totais.extra100
          : '-';

        // busca noturno e noturnoCalculado
        let noturno = totais && totais.noturno
          ? totais.noturno
          : '-';
        let noturnoCalculado = totais && totais.noturnoMultiplicado
          ? totais.noturnoMultiplicado
          : '-';

        // busca justificativa
        let justificativa = data_justificada
          ? data_justificada.justificativa.nm_justificativa
          : '-';

        if (
          (tipoPonto !== 'IRREGULAR + DESCONTADO' && tipoPonto !== 'DESCONTADO + JUSTIFICADO') ||
          (tipoPonto === 'IRREGULAR + DESCONTADO' && descontado !== '-') ||
          (tipoPonto === 'DESCONTADO + JUSTIFICADO' && (data_justificada && calendarios.length === 4 && calendarios.length % 2 !== 0))
        ) {
          COL_01 = '';
          COL_02 = data;
          COL_03 = tipo;
          COL_04 = pontosString;
          COL_05 = expediente;
          COL_06 = periodoTotal;
          COL_07 = descontado;
          COL_08 = extra50;
          COL_09 = extra100;
          COL_10 = noturno;
          COL_11 = noturnoCalculado;
          COL_12 = justificativa;

          const linhaX = {
            COL_01,
            COL_02,
            COL_03,
            COL_04,
            COL_05,
            COL_06,
            COL_07,
            COL_08,
            COL_09,
            COL_10,
            COL_11,
            COL_12,
          };

          arrayExcel.push(linhaX);
        }
      });

      if (
        pontosNaoAlocados &&
        pontosNaoAlocados[0] &&
        tipoPonto !== 'DESCONTADO + JUSTIFICADO'
      ) {
        let pontos = '';

        pontosNaoAlocados.forEach(ponto => {
          if (ponto && ponto.dt_calendario) {
            if (pontos) {
              pontos = `${pontos} - ${moment
                .utc(ponto.dt_calendario)
                .format('DD/MM/YYYY hh:mm')}`;
            } else {
              pontos = moment
                .utc(ponto.dt_calendario)
                .format('DD/MM/YYYY hh:mm');
            }
          }
        });

        COL_01 = '';
        COL_02 = '-';
        COL_03 = 'PONTOS NÃO ALOCADOS';
        COL_04 = pontos;
        COL_05 = '';
        COL_06 = '';
        COL_07 = '';
        COL_08 = '';
        COL_09 = '';
        COL_10 = '';
        COL_11 = '';
        COL_12 = '';

        const linhaX = {
          COL_01,
          COL_02,
          COL_03,
          COL_04,
          COL_05,
          COL_06,
          COL_07,
          COL_08,
          COL_09,
          COL_10,
          COL_11,
          COL_12,
        };

        arrayExcel.push(linhaX);
      }

      COL_01 = '';
      COL_02 = '';
      COL_03 = '';
      COL_04 = '';
      COL_05 = '';
      COL_06 = 'TOTAL_TRABALHADO';
      COL_07 = 'TOTAL_DESCONTADO';
      COL_08 = 'TOTAL_EXTRA50';
      COL_09 = 'TOTAL_EXTRA100';
      COL_10 = 'TOTAL_NOTURNO';
      COL_11 = '';
      COL_12 = '';

      const linha03 = {
        COL_01,
        COL_02,
        COL_03,
        COL_04,
        COL_05,
        COL_06,
        COL_07,
        COL_08,
        COL_09,
        COL_10,
        COL_11,
        COL_12,
      };

      arrayExcel.push(linha03);

      COL_01 = '';
      COL_02 = '';
      COL_03 = '';
      COL_04 = '';
      COL_05 = '';
      COL_06 = totalTrabalhadoGeral || '-';
      COL_07 = totalDescontadoGeral || '-';
      COL_08 = totalExtra50Geral || '-';
      COL_09 = totalExtra100Geral || '-';
      COL_10 = totalNoturnoGeral || '-';

      const linha04 = {
        COL_01,
        COL_02,
        COL_03,
        COL_04,
        COL_05,
        COL_06,
        COL_07,
        COL_08,
        COL_09,
        COL_10,
        COL_11,
        COL_12,
      };

      arrayExcel.push(linha04);

      const vazio = {};

      arrayExcel.push(vazio);
    });

    return arrayExcel;
  };

  render() {
    const {
      superiorUuid,
      funcionarios,
      superiores,
      nm_funcionario,
      turnos,
      turnoUuid,
      tipoPonto,
      begin,
      end,
      isLoading,
    } = this.state;

    const nomeArquivo = `RELATÓRIO DE FREQUÊNCIA GERAL DE ${moment(
      begin
    ).format('DD-MM-YYYY')} A ${moment(end).format('DD-MM-YYYY')}`;

    const nomeArquivoTotais = `RELATÓRIO DE FREQUÊNCIA DE ${moment(
      begin
    ).format('DD-MM-YYYY')} A ${moment(end).format('DD-MM-YYYY')}`;

    const excelData = funcionarios ? this.generateExcelData(funcionarios) : '';

    const excelDataTotais = funcionarios
      ? this.generateExcelDataTotais(funcionarios)
      : '';

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>RELATÓRIOS</h1>

            <button
              type="button"
              className="excel"
              disabled={!funcionarios}
              onClick={() => this.exportToCSV(excelData, nomeArquivo)}
            >
              EXCEL RELATÓRIO GERAL
            </button>

            {tipoPonto !== 'IRREGULAR' && (
              <button
                type="button"
                className="excel"
                disabled={!funcionarios}
                onClick={() =>
                  this.exportToCSV(excelDataTotais, nomeArquivoTotais)
                }
              >
                EXCEL RELATÓRIO DE TOTAIS
              </button>
            )}
          </header>

          <Content>
            <RelatorioFilterForm
              onList={this.getFuncionarios}
              funcionarios={funcionarios}
              superiores={superiores}
              superiorUuid={superiorUuid}
              nm_funcionario={nm_funcionario}
              turnos={turnos}
              turnoUuid={turnoUuid}
              tipoPonto={tipoPonto}
              begin={begin}
              end={end}
            />

            <RelatorioList isLoading={isLoading} funcionarios={funcionarios} />
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

export default RelatorioContainer;
