import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import imgloading from '../../../img/load.gif';
import { FormStyled } from '../../../css/styles/form';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ dt_inicio, turnoUuid }) => {
  const errors = {};

  if (!turnoUuid) errors.turnoUuid = 'Obrigatório!';

  if (!dt_inicio) errors.dt_inicio = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  const { dt_retorno } = values;

  if (dt_retorno) {
    await props.onAlocacaoTurnoTrocaRapida({ ...values });
  } else {
    await props.onAlocacaoTurnoCreation({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const AlocacaoTurnoForm = props => {
  const { alocacaoTurno, turnos } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        dt_inicio: alocacaoTurno ? alocacaoTurno.dt_inicio : '',

        dt_retorno: '',

        turnoUuid: alocacaoTurno ? alocacaoTurno.turnoUuid : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        handleReset,
        dirty,
      }) => (
        <FormStyled>
          <h1>NOVO TURNO</h1>
          <div className="formData">
            <strong>TURNO/ CONTRATO</strong>
            <select
              id="turnoUuid"
              name="turnoUuid"
              value={values.turnoUuid}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <>
                <option value="" />
                {turnos &&
                  turnos.length > 0 &&
                  turnos.map(object => (
                    <option key={object.uuid} value={object.uuid}>
                      {`${object.nm_turno} | ${object.contrato.nm_contrato}`}
                    </option>
                  ))}
              </>
            </select>

            {errors.turnoUuid && touched.turnoUuid && (
              <span>{errors.turnoUuid}</span>
            )}
          </div>
          <div className="formData">
            <strong>DATA INÍCIO</strong>
            <input
              id="dt_inicio"
              name="dt_inicio"
              type="date"
              value={values.dt_inicio}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.dt_inicio && touched.dt_inicio && (
              <span>{errors.dt_inicio}</span>
            )}
          </div>

          <div className="formData">
            <strong>DATA DE RETORNO PARA TROCA RAPIDA</strong>
            <input
              id="dt_retorno"
              name="dt_retorno"
              type="date"
              value={values.dt_retorno}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.dt_retorno && touched.dt_retorno && (
              <span>{errors.dt_retorno}</span>
            )}
          </div>

          <button variant="contained" type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

AlocacaoTurnoForm.propTypes = {
  alocacaoTurno: PropTypes.shape({
    dt_inicio: PropTypes.string,
    turnoUuid: PropTypes.string,
  }),
  turnos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_turno: PropTypes.string,
      contrato: PropTypes.shape({
        nm_contrato: PropTypes.string,
      }),
    })
  ),
};

AlocacaoTurnoForm.defaultProps = {
  alocacaoTurno: null,
  turnos: [],
};

export default AlocacaoTurnoForm;
