import React, { Component } from 'react';

import { Router, Route, Switch } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

import io from 'socket.io-client';

import API from './services/api';

import history from './services/history';

import Parameters from './services/parameters';

import 'react-toastify/dist/ReactToastify.css';

import 'moment/locale/pt-br';

import createAuditoria from './services/auditoria';

// Views

import Login from './views/Login';

import DepartamentoContainer from './views/Departamento';

import CargoContainer from './views/Cargo';

import FuncionarioContainer from './views/Funcionario';

import TurnoContainer from './views/Turno';

import ContratoContainer from './views/Contrato';

import RelatorioContainer from './views/Relatorio';

import FechamentoContainer from './views/Fechamento';

import Import from './views/Import';

import Usuarios from './views/Usuarios';

import NovoUsuario from './views/Usuarios/new';

import AuditoriaContainer from './views/Auditoria';

import JustificativaContainer from './views/Justificativa';

import MotivoContainer from './views/Motivo';

import FeriadoContainer from './views/Feriado';

// Components

import Menu from './views/components/Menu/index';

import HeaderReport from './views/components/HeaderReport/index';

import Header from './views/components/Header/index';

// Images

// Css

import './css/main.scss';

io(Parameters.API_PATH);

class App extends Component {
  state = {
    isAuth: false,

    isReport: false,

    username: '',

    useruuid: '',

    photo: '',
  };

  componentDidMount() {
    const username = localStorage.getItem('USER_USERNAME');
    const useruuid = localStorage.getItem('USER_UUID');
    const photo = localStorage.getItem('USER_PHOTO');

    if (username && useruuid) {
      this.setState({
        isAuth: true,
        username,
        useruuid,
        photo,
      });
      createAuditoria('APLICACAO', useruuid);
    }
  }

  /**

   * @argument message = mensagem texto puro do toast

   * @argument linkOnClick caso preenchido com uma rota, ao clicar no toast,

   * redireciona a página para a rota informada

   */

  notifyErrorTextOnClick = (message, linkOnClick) => {
    const routeLink = !linkOnClick || linkOnClick === '' ? null : linkOnClick;

    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,

      onClick: () => {
        if (routeLink) history.push(routeLink);
      },
    });
  };

  notifyErrorText = message => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  handleLogin = async (email, passwd, lembrar = false) => {
    try {
      const logado = await API.post(
        `auth/login`,

        { username: email, password: passwd },

        { headers: { 'Content-Type': 'application/json' } }
      );

      if (lembrar) {
        this.saveLocalStorage(
          logado.data.name,

          logado.data.uuid,

          logado.data.photo
        );
      }

      this.setState({
        isAuth: true,

        username: logado.data.name,

        useruuid: logado.data.uuid,

        photo: logado.data.photo,
      });
    } catch (err) {
      return err.response.status;
    }

    return true;
  };

  handleLogout = () => {
    history.push('/');

    this.setState({
      isAuth: false,
    });

    localStorage.removeItem('USER_USERNAME');

    localStorage.removeItem('USER_UUID');

    localStorage.removeItem('USER_PHOTO');

    localStorage.removeItem('USER');
  };

  /**

   * Esse método de login DEVE SER MELHORADO, pois esta solução é temporária

   * e abre brechas de segurança.

   */

  saveLocalStorage = (USERNAME, UUID, PHOTO) => {
    localStorage.setItem('USER_USERNAME', USERNAME);

    localStorage.setItem('USER_UUID', UUID);

    localStorage.setItem('USER_PHOTO', PHOTO);
  };

  /**

   * Essa função foi criada para que o `useruuid` mais recente e atualizado

   * do state seja buscado sem risco de pegar um valor vazio ou desatualizado.

   * Sem isso, ao buscar no componentDidMout, vinha vazio o state.

   */

  getUserUuidFromState = () => {
    const { useruuid } = this.state;

    return useruuid;
  };

  render() {
    const { isAuth, username, photo, isReport } = this.state;
    console.log('Acesso pelo APP');
    return (
      <div className="App">
        <Router history={history}>
          {!isAuth && (
            <Route
              path="*"
              render={props => (
                <Login {...props} handleLogin={this.handleLogin} />
              )}
            />
          )}

          {isAuth ? (
            <div>
              <Menu
                onLogout={this.handleLogout}
                username={username}
                empresa=""
                photo={photo}
              />

              {isReport ? <HeaderReport /> : <Header />}

              <ToastContainer hideProgressBar autoClose={false} />
            </div>
          ) : null}

          <Switch>
            {isAuth && <Route path="/usuarios" exact component={Usuarios} />}

            {isAuth && (
              <Route path="/usuarios/novo" exact component={NovoUsuario} />
            )}

            {isAuth && (
              <Route
                path="/departamentos"
                exact
                component={DepartamentoContainer}
              />
            )}

            {isAuth && (
              <Route
                path="/departamentos/:uuid"
                exact
                component={DepartamentoContainer}
              />
            )}

            {isAuth && (
              <Route path="/cargos" exact component={CargoContainer} />
            )}

            {isAuth && (
              <Route path="/cargos/:uuid" exact component={CargoContainer} />
            )}

            {isAuth && (
              <Route
                path="/funcionarios/:uuid"
                exact
                component={FuncionarioContainer}
              />
            )}

            {isAuth && (
              <Route
                path="/funcionarios"
                exact
                component={FuncionarioContainer}
              />
            )}

            {isAuth && (
              <Route path="/turnos/:uuid" exact component={TurnoContainer} />
            )}

            {isAuth && (
              <Route path="/turnos" exact component={TurnoContainer} />
            )}

            {isAuth && (
              <Route
                path="/contratos/:uuid"
                exact
                component={ContratoContainer}
              />
            )}

            {isAuth && (
              <Route path="/contratos" exact component={ContratoContainer} />
            )}

            {isAuth && (
              <Route path="/relatorios" exact component={RelatorioContainer} />
            )}

            {isAuth && (
              <Route
                path="/fechamentos"
                exact
                component={FechamentoContainer}
              />
            )}

            {isAuth && <Route path="/import" exact component={Import} />}

            {isAuth && (
              <Route path="/" exact component={FuncionarioContainer} />
            )}

            {isAuth && (
              <Route
                path="/justificativas/:uuid"
                exact
                component={JustificativaContainer}
              />
            )}

            {isAuth && (
              <Route
                path="/justificativas"
                component={JustificativaContainer}
              />
            )}

            {isAuth && (
              <Route path="/motivos/:uuid" exact component={MotivoContainer} />
            )}

            {isAuth && <Route path="/motivos" component={MotivoContainer} />}

            {isAuth && (
              <Route
                path="/feriados/:uuid"
                exact
                component={FeriadoContainer}
              />
            )}

            {isAuth && <Route path="/feriados" component={FeriadoContainer} />}

            {isAuth && (
              <Route path="/auditorias" exact component={AuditoriaContainer} />
            )}
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
