import React from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';

import Loading from '../components/Loading';

const contratosListItem = props =>
  props.contratos.map(contrato => (
    <li key={contrato.uuid}>
      <Link to={`/contratos/${contrato.uuid}`} key={contrato.uuid}>
        <strong>{contrato.nm_contrato}</strong>
        <strong />
        <strong>{new Date(contrato.createdAt).toLocaleString()}</strong>
      </Link>
    </li>
  ));

function ContratoList(props) {
  const { isLoading, contratos } = props;

  return (
    <>
      {!isLoading ? (
        <>
          {contratos.length > 0 ? (
            <ListStyled>
              <header>
                <div className="total">
                  <h1>TOTAL: </h1>
                  <p>{contratos.length}</p>
                </div>

                <div className="titles">
                  <strong>NOME</strong>
                  <strong />
                  <strong>DATA DE CRIAÇÃO</strong>
                </div>
              </header>
              {contratosListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>LISTA VAZIA</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

ContratoList.propTypes = {
  isLoading: PropTypes.bool,
  contratos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      nm_contrato: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
};

ContratoList.defaultProps = {
  contratos: [],
  isLoading: false,
};

export default ContratoList;
