import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';

import PropTypes from 'prop-types';

import { ListStyled } from '../../css/styles/list';

import Loading from '../components/Loading';

class MotivoList extends Component {
  MotivoListItem = motivos =>
    motivos.map(motivo => (
      <li key={motivo.uuid}>
        <Link to={`/motivos/${motivo.uuid}`} key={motivo.uuid}>
          <strong>{moment(motivo.createdAT).format('DD/MM/YYYY')}</strong>

          <strong>{motivo.nm_motivo}</strong>
        </Link>
      </li>
    ));

  render() {
    const { isLoading, motivos } = this.props;

    return (
      <>
        {!isLoading ? (
          <>
            {motivos && (
              <>
                {motivos.length > 0 ? (
                  <ListStyled>
                    <header>
                      <div className="total">
                        <h1>TOTAL: </h1>

                        <p>{motivos.length}</p>
                      </div>

                      <div className="titles">
                        <strong className="name">DATA</strong>

                        <strong>DESCRIÇÃO</strong>
                      </div>
                    </header>

                    {this.MotivoListItem(motivos)}
                  </ListStyled>
                ) : (
                  <ListStyled>
                    <strong>LISTA VAZIA</strong>
                  </ListStyled>
                )}
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

MotivoList.propTypes = {
  isLoading: PropTypes.bool,

  motivos: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      createdAT: PropTypes.string,

      nm_motivo: PropTypes.string,
    })
  ),
};

MotivoList.defaultProps = {
  motivos: [],

  isLoading: false,
};

export default MotivoList;
